import styled from '@emotion/styled';
import {
    Checkbox as MuiCheckBox,
    CheckboxProps,
    useTheme
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const Icon = styled.div`
    width: 16px;
    height: 16px;
    border: 1px solid ${(props) => props.theme.palette.greyBorder};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IconCheckedOuter = styled(Icon)`
    border: 1px solid ${(props) => props.theme.palette.text.success};
    background: ${(props) => props.theme.palette.successBackground};
`;

const IconChecked = () => {
    const theme = useTheme();
    return (
        <IconCheckedOuter>
            <CheckIcon
                style={{
                    color: theme.palette.text.success,
                    width: '12px',
                    height: '12px'
                }}
            />
        </IconCheckedOuter>
    );
};

export const Checkbox = (props: CheckboxProps) => {
    return (
        <MuiCheckBox
            icon={<Icon />}
            checkedIcon={<IconChecked />}
            //indeterminateIcon={<></>}
            {...props}
        />
    );
};
