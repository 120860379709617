import styled from '@emotion/styled';
import { Grid } from '@mui/material';

export const SearchGrid = styled(Grid)(({ theme }) => ({
    height: '60px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    borderRadius: '12px',
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(0),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.greySurface
}));
