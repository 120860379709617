import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Button,
    Box
} from '@mui/material';
import { useState } from 'react';
import { SalaryProvider, salaryProviders } from '../../models/payment';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export type PayrollProviderSelectProps = {
    selectedPayrollProvider: SalaryProvider;
    onSaveSuccess: () => void;
};

export const PayrollProviderSelect = (props: PayrollProviderSelectProps) => {
    const [payrollProvider, setPayrollProvider] = useState<SalaryProvider>(
        props.selectedPayrollProvider
    );
    const { setAlert } = useNotificationsSnackbar();
    const { setLoading } = useLoader();
    const { backendErrorHandler } = useErrorHandling();

    const onSaveClick = () => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .updateSalaryProvider({ salaryProvider: payrollProvider })
            .then(() => {
                setAlert('Updated successfully', 'success');
                props.onSaveSuccess();
            })
            .catch(backendErrorHandler(undefined))
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <Typography variant="h5">Payroll provider selection</Typography>
            Please choose your company's payroll data provider:
            <Box>
                <FormControl>
                    <InputLabel></InputLabel>
                    <Select
                        variant="standard"
                        sx={{ minWidth: '200px' }}
                        value={payrollProvider}
                        label="Select payroll provider"
                        onChange={(e) =>
                            setPayrollProvider(e.target.value as SalaryProvider)
                        }
                    >
                        {salaryProviders.map((salaryProvider) => (
                            <MenuItem
                                key={salaryProvider}
                                value={salaryProvider}
                            >
                                {salaryProvider}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Button
                    sx={{ marginTop: '1rem', marginLeft: '1rem' }}
                    variant="contained"
                    onClick={onSaveClick}
                >
                    Save
                </Button>
            </Box>
            <hr />
        </>
    );
};
