import { Backdrop, CircularProgress, debounce } from '@mui/material';
import { useLoader } from './LoaderContext';
import { useLoader as useGlobalLoader } from '../../lib/hooks';
import { useEffect, useState } from 'react';

export const Loader = () => {
    const { loading } = useLoader();

    const [internalLoader, setInternalLoader] = useState(false);

    const { isLoading } = useGlobalLoader();

    useEffect(() => {
        if (loading || isLoading) {
            setInternalLoader(true);
        } else {
            //enable loader for minimum 400 ms for better UX
            debounce(() => setInternalLoader(false), 400)();
        }
    }, [loading, isLoading]);

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
            open={internalLoader}
        >
            <CircularProgress sx={{ color: 'inherit' }} />
        </Backdrop>
    );
};
