import { configureStore } from '@reduxjs/toolkit';
import paymentsReducer from './slices/paymentsSlice';
import usersReducer from './slices/usersSlice';
import globalLoaderReducer from './slices/globalLoaderSlice';

export const makeStore = () => {
    return configureStore({
        reducer: {
            payments: paymentsReducer,
            users: usersReducer,
            loader: globalLoaderReducer
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false
            })
    });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
