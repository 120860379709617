import { Box, Typography, Card, Divider, CardContent } from '@mui/material';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DateFormats, formatDate } from '../../utils/formatting';
import { CompanyPaymentDetailsResponse } from '../../models/payment';
import { UserContext } from '../../contexts/user/UserContext';
import dayjs from 'dayjs';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export const SubscriptionDetails = () => {
    const [company, setCompany] = useState<CompanyPaymentDetailsResponse>();
    const { setLoading } = useLoader();
    const { backendErrorHandler } = useErrorHandling();

    const fetchCompany = useCallback(() => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getCurrentCompany()
            .then((response: any) => {
                var company = response.data as CompanyPaymentDetailsResponse;
                setCompany(company);
            })
            .catch(backendErrorHandler())
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, backendErrorHandler]);

    useEffect(() => {
        fetchCompany();
    }, [fetchCompany]);

    const { company: userCompany } = useContext(UserContext);

    const getNextPeriodStartIfNoTrial = () => {
        return dayjs()
            .add(1, 'month')
            .startOf('month')
            .format(DateFormats.DATE_WITH_SHORT_MONTH);
    };

    const getNextPeriodStart = () => {
        if (userCompany?.endOfTrial) {
            let nextPeriodStart = dayjs(userCompany?.endOfTrial)
                .add(1, 'month')
                .startOf('month');

            if (nextPeriodStart.isAfter(dayjs())) {
                var nextPeriodStartStr = nextPeriodStart.format(
                    DateFormats.DATE_WITH_SHORT_MONTH
                );
                return nextPeriodStartStr;
            }
        }
        return getNextPeriodStartIfNoTrial();
    };

    return (
        <>
            <Typography variant="h5">Subscription plan</Typography>
            {userCompany?.endOfTrial && (
                <Typography variant="body1">
                    Free trial ending :{' '}
                    {dayjs(userCompany?.endOfTrial).format(
                        DateFormats.DATE_WITH_SHORT_MONTH
                    )}
                </Typography>
            )}
            <Box>
                <Card
                    sx={{
                        maxWidth: 345,
                        margin: '1rem 0',
                        backgroundColor: '#5b94e3',
                        color: 'white'
                    }}
                >
                    <CardContent>
                        <b>InstaPaid Basic</b>
                        <br />
                        <Typography variant="h6" fontWeight="bold">
                            {company?.pricePerUser} {company?.currency} / User
                        </Typography>
                        <Divider
                            sx={{
                                margin: '0.8rem 0',
                                backgroundColor: 'white'
                            }}
                        />
                        No. of users activated
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            sx={{ margin: '0.3rem 0 0.5rem 0' }}
                        >
                            {company?.activeUsersCount}
                        </Typography>
                        <b>Next period starts</b>
                        <br />
                        {getNextPeriodStart()}
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};
