import { useState, useEffect, useCallback } from 'react';
import { Alert } from '@mui/material';
import { debounce } from 'lodash';
import FormGroup from '@mui/material/FormGroup';
import { UserTableType, UsersTable } from '../UsersTable';
import { AddUserForm } from '../add/AddUserForm';
import { UpdateUserForm } from '../update/UpdateUserForm';
import { UpdateBankAccountForm } from '../bankAccountDetails/UpdateBankAccountForm';
import {
    UserDetailedResponse,
    GetIntectUsersDetailedReqest,
    IntectUserDetailed,
    CompanyUsersResponse,
    PaymentsUser
} from '../../../models/payment';
import { useErrorHandling } from '../../../utils/errorHandling';
import { IntectUsersFilters } from './IntectUsersFilters';
import { useEmployeesOverview } from '../EmployeesOverview';
import {
    WithConfirmation,
    WithConfirmationWrappedComponentProps
} from '../../../utils/withConfirmation';
import { useEmployeeStatusConfirmation } from '../useEmployeeStatusConfirmation';
import useUserActiveFilter from '../useUserActiveFilter';
import { useAppDispatch, usePayments } from '../../../lib/hooks';
import { FETCH_STATE } from '../../../lib/slices/types';
import { checkValueAndCall } from '../../../lib/functionUtil';
import { getFilterToParams } from '../UserActiveFilter';
import {
    getIntectUsersDetailed,
    getCompaniesUsers
} from '../../../lib/slices/paymentsSlice';
import { useParams } from 'react-router-dom';
import { setLoader } from '../../../lib/slices/globalLoaderSlice';
import { saveRowsPerPageForUserTable } from '../../../lib/localStorage';
import { EditUsersDialog } from '../../company/EditUsersDialog';
import { UserType } from '../../../models/company';
import { usePagination } from '../../../components/shared/TablePagination';
interface IntectEmployeesProps extends WithConfirmationWrappedComponentProps {
    userType: UserType;
}

export const IntectEmployeesOverview = WithConfirmation(
    (props: IntectEmployeesProps) => {
        // const { showConfirmDialog } = props;
        const [usersDetailed, setUsersDetailed] =
            useState<UserDetailedResponse<IntectUserDetailed>>();
        const [companyUsersDetailed, setCompanyUsersDetailed] =
            useState<CompanyUsersResponse>();
        const [error, setError] = useState<string>();
        const [updateUserId, setUpdateUserId] = useState<string>();
        const [updateUserDialogOpen, setUpdateUserDialogOpen] = useState(false);

        const [accessRowUser, setAccessRowUser] = useState<PaymentsUser>();
        const [editUserAccessOpen, setEditUserAccessOpen] = useState(false);

        const [updateBankAccountUserId, setUpdateBankAccountUserId] =
            useState<string>();
        const [
            updateBankAccountUserDialogOpen,
            setUpdateBankAccountUserDialogOpen
        ] = useState(false);

        const [checked, setChecked] = useState<string[]>([]);
        const [selectedDepartments, setSelectedDepartments] = useState<
            string[]
        >([]);

        const [selectedSalaryTypes, setSelectedSalaryTypes] = useState<
            string[]
        >([]);

        const [selectedPandayEmployeeIds, setPandayEmployeeTypesIds] = useState<
            string[]
        >([]);
        const [selectedEmploymentTemplates, setSelectedEmploymentTemplates] =
            useState<string[]>([]);
        const [selectedActiveType, setSelectedActiveType] =
            useState<string>('');
        const [username, setUsername] = useState<string>('');

        const [rowsPerPage, setRowsPerPage] = useState(16);
        const { backendErrorHandler } = useErrorHandling();
        const {
            onRowClick,
            onCheckAllClick,
            allUsersChecked,
            onCheckAllCompanyUsersClick,
            allCompanyUsersChecked
        } = useEmployeesOverview();

        const { page, setPage, resetPage } = usePagination();

        useEffect(() => {
            if (username) {
                resetPage();
            }
        }, [username]);

        const { filteredUserDetailsList, renderOptions: renderActiveOptions } =
            useUserActiveFilter(usersDetailed);

        const { filteredUserDetailsList: filteredCompanyAdminDetailsList } =
            useUserActiveFilter(companyUsersDetailed);

        const dispatch = useAppDispatch();

        const { intectUsersDetailed, companyUsers } = usePayments();

        useEffect(() => {
            dispatch(
                setLoader(intectUsersDetailed.status == FETCH_STATE.LOADING)
            );

            if (intectUsersDetailed.status == FETCH_STATE.FAILED) {
                backendErrorHandler('Error fetching users: ', () => {
                    setError('Users could not be fetched');
                });
            }

            if (intectUsersDetailed.status == FETCH_STATE.SUCCEEDED) {
                setUsersDetailed(intectUsersDetailed.usersResponse);
            }
        }, [intectUsersDetailed]);

        const reloadUsersPaymentsService = useCallback(() => {
            let activeStatus = getFilterToParams(selectedActiveType);
            const request: GetIntectUsersDetailedReqest = {
                departmentIds: selectedDepartments,
                employmentTemplateIds: selectedEmploymentTemplates,
                plandayEmployeeTypeIds: selectedPandayEmployeeIds,
                username: username,
                instapaidSalaryType: selectedSalaryTypes,
                ...activeStatus,
                limit: rowsPerPage > 0 ? rowsPerPage : 0, // can be set to -1 to show all
                offset: page * rowsPerPage
            };
            dispatch(getIntectUsersDetailed(request));
        }, [
            backendErrorHandler,
            selectedDepartments,
            selectedEmploymentTemplates,
            selectedActiveType,
            username,
            page,
            rowsPerPage,
            selectedPandayEmployeeIds,
            selectedSalaryTypes
        ]);

        let debouncedReload = debounce(() => {
            reloadUsersPaymentsService();
        }, 100);

        const reloadUsers = useCallback(() => {
            debouncedReload?.cancel();
            debouncedReload();
            setChecked([]);
        }, [reloadUsersPaymentsService]);

        useEffect(() => {
            dispatch(setLoader(companyUsers.status === FETCH_STATE.LOADING));
            if (companyUsers.status == FETCH_STATE.FAILED) {
                backendErrorHandler('Error fetching users: ', () => {
                    setError('Company Admins could not be fetched');
                });
            }
            if (companyUsers.status == FETCH_STATE.SUCCEEDED) {
                setCompanyUsersDetailed(companyUsers?.companySettingsUsers);
            }
        }, [companyUsers]);

        const reloadCompanyUsersService = useCallback(() => {
            dispatch(
                getCompaniesUsers({
                    limit: rowsPerPage > 0 ? rowsPerPage : 0,
                    offset: page * rowsPerPage
                })
            );
        }, [page, rowsPerPage]);

        let debouncedCompanyUsersReload = debounce(() => {
            reloadCompanyUsersService();
        }, 100);

        const reloadCompanyUsers = useCallback(() => {
            debouncedCompanyUsersReload?.cancel();
            debouncedCompanyUsersReload();
            setChecked([]);
        }, [reloadCompanyUsersService]);

        const {
            handleBulkEnable,
            handleBulkDisable,
            handleStatusChange,
            handleRoleToggleChange,
            sendActivationEmail,
            handleDeleteUser
        } = useEmployeeStatusConfirmation(props.showConfirmDialog, reloadUsers);

        useEffect(() => {
            reloadUsers();
        }, [reloadUsers]);

        useEffect(() => {
            reloadCompanyUsers();
        }, [reloadCompanyUsers]);

        const onEditClick = (userId: string) => () => {
            setUpdateUserId(userId);
            setUpdateUserDialogOpen(true);
        };

        const onEditUserAccessClick = (data: any) => () => {
            setAccessRowUser(data);
            setEditUserAccessOpen(true);
        };

        const onUpdateBankAccountDetailsClick = (userId: string) => () => {
            setUpdateBankAccountUserId(userId);
            setUpdateBankAccountUserDialogOpen(true);
        };

        const handleDepartmentChange = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedDepartments);
        };

        const handlePlandayEmployeeTypesChange = (value: string | string[]) => {
            checkValueAndCall(value, setPandayEmployeeTypesIds);
        };

        const handleEmploymentTemplateChange = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedEmploymentTemplates);
        };

        const handleSalaryTypeChange = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedSalaryTypes);
        };

        const handleSetSelectedActiveType = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedActiveType);
        };

        const handleChangePage = (
            event: React.MouseEvent<HTMLButtonElement> | null,
            newPage: number
        ) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (rowsPerPage: number) => {
            setRowsPerPage(rowsPerPage);
            saveRowsPerPageForUserTable(rowsPerPage);
            setPage(0);
        };

        // useEffect(() => {
        //     let rowsPerPage = getRowsPerPageForUserTable();
        //     setRowsPerPage(rowsPerPage);
        // }, []);

        const handleSentActivationEmail = (userId: string, name: string) => {
            sendActivationEmail(userId, name, setError);
        };

        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {usersDetailed && (
                    <div style={{ position: 'relative' }}>
                        <div
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: -80
                            }}
                        >
                            {props?.userType === 'Normal' && (
                                <AddUserForm
                                    onUserListChange={reloadUsers}
                                    name="Add user"
                                />
                            )}
                        </div>
                        {props?.userType === 'Normal' && (
                            <IntectUsersFilters
                                renderActiveOptions={renderActiveOptions}
                                onPlanDayEmployeeTypeChange={
                                    handlePlandayEmployeeTypesChange
                                }
                                selectedPlanDayEmployeeTypes={
                                    selectedPandayEmployeeIds
                                }
                                selectedDepartments={selectedDepartments}
                                onDepartmentChange={handleDepartmentChange}
                                selectedEmploymentTemplates={
                                    selectedEmploymentTemplates
                                }
                                onEmploymentTemplateChange={
                                    handleEmploymentTemplateChange
                                }
                                username={username}
                                onUsernameChange={setUsername}
                                selectedActiveType={selectedActiveType}
                                onActiveTypeChange={handleSetSelectedActiveType}
                                selectedSalaryTypes={selectedSalaryTypes}
                                onSalaryTypeChange={handleSalaryTypeChange}
                            />
                        )}

                        <FormGroup sx={{ marginBottom: '1rem' }}>
                            <UsersTable
                                displayAdmin={
                                    props?.userType === 'Normal' ? false : true
                                }
                                addUserForm={
                                    props?.userType === 'Admin' && (
                                        <AddUserForm
                                            onUserListChange={
                                                reloadCompanyUsers
                                            }
                                            name="Create user"
                                        />
                                    )
                                }
                                checked={checked}
                                setChecked={setChecked}
                                handleDeleteUser={(userId: string) =>
                                    handleDeleteUser(
                                        userId,
                                        props?.userType === 'Normal'
                                            ? reloadUsers
                                            : reloadCompanyUsers
                                    )
                                }
                                allChecked={
                                    props?.userType === 'Normal'
                                        ? allUsersChecked(
                                              filteredUserDetailsList,
                                              checked
                                          )
                                        : allCompanyUsersChecked(
                                              filteredCompanyAdminDetailsList,
                                              checked
                                          )
                                }
                                onRowClick={(userId: string) =>
                                    onRowClick(userId, checked, setChecked)
                                }
                                onCheckAllClick={() =>
                                    props?.userType === 'Normal'
                                        ? onCheckAllClick(
                                              filteredUserDetailsList,
                                              checked,
                                              setChecked
                                          )
                                        : onCheckAllCompanyUsersClick(
                                              filteredCompanyAdminDetailsList,
                                              checked,
                                              setChecked
                                          )
                                }
                                onStatusClick={(
                                    userId: string,
                                    isActive: boolean
                                ) =>
                                    handleStatusChange(
                                        userId,
                                        isActive,
                                        props?.userType === 'Normal'
                                            ? reloadUsers
                                            : reloadCompanyUsers
                                    )
                                }
                                onRoleToggleChange={(
                                    userId: string,
                                    isAdmin: boolean
                                ) =>
                                    handleRoleToggleChange(
                                        userId,
                                        isAdmin,
                                        props?.userType === 'Normal'
                                            ? reloadUsers
                                            : reloadCompanyUsers
                                    )
                                }
                                onEditClick={
                                    props?.userType === 'Normal'
                                        ? onEditClick
                                        : onEditUserAccessClick
                                }
                                onUpdateBankAccountDetailsClick={
                                    onUpdateBankAccountDetailsClick
                                }
                                onSendActivationMailClicked={
                                    handleSentActivationEmail
                                }
                                // usersBase={filteredUsers}
                                handleChangePage={handleChangePage}
                                handleChangeRowsPerPage={
                                    handleChangeRowsPerPage
                                }
                                usersDetailed={
                                    props?.userType === 'Normal'
                                        ? filteredUserDetailsList
                                        : filteredCompanyAdminDetailsList
                                }
                                rowsPerPage={rowsPerPage}
                                userTableType={UserTableType.Intect}
                                total={
                                    props?.userType === 'Normal'
                                        ? intectUsersDetailed?.usersResponse
                                              ?.filteredTotal
                                        : companyUsers?.companySettingsUsers
                                              ?.total
                                }
                                handleBulkEnable={handleBulkEnable}
                                handleBulkDisable={handleBulkDisable}
                            />
                        </FormGroup>
                    </div>
                )}

                <UpdateUserForm
                    onUserListChange={reloadUsers}
                    userId={updateUserId}
                    open={updateUserDialogOpen}
                    setOpen={(open: boolean) => setUpdateUserDialogOpen(open)}
                />

                <UpdateBankAccountForm
                    onUserListChange={reloadUsers}
                    userId={updateBankAccountUserId}
                    open={updateBankAccountUserDialogOpen}
                    setOpen={(open: boolean) =>
                        setUpdateBankAccountUserDialogOpen(open)
                    }
                />

                <EditUsersDialog
                    dialogOpen={editUserAccessOpen}
                    onClose={() => setEditUserAccessOpen(false)}
                    rowData={editUserAccessOpen && accessRowUser}
                    refreshList={reloadCompanyUsers}
                />
            </>
        );
    }
);
