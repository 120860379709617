import { useEffect, useState, useCallback, useMemo } from 'react';
import {
    Alert,
    Box,
    FormControl,
    Typography,
    Stack,
    Card
} from '@mui/material';
import { TransactionsList } from './TransactionsList';
import { CompanyPaymentDetailsResponse } from '../../models/payment';
import { FONT_WEIGHT, SMBold, TEXT_SIZE } from '../../typography/Typography';
import Chip from '../../components/shared/Chip';
import { CalenderIcon } from '../../icons/CalenderIcon';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from '@emotion/styled';
import enGB from 'date-fns/locale/en-GB';
import { TablePagination } from '../../components/shared/TablePagination';
import { useParams } from 'react-router-dom';
import { useAppDispatch, usePayments } from '../../lib/hooks';
import {
    getTransactionsHistory,
    getCurrentCompany,
    getDownloadTransactions,
    getTransactionsPdf
} from '../../lib/slices/paymentsSlice';
import * as XLSX from 'xlsx';
import moment from 'moment';
import CardHeader from '../../components/shared/CardHeader';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '../../utils/formatting';
import dayjs from 'dayjs';
import {
    PrimaryButton,
    SecondaryButtonRegular
} from '../../components/shared/Button';
import { SearchGrid } from '../../components/SearchGrid';
import { FETCH_STATE } from '../../lib/slices/types';
import { setLoader } from '../../lib/slices/globalLoaderSlice';
import { useErrorHandling } from '../../utils/errorHandling';

const DropDown = styled('div')`
    margin: 0 auto;
    z-index: 10000;
    min-width: auto;
    position: relative;
`;

const DropDownBtn = styled('div')`
    cursor: pointer;
    background: white;
    display: flex;
    align-items: center;
    padding: 10px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    color: #777;
    font-weight: 500;
    padding: 8px 14px;
`;

const DropDownContent = styled('div')`
    position: absolute;
    left: 0;
    min-width: -webkit-fill-available;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
    background: white;
    border-radius: 10px;
`;

const DropDownItem = styled('div')`
    display: block;
    padding: 8px;
    margin: 10px;
    line-height: 16px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;

    &:hover {
        background: #eff8ff;
    }
`;

const DatePickerFooter = styled('div')`
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border-left: 1px solid #f0f0f0;
`;

const DateInput = styled('input')`
    border-radius: 4px;
    border: 1px solid #d0d5dd;
    width: 90px;
    height: 30px;
    text-align: center;
    margin: 5px;
`;

const DatePickerButtons = styled('div')`
    display: flex;
    align-items: center;
    width: 150px;
    justify-content: space-evenly;
`;

export const TransactionsHistory = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { backendErrorHandler } = useErrorHandling();
    let { userId } = useParams();
    const [errorMessage, setErrorMessage] = useState<string>();
    const {
        transactionHistory,
        currentCompany,
        downloadTransactions,
        exportTransactionsPdf
    } = usePayments();

    const [companyPaymentDetails, setCompanyPaymentDetails] = useState(
        currentCompany.company
    );

    const [timeFilter, setTimeFilter] = useState('last90Days');
    const currentYear = new Date().getFullYear();
    const currentMonth = moment().format('MMMM');
    const lastMonth = moment().subtract(1, 'month');

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(16);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const filterOptions = [
        { value: 'last90Days', name: t('last90Days') },
        { value: 'customDate', name: t('customDate') },
        { value: 'thisMonth', name: t('thisMonth') },
        { value: 'lastMonth', name: t('lastMonth') },
        { value: 'currentYear', name: t('currentYear', { year: currentYear }) },
        { value: 'lastYear', name: t('lastYear', { year: currentYear - 1 }) }
    ];
    const [downloadReady, setDownloadReady] = useState(false);
    const [pdfDownloadReady, setPdfDownloadReady] = useState(false);
    const { getBalanceLabel } = useFormatting();

    useEffect(() => {
        registerLocale('en-GB', enGB.enGB);
    }, []);

    useEffect(() => {
        dispatch(getCurrentCompany());
    }, []);

    useEffect(() => {
        setCompanyPaymentDetails(currentCompany.company);
    }, [currentCompany.company]);

    let startDateValue: Date | null = null;
    let endDateValue: Date | null = null;
    const currentDate = new Date();

    useEffect(() => {
        dispatch(setLoader(transactionHistory.status === FETCH_STATE.LOADING));
        if (transactionHistory.status === FETCH_STATE.FAILED) {
            backendErrorHandler('Error fetching users: ', () => {
                setErrorMessage('Transactions could not be fetched');
            });
        }
    }, [transactionHistory.status]);

    const filtereDateData = async (options: any) => {
        if (options === 'last90Days') {
            const ninetyDaysAgo = new Date(
                currentDate.getTime() - 90 * 24 * 60 * 60 * 1000
            );
            startDateValue = ninetyDaysAgo;
            endDateValue = currentDate;
        } else if (options === 'thisMonth') {
            const firstDayOfMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                1
            );
            startDateValue = firstDayOfMonth;
            endDateValue = currentDate;
        } else if (options === 'lastMonth') {
            const lastMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() - 1,
                1
            );
            const lastDayOfLastMonth = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                0
            );
            startDateValue = lastMonth;
            endDateValue = lastDayOfLastMonth;
        } else if (options === 'currentYear') {
            const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
            startDateValue = firstDayOfYear;
            endDateValue = currentDate;
        } else if (options === 'lastYear') {
            const firstDayOfLastYear = new Date(
                currentDate.getFullYear() - 1,
                0,
                1
            );
            const lastDayOfLastYear = new Date(
                currentDate.getFullYear() - 1,
                11,
                31
            );
            startDateValue = firstDayOfLastYear;
            endDateValue = lastDayOfLastYear;
        } else if (options === 'customDate' && startDate && endDate) {
            startDateValue = startDate;
            endDateValue = endDate;
        } else {
            startDateValue = null;
            endDateValue = null;
        }

        if (startDateValue && endDateValue) {
            const startdate = moment(startDateValue).format('YYYY-MM-DD');
            const enddate = moment(endDateValue).format('YYYY-MM-DD');

            try {
                await dispatch(
                    getTransactionsHistory({
                        userId: userId!,
                        limit: rowsPerPage > 0 ? rowsPerPage : 0,
                        offset: Number(page) * Number(rowsPerPage),
                        startDate: startdate,
                        endDate: enddate
                    })
                );
            } catch (error) {
                console.error('Error loading transactions:', error);
            }
        }
    };

    const reloadTransactionsHistory = useCallback(() => {
        filtereDateData(timeFilter);
    }, [timeFilter, page, rowsPerPage]);

    const reloadTransactions = useCallback(() => {
        reloadTransactionsHistory();
    }, [reloadTransactionsHistory]);

    useEffect(() => {
        reloadTransactions();
    }, [reloadTransactions]);

    const onChange = (dates: any) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleMouseEnter = (option: any) => {
        setShowDatePicker(option === 'customDate');
    };

    const handleApply = () => {
        setIsActive(false);
        setShowDatePicker(false);
        setTimeFilter('customDate');
        filtereDateData('customDate');
    };

    const handleClose = () => {
        setShowDatePicker(false);
    };

    const handleDownload = async () => {
        filtereDateData(timeFilter);
        if (startDateValue && endDateValue) {
            const startdate = moment(startDateValue).format('YYYY-MM-DD');
            const enddate = moment(endDateValue).format('YYYY-MM-DD');

            try {
                await dispatch(
                    getDownloadTransactions({
                        startDate: startdate,
                        endDate: enddate
                    })
                );
                setDownloadReady(true);
            } catch (error) {
                console.error('Error downloading transactions:', error);
            }
        } else if (startDateValue === null && endDateValue === null) {
            await dispatch(
                getDownloadTransactions({ startDate: null, endDate: null })
            );
            setDownloadReady(true);
        }
    };

    const handleDownloadPdf = async () => {
        filtereDateData(timeFilter);
        if (startDateValue && endDateValue) {
            const startdate = moment(startDateValue).format('YYYY-MM-DD');
            const enddate = moment(endDateValue).format('YYYY-MM-DD');

            try {
                await dispatch(
                    getTransactionsPdf({
                        startDate: startdate,
                        endDate: enddate
                    })
                );
                setPdfDownloadReady(true);
            } catch (error) {
                console.error('Error downloading transactions:', error);
            }
        } else if (startDateValue === null && endDateValue === null) {
            await dispatch(
                getTransactionsPdf({ startDate: null, endDate: null })
            );
            setPdfDownloadReady(true);
        }
    };

    useEffect(() => {
        filtereDateData(timeFilter);
        if (downloadReady && downloadTransactions.transactions) {
            const formattedTransactions =
                downloadTransactions.transactions.transactions.map(
                    (transaction: any) => {
                        const {
                            date,
                            description,
                            status,
                            amount,
                            balance,
                            paymentSource
                        } = transaction;
                        return {
                            Date: dayjs(date).format('DD-MM-YYYY'),
                            Description: description,
                            Status: status,
                            Amount: amount,
                            ...(paymentSource === 'InstaPaidAccount' && {
                                Balance: balance
                            })
                        };
                    }
                );

            const worksheet = XLSX.utils.json_to_sheet(
                formattedTransactions as any
            );
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            let fileName = 'transactions.xlsx';
            if (timeFilter === 'last90Days') {
                fileName = t('account_statement_download', {
                    filterName: '90 Days'
                });
            } else if (timeFilter === 'customDate') {
                fileName = t('account_statement_download', {
                    filterName: 'Custom'
                });
            } else if (timeFilter === 'thisMonth') {
                fileName = t('account_statement_download', {
                    filterName: currentMonth + ' ' + currentYear
                });
            } else if (timeFilter === 'lastMonth') {
                fileName = t('account_statement_download', {
                    filterName: lastMonth.format('MMMM') + ' ' + currentYear
                });
            } else if (timeFilter === 'currentYear') {
                fileName = t('account_statement_download', {
                    filterName: currentYear
                });
            } else if (timeFilter === 'lastYear') {
                fileName = t('account_statement_download', {
                    filterName: currentYear - 1
                });
            }

            XLSX.writeFile(workbook, fileName);
            setDownloadReady(false);
        } else if (pdfDownloadReady && exportTransactionsPdf.data?.fileUrl) {
            const anchor = document.createElement('a');
            anchor.href = exportTransactionsPdf?.data?.fileUrl;
            anchor.click();
            setPdfDownloadReady(false);
        }
    }, [
        timeFilter,
        downloadReady,
        downloadTransactions,
        exportTransactionsPdf,
        pdfDownloadReady
    ]);

    const renderDatePicker = useMemo(() => {
        return (
            <div style={{ display: 'block' }}>
                <DatePicker
                    calendarStartDay={1}
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    monthsShown={2}
                    locale={'en-GB'}
                    selectsRange
                    inline
                    shouldCloseOnSelect={false}
                />

                <DatePickerFooter>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DateInput
                            value={
                                startDate
                                    ? dayjs(startDate).format('MMMM D, YYYY')
                                    : ''
                            }
                            readOnly
                        />
                        <p> - </p>
                        <DateInput
                            value={
                                endDate
                                    ? dayjs(endDate).format('MMMM D, YYYY')
                                    : ''
                            }
                            readOnly
                        />
                    </div>
                    <DatePickerButtons>
                        <SecondaryButtonRegular onClick={handleClose}>
                            {t('close')}
                        </SecondaryButtonRegular>
                        <PrimaryButton onClick={handleApply}>
                            {t('apply')}
                        </PrimaryButton>
                    </DatePickerButtons>
                </DatePickerFooter>
            </div>
        );
    }, [startDate, endDate, handleApply, onChange]);

    const { pageNumber } = useParams();

    const handleChangeRowsPerPage = (rowsPerPage: number) => {
        setRowsPerPage(rowsPerPage);
        setPage(0);
    };

    useEffect(() => {
        if (pageNumber) {
            setPage(parseInt(pageNumber));
        }
    }, [pageNumber]);

    const actions = (
        <Stack direction="row" gap={'8px'}>
            <SecondaryButtonRegular
                onClick={handleDownload}
                size="small"
                sx={{ borderRadius: '5px' }}
            >
                {t('download_as_excel')}
            </SecondaryButtonRegular>
            <PrimaryButton onClick={handleDownloadPdf} size="small">
                {t('download_as_pdf')}
            </PrimaryButton>
        </Stack>
    );

    return (
        <Box>
            {transactionHistory?.transactions?.transactions &&
                companyPaymentDetails && (
                    <>
                        <SearchGrid container justifyContent={'space-between'}>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                gap={1}
                                justifyContent={'space-between'}
                            >
                                <div>
                                    <SMBold noWrap={true}>
                                        {t('filter_by')}:
                                    </SMBold>
                                </div>

                                <>
                                    <FormControl>
                                        <DropDown style={{ zIndex: 1 }}>
                                            <DropDownBtn
                                                onClick={(e) =>
                                                    setIsActive(!isActive)
                                                }
                                            >
                                                <CalenderIcon />
                                                <Typography
                                                    marginLeft={'10px'}
                                                    color={'#344054'}
                                                    fontWeight={600}
                                                    fontSize={
                                                        TEXT_SIZE.MEDIUM_SMALL
                                                    }
                                                    lineHeight={'20px'}
                                                >
                                                    {timeFilter ==
                                                        'customDate' &&
                                                    startDate &&
                                                    endDate
                                                        ? `${
                                                              startDate
                                                                  ? dayjs(
                                                                        startDate
                                                                    ).format(
                                                                        'MMMM D, YYYY'
                                                                    )
                                                                  : ''
                                                          } - ${
                                                              endDate
                                                                  ? dayjs(
                                                                        endDate
                                                                    ).format(
                                                                        'MMMM D, YYYY'
                                                                    )
                                                                  : ''
                                                          }`
                                                        : filterOptions.find(
                                                              (option) =>
                                                                  option.value ===
                                                                  timeFilter
                                                          )?.name || ''}
                                                </Typography>
                                            </DropDownBtn>
                                            <DropDownContent
                                                style={{
                                                    display: isActive
                                                        ? 'flex'
                                                        : 'none'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'block',
                                                        textWrap: 'nowrap'
                                                    }}
                                                >
                                                    {filterOptions.map(
                                                        (option) => (
                                                            <DropDownItem
                                                                onClick={(
                                                                    e: any
                                                                ) => {
                                                                    setIsActive(
                                                                        false
                                                                    );
                                                                    setTimeFilter(
                                                                        option.value as string
                                                                    );
                                                                    filtereDateData(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                    if (
                                                                        option.value ===
                                                                        'customDate'
                                                                    ) {
                                                                        setShowDatePicker(
                                                                            true
                                                                        );
                                                                    }
                                                                }}
                                                                onMouseEnter={() =>
                                                                    handleMouseEnter(
                                                                        option.value
                                                                    )
                                                                }
                                                            >
                                                                <p
                                                                    style={{
                                                                        padding: 0,
                                                                        margin: 0
                                                                    }}
                                                                >
                                                                    {
                                                                        option.name
                                                                    }
                                                                </p>
                                                            </DropDownItem>
                                                        )
                                                    )}
                                                </div>
                                                {showDatePicker &&
                                                    renderDatePicker}
                                            </DropDownContent>
                                        </DropDown>
                                    </FormControl>
                                </>
                            </Stack>
                        </SearchGrid>
                        <Card onClick={() => setIsActive(false)}>
                            <CardHeader
                                label={t('transactions')}
                                chip={
                                    <Chip
                                        icon={<></>}
                                        label={
                                            <Typography
                                                fontWeight={
                                                    FONT_WEIGHT.SEMI_BOLD
                                                }
                                            >
                                                {
                                                    transactionHistory
                                                        ?.transactions
                                                        ?.filteredTotal
                                                }
                                            </Typography>
                                        }
                                        color={'success'}
                                    />
                                }
                                actions={actions}
                            />
                            <TransactionsList
                                companyPaymentDetails={companyPaymentDetails}
                                transactions={
                                    transactionHistory?.transactions
                                        ?.transactions as any
                                }
                                reloadTransactions={reloadTransactions}
                            />
                            <TablePagination
                                page={0}
                                rowsPerPageOptions={[
                                    {
                                        label: '16',
                                        value: 16
                                    },
                                    {
                                        label: '24',
                                        value: 24
                                    },
                                    {
                                        label: '32',
                                        value: 32
                                    },
                                    {
                                        label: 'All',
                                        value: -1
                                    }
                                ]}
                                count={
                                    transactionHistory?.transactions
                                        ?.filteredTotal ?? 0
                                }
                                rowsPerPage={rowsPerPage}
                                onPageChange={() => {}}
                                onRowsPerPageChange={
                                    handleChangeRowsPerPage as any
                                }
                            />
                        </Card>
                    </>
                )}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </Box>
    );
};
