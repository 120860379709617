import { Dropdown } from '../../components/shared/Dropdown';

type ActiveFilterProps = {
    selectedActiveType: string;
    onActiveTypeChange: (value: string | string[]) => void;
    type?: string;
};

export enum ActiveType {
    Enabled = 'Enabled',
    Disabled = 'Disabled',
    Imported = 'Imported',
    All = 'All'
}

export const getFilterToParams = (selectedActiveType: ActiveType | string) => {
    let activeStatus: any = {};

    if (selectedActiveType == ActiveType.Enabled) {
        activeStatus.isActive = true;
        activeStatus.isDisabled = false;
    }

    if (selectedActiveType == ActiveType.Disabled) {
        activeStatus.isActive = false;
        activeStatus.isDisabled = true;
    }

    if (selectedActiveType == ActiveType.Imported) {
        activeStatus.isActive = false;
        activeStatus.isDisabled = false;
    }

    return activeStatus;
};

export const ActiveFilter = ({
    selectedActiveType,
    onActiveTypeChange,
    type
}: ActiveFilterProps) => {
    return (
        <Dropdown
            label={type === 'AllEmployees' ? 'Status' : 'Enabled/Disabled'}
            choices={[
                { label: 'Enabled', value: ActiveType.Enabled },
                { label: 'Disabled', value: ActiveType.Disabled },
                { label: 'Imported', value: ActiveType.Imported },
                { label: 'All', value: ActiveType.All }
            ]}
            value={selectedActiveType}
            onChange={onActiveTypeChange}
        />
    );
};
