import MuiDrawer from '@mui/material/Drawer';
import {
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    IconButton
} from '@mui/material';
import { useState } from 'react';
import { styled, Theme, CSSObject, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MenuLink, UserRoles, useMenuTree } from './MenuTree';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { useMsal } from '@azure/msal-react';
import { useUser } from '../../contexts/user/UserContext';
import { SidebarHeader } from './SidebarHeader';
import {
    drawerItemActiveBackground,
    drawerItemActiveItem
} from '../../utils/colors';
import SideBarText, { FontFamily } from '../typography/SideBarText';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
    }
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));

const StyledNavLink = styled(NavLink)(({ theme }) => {
    return {
        '&': {
            marginLeft: '10px',
            marginRight: '20px'
        },
        '& h6': {
            fontWeight: 500
        },
        '&.active h6': {
            fontWeight: 700
        },
        '&.active': {
            display: 'flex',
            backgroundColor: drawerItemActiveBackground,
            color: drawerItemActiveItem,
            borderRadius: '5px',
            fontWeight: 700
        },
        '&.active:visited': {
            color: drawerItemActiveItem
        },
        '&.active  svg': {
            color: theme.palette.drawerActiveIconColor
        },
        '&  svg': {
            color: theme.palette.drawerIconColor
        }
    };
});

export const Sidebar = () => {
    const [open, setOpen] = useState(true);
    const { instance } = useMsal();
    const { user } = useUser();

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const menuListItem = (
        item: MenuLink,
        onClick?: () => Promise<void> | null
    ) => (
        <ListItem key={item.label} disablePadding sx={{ display: 'block' }}>
            <ListItemButton
                {...{
                    onClick: () => {
                        if (item.url == 'logout') {
                            window.localStorage.clear();
                            instance.logoutRedirect();
                        } else {
                            return onClick;
                        }
                    }
                }}
                component={StyledNavLink}
                end
                key={item.url}
                to={item.url}
                sx={{
                    minHeight: 44,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: open ? 1 : 'auto',
                        justifyContent: 'center'
                    }}
                >
                    {item.icon}
                </ListItemIcon>
                <ListItemText
                    primary={<SideBarText label={item.label} />}
                    sx={{
                        opacity: open ? 1 : 0,
                        paddingLeft: open ? '8px' : 0
                    }}
                />
            </ListItemButton>
        </ListItem>
    );

    const menuListText = (label: string) => (
        <ListItem>
            <ListItemText
                primary={
                    <SideBarText
                        style={{ fontWeight: 700, textTransform: 'uppercase' }}
                        label={label}
                    />
                }
                sx={{
                    opacity: open ? 1 : 0,
                    minHeight: 18,
                    paddingLeft: open ? '16px' : 0
                }}
            />
        </ListItem>
    );

    const theme = useTheme();

    const { menuTree } = useMenuTree();

    return (
        <>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <SidebarHeader open={open} />
                    <IconButton
                        onClick={open ? handleDrawerClose : handleDrawerOpen}
                    >
                        {open ? <ChevronLeftIcon /> : <MenuIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider style={{ marginBottom: theme.spacing(4) }} />
                {[...menuTree].map((section) => {
                    if (
                        section.role == UserRoles.SuperAdmin &&
                        user.role !== UserRoles.SuperAdmin
                    ) {
                        return null;
                    }

                    return (
                        <List dense key={section.label}>
                            {section.label && menuListText(section.label)}
                            {[...section.items].map((item) => {
                                if (
                                    item.requiredRole &&
                                    item.requiredRole !== user.role
                                ) {
                                    return null;
                                }
                                return menuListItem(item);
                            })}
                        </List>
                    );
                })}
            </Drawer>
        </>
    );
};
