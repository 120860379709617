import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Caption, Heading, SubHeading } from '../typography/Typography';
import CloseIcon from '@mui/icons-material/Close';

export type ConfirmDialogProps = {
    dialogOpen: boolean;
    title: string;
    content: JSX.Element | string;
    showCancelButton?: boolean;
    handleCancel?: () => void;
    handleContinue: () => void;
};

export const ConfirmDialog = (props: ConfirmDialogProps) => {
    const theme = useTheme();

    return (
        <div>
            <Dialog
                fullWidth={true}
                open={props.dialogOpen}
                onClose={props.handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Button
                    onClick={props.handleCancel}
                    variant="text"
                    style={{
                        position: 'absolute',
                        right: 0,
                        top: 12,
                        color: theme.palette.text.primary
                    }}
                >
                    <CloseIcon />
                </Button>
                <DialogTitle id="alert-dialog-title">
                    <Heading>{props.title}</Heading>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Caption>{props.content}</Caption>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {props.showCancelButton !== false && (
                        <Button
                            style={{ marginRight: theme.spacing(2) }}
                            onClick={props.handleCancel}
                        >
                            Cancel
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        onClick={props.handleContinue}
                        autoFocus
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
