import { Card, Grid } from '@mui/material';
import {
    Caption,
    FONT_WEIGHT,
    Heading,
    LINE_HEIGHT,
    SubHeading,
    TEXT_SIZE
} from '../../../typography/Typography';
import { toDecimal, useFormatting } from '../../../utils/formatting';
import { cardStyles } from './CardStyles';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/user/UserContext';

export interface MileageOverviewProps {
    mileageTotal: number;
    mileageKm: number;
}

export const MileageOverview = ({
    mileageTotal,
    mileageKm
}: MileageOverviewProps) => {
    const { getBalanceLabel } = useFormatting();
    const { t } = useTranslation();
    const { company } = useContext(UserContext);

    return (
        <Card style={cardStyles}>
            <Heading fontSize={TEXT_SIZE.SMALL}>
                {t('mileage_overview')}
            </Heading>

            <Grid mb={2} mt={2} container justifyContent={'space-around'}>
                <Grid item direction={'column'} alignItems={'center'}>
                    <SubHeading>
                        {getBalanceLabel(mileageTotal, company.currency)}
                    </SubHeading>
                    <Caption>Approved km</Caption>
                </Grid>

                <Grid item direction={'column'} alignItems={'center'}>
                    <SubHeading>
                        {getBalanceLabel(mileageKm, company.currency)}
                    </SubHeading>
                    <Caption> Traveled km </Caption>
                </Grid>
            </Grid>
        </Card>
    );
};
