import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { PageLayout } from './components/PageLayout';
import { Pages } from './components/Pages';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { AuthenticatedTemplate, MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { LoginPage } from './pages/LoginPage';
import { NotificationsProvider } from './components/snackbar/NotificationsSnackbarContext';
import { LoaderProvider } from './contexts/loader/LoaderContext';
import { Loader } from './contexts/loader/Loader';
import { UserProvider } from './contexts/user/UserContext';
import { UnderMaintenanceCheck } from './components/UnderMaintenanceCheck';
import StoreProvider from './storeProvider';
import { theme } from './lib/theme';

type AppProps = {
    pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
    return (
        <div className="App">
            <MsalProvider instance={pca}>
                <LoaderProvider>
                    <ThemeProvider theme={theme}>
                        <StoreProvider>
                            <CssBaseline />
                            <BrowserRouter>
                                <UnderMaintenanceCheck>
                                    <>
                                        <LoginPage />
                                        <NotificationsProvider>
                                            <AuthenticatedTemplate>
                                                <UserProvider>
                                                    <PageLayout>
                                                        <Pages />
                                                    </PageLayout>
                                                </UserProvider>
                                            </AuthenticatedTemplate>
                                        </NotificationsProvider>
                                    </>
                                </UnderMaintenanceCheck>
                            </BrowserRouter>
                            <Loader />
                        </StoreProvider>
                    </ThemeProvider>
                </LoaderProvider>
            </MsalProvider>
        </div>
    );
}

// cache test 8

export default App;
