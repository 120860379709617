import { useState } from 'react';
import { ConfirmDialog } from '../components/ConfirmDialog';

export interface WithConfirmationWrappedComponentProps {
    showConfirmDialog: (
        content: string,
        title?: string | undefined | null,
        onSuccessCallBack?: () => void,
        onCancelCallback?: () => void
    ) => void;
}

export const WithConfirmation = (WrappedComponent: any) => {
    return (props: any) => {
        const { ...rest } = props;

        const [confirmDialogOpen, setConfirmDialogOpen] =
            useState<boolean>(false);
        const [confirmDialogTitle, setConfirmDialogTitle] =
            useState<string>('Confirm the Action');
        const [confirmDialogContent, setConfirmDialogContent] =
            useState<string>('');
        const [
            confirmDialogOnSuccessCallback,
            setConfirmDialogOnSuccessCallback
        ] = useState<() => void>(() => () => {});
        const [
            confirmDialogOnCancelCallback,
            setConfirmDialogOnCancelCallback
        ] = useState<() => void>(() => () => {});

        const showConfirmDialog = (
            content: string,
            title: string,
            onSuccessCallBack: () => void,
            onCancelCallback: () => void
        ) => {
            setConfirmDialogOpen(true);
            if (title) {
                setConfirmDialogTitle(title);
            }
            setConfirmDialogContent(content);
            setConfirmDialogOnSuccessCallback(() => () => {
                onSuccessCallBack();
            });
            if (onCancelCallback) {
                setConfirmDialogOnCancelCallback(() => () => {
                    onCancelCallback();
                });
            }
        };

        const handleConfirmContinue = () => {
            confirmDialogOnSuccessCallback();
            setConfirmDialogOpen(false);
        };

        const handleConfirmCancel = () => {
            confirmDialogOnCancelCallback();
            setConfirmDialogOpen(false);
        };

        return (
            <>
                <ConfirmDialog
                    dialogOpen={confirmDialogOpen}
                    title={confirmDialogTitle}
                    content={confirmDialogContent}
                    handleContinue={handleConfirmContinue}
                    handleCancel={handleConfirmCancel}
                />
                <WrappedComponent
                    {...rest}
                    showConfirmDialog={showConfirmDialog}
                />
            </>
        );
    };
};
