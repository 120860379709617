import { SvgIcon, SvgIconProps } from '@mui/material';

export const PendingApprovalsIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 16" width="16" height="16">
            <rect width="16" height="16" rx="3" />
            <path
                d="M4.6665 9.24967C4.6665 9.24967 5.08317 8.83301 6.33317 8.83301C7.58317 8.83301 8.4165 9.66634 9.6665 9.66634C10.9165 9.66634 11.3332 9.24967 11.3332 9.24967V4.24967C11.3332 4.24967 10.9165 4.66634 9.6665 4.66634C8.4165 4.66634 7.58317 3.83301 6.33317 3.83301C5.08317 3.83301 4.6665 4.24967 4.6665 4.24967V9.24967ZM4.6665 9.24967V12.1663"
                stroke="#F5FAFF"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </SvgIcon>
    );
};
