import { Typography, useTheme } from '@mui/material';

export enum FontFamily {
    Poppins = 'Poppins',
    Figtree = 'Figtree'
}

interface SideBarTextProps {
    label: string;
    fontWeight?: number;
    fontFamily?: FontFamily;
    style?: React.CSSProperties;
}

const SideBarText = ({ label, fontFamily, style }: SideBarTextProps) => {
    return (
        <Typography
            variant="h6"
            style={{
                fontSize: '12px',
                lineHeight: '12px',
                letterSpacing: '0.5px',
                textAlign: 'left',
                fontFamily: fontFamily ?? 'Figtree',
                ...style
            }}
        >
            {label}{' '}
        </Typography>
    );
};

export default SideBarText;
