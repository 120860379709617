import {
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Alert,
    TextFieldProps
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useLoader } from '../../../contexts/loader/LoaderContext';
import { User, UpdateUserRequest } from '../../../models/company';
import { MuiTelInput } from 'mui-tel-input';
import { Validator, useErrorHandling } from '../../../utils/errorHandling';
import UsersAPIServiceV2 from '../../../services/UserServiceV2';

type UpdateUserDialogProps = {
    userId?: string;
    open: boolean;
    onClose: () => void;
    onSubmit: (request: UpdateUserRequest) => Promise<void>;
    validator: Validator;
    setValidator: React.Dispatch<React.SetStateAction<Validator>>;
};

export const UpdateUserDialog = (props: UpdateUserDialogProps) => {
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [user, setUser] = useState<User>();
    const { setLoading } = useLoader();

    const { backendErrorHandler } = useErrorHandling();

    const onSubmit = () => {
        if (!props.userId) return;

        props.onSubmit({
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            id: props.userId
        });
    };

    useEffect(() => {
        if (!props.userId) return;

        setLoading(true);

        UsersAPIServiceV2.getInstance()
            .getUser(props.userId)
            .then((response: any) => {
                let responseUser = response.data as User;
                setUser(responseUser);
                setFirstName(responseUser.firstName);
                setLastName(responseUser.lastName);
                setPhoneNumber(responseUser.phoneNumber);
            })
            .catch(backendErrorHandler('Error fetching user: '))
            .finally(() => {
                setLoading(false);
            });
    }, [props.userId, backendErrorHandler, setLoading]);

    const commonProps: TextFieldProps = {
        margin: 'dense',
        fullWidth: true,
        variant: 'standard'
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                Edit user {user?.firstName} {user?.lastName}
            </DialogTitle>
            <DialogContent>
                <TextField
                    {...commonProps}
                    name="first_name"
                    label="First name"
                    value={firstName}
                    error={props.validator.hasError('firstName')}
                    helperText={props.validator.getError('firstName')}
                    onChange={(e) => {
                        setFirstName(e.currentTarget.value);
                        props.setValidator(
                            props.validator.clearError('firstName')
                        );
                    }}
                />

                <TextField
                    {...commonProps}
                    name="last_name"
                    label="Last name"
                    value={lastName}
                    error={props.validator.hasError('lastName')}
                    helperText={props.validator.getError('lastName')}
                    onChange={(e) => {
                        setLastName(e.currentTarget.value);
                        props.setValidator(
                            props.validator.clearError('lastName')
                        );
                    }}
                />

                <MuiTelInput
                    {...commonProps}
                    name="phone_number"
                    label="Phone number"
                    placeholder="+XX XXXXXX"
                    error={props.validator.hasError('phoneNumber')}
                    helperText={props.validator.getError(
                        'phoneNumber',
                        'Phone number including country code'
                    )}
                    value={phoneNumber}
                    onChange={(e) => {
                        setPhoneNumber(e);
                        props.setValidator(
                            props.validator.clearError('phoneNumber')
                        );
                    }}
                />

                <Alert severity="info" sx={{ marginTop: '1rem' }}>
                    Note: the changes you do here may be overwritten during
                    import of users from the salary provider. Please fix the
                    data in the salary provider system if needed.
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button variant="contained" onClick={onSubmit}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
