import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useNotificationsSnackbar } from '../components/snackbar/NotificationsSnackbarContext';

export class Validator {
    errors: BackendValidationErrors | undefined;

    constructor(errors?: BackendValidationErrors) {
        this.errors = errors;
    }

    hasError(fieldName: string) {
        return (this.errors && this.errors[fieldName] !== undefined) || false;
    }

    clearError(fieldName: string) {
        if (this.errors) {
            delete this.errors[fieldName];
        }
        return this;
    }

    clearErrors() {
        delete this.errors;
        return this;
    }

    getError(fieldName: string, fallbackMessage?: string) {
        if (this.errors && this.errors[fieldName]) {
            return (
                <>
                    {this.errors[fieldName].map((item) => (
                        <>
                            {item}
                            <br />
                        </>
                    ))}
                </>
            );
        }
        return fallbackMessage ?? '';
    }
}

export type BackendValidationErrors = Record<string, string[]> | null;

export type BackendError = {
    detail: string;
    instance: string;
    status: string;
    title: string;
    type: string;
    errors: BackendValidationErrors;
};

export function isBackendError(object: any): object is BackendError {
    return (
        object !== undefined &&
        typeof object === 'object' &&
        'detail' in object &&
        'instance' in object &&
        'status' in object &&
        'title' in object
    );
}

export const useErrorHandling = () => {
    const { setAlert } = useNotificationsSnackbar();

    const backendErrorHandler = useCallback(
        (
                defaultErrorMessage?: string,
                callback?: (e: Error, backendError?: BackendError) => void
            ) =>
            (e: Error, backendError?: BackendError) => {
                console.log(e);
                let errorDetails: BackendError | undefined = undefined;
                if (
                    e instanceof AxiosError &&
                    isBackendError(e.response?.data)
                ) {
                    errorDetails = e.response?.data as BackendError;
                    setAlert(errorDetails.detail, 'error');
                } else {
                    setAlert(
                        defaultErrorMessage
                            ? defaultErrorMessage + ' ' + e.message
                            : e.message,
                        'error'
                    );
                }

                if (callback) {
                    callback(e, errorDetails);
                }
            },
        [setAlert]
    );
    return { backendErrorHandler };
};
