import {
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextFieldProps,
    FormControlProps,
    FormHelperText
} from '@mui/material';
import { useState } from 'react';
import {
    ApprovalType,
    CreateCompanyRequest,
    LoginType,
    LowBalanceAudience,
    PaymentSource,
    approvalTypes,
    getLowBalanceAudienceLabel
} from '../../models/company';
import Autocomplete from '@mui/material/Autocomplete';
import countryList from 'react-select-country-list';

import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { Validator } from '../../utils/errorHandling';
import { MuiTelInput } from 'mui-tel-input';
import {
    PaymentProvider,
    PaymentSettingsCombination,
    SalaryProvider,
    TimeRegistrationProvider,
    paymentSettingsCombinations,
    salaryProviders,
    timeRegistrationProviders
} from '../../models/payment';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment, { Moment } from 'moment';
import { convertStringInputToNumber } from '../../utils/formatting';
import { currencyList } from '../../utils/currencyList';

type AddCompanyDialogProps = {
    open: boolean;
    onClose: () => void;
    onAdd: (
        request: CreateCompanyRequest,
        onSuccess: () => void
    ) => Promise<void>;
    validator: Validator;
    setValidator: React.Dispatch<React.SetStateAction<Validator>>;
};

export const AddCompanyDialog = (props: AddCompanyDialogProps) => {
    const sortedCurrency = currencyList.sort((a, b) => a.localeCompare(b));

    const currencyOptions = sortedCurrency.map((item) => {
        return { label: item, value: item };
    });

    const countryOptions = countryList().getData();

    const defaultPricePerUser = 19;
    const getInitialEndOfTrial = () => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth() + 1, 0, 0, 0, 0); // end of current month
    };

    const [companyName, setCompanyName] = useState<string>('');
    const [currency, setCurrency] = useState<string>('DKK');
    const [country, setCountry] = useState<string>('Denmark');
    const [tenantId, setTenantId] = useState<string>('');
    const [loginType, setLoginType] = useState<LoginType>(LoginType.AzureB2C);
    const [salaryProvider, setSalaryProvider] =
        useState<SalaryProvider>('Zenegy');
    const [timeRegistrationProvider, setTimeRegistrationProvider] =
        useState<TimeRegistrationProvider>('Zenegy');
    const [pricePerUser, setPricePerUser] = useState<string>(
        defaultPricePerUser.toString()
    );
    const [endOfTrial, setEndOfTrial] = useState<Date | null>(
        getInitialEndOfTrial()
    );
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [paymentCombination, setPaymentCombination] =
        useState<PaymentSettingsCombination>('InstaPaid');
    const [paymentProvider, setPaymentProvider] =
        useState<PaymentProvider>('None');
    const [paymentSource, setPaymentSource] =
        useState<PaymentSource>('InstaPaidAccount');
    const [approvalType, setApprovalType] = useState<ApprovalType>('Manual');
    const [lowBalanceNotification, setLowBalanceNotification] =
        useState<string>('');
    const [lowBalanceAudience, setLowBalanceAudience] =
        useState<LowBalanceAudience>('PendingWithdrawalsEmails');
    const [lowBalanceEmail, setLowBalanceEmail] = useState<string>('');
    const { setAlert } = useNotificationsSnackbar();

    const setPaymentCombinationWithDependancies = (
        newPaymentCombination: PaymentSettingsCombination
    ) => {
        setPaymentCombination(newPaymentCombination);
        if (newPaymentCombination === 'Danske Bank') {
            setPaymentProvider('DanskeBank');
            setPaymentSource('CompanyAccount');
        } else if (newPaymentCombination === 'InstaPaid') {
            setPaymentProvider('None');
            setPaymentSource('InstaPaidAccount');
        } else if (newPaymentCombination === 'Aiia') {
            setPaymentProvider('Aiia');
            setPaymentSource('CompanyAccount');
            setApprovalType('Manual'); // automatic approvals are not available for Aiia
        }
    };

    const dateToUTC = (date: Date) => {
        // make sure we send the data the user sees in the datepicker, ignoring the possible timezone offset
        return new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                17,
                0,
                0
            )
        );
    };

    const onAdd = () => {
        if (
            !companyName ||
            !currency ||
            !country ||
            !loginType ||
            !firstName ||
            !lastName ||
            !email ||
            !endOfTrial
        ) {
            setAlert('Please fill out all the fields', 'error');
            return;
        }
        const lowBalanceNotificationDecimal = convertStringInputToNumber(
            lowBalanceNotification
        );
        const pricePerUserDecimal = convertStringInputToNumber(pricePerUser);

        if (lowBalanceNotificationDecimal === undefined) {
            setAlert('Low balance notification must be a number', 'error');
            return;
        }

        if (pricePerUserDecimal === undefined) {
            setAlert('Price per user must be a number', 'error');
            return;
        }

        props.onAdd(
            {
                name: companyName,
                currency: currency,
                country: country,
                tenantId: tenantId,
                loginType: loginType,
                salaryProvider: salaryProvider,
                timeRegistrationProvider: timeRegistrationProvider,
                pricePerUser: pricePerUserDecimal ?? defaultPricePerUser,
                endOfTrial: dateToUTC(endOfTrial),
                paymentSource: paymentSource,
                paymentProvider: paymentProvider,
                approvalType: approvalType,
                lowBalanceNotificationThreshold: lowBalanceNotificationDecimal,
                lowBalanceAudience: lowBalanceAudience,
                lowBalanceEmail: lowBalanceEmail,
                admin: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phoneNumber: phoneNumber
                }
            },
            clearState
        );
    };

    const clearState = () => {
        setCompanyName('');
        setCurrency('');
        setCountry('');
        setTenantId('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setLowBalanceNotification('');
        setLowBalanceEmail('');
    };

    const commonProps: TextFieldProps = {
        margin: 'dense',
        fullWidth: true,
        variant: 'standard'
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Add new company</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Fill the fields below to create a new company and the first
                    admin user for this company
                </DialogContentText>

                <TextField
                    {...commonProps}
                    label="Company name"
                    value={companyName}
                    error={props.validator.hasError('companyName')}
                    helperText={props.validator.getError('companyName')}
                    onChange={(e) => {
                        setCompanyName(e.currentTarget.value);
                        props.setValidator(
                            props.validator.clearError('companyName')
                        );
                    }}
                />

                {
                    <Autocomplete
                        id="currency"
                        isOptionEqualToValue={(option, value) =>
                            option.value === value.value
                        }
                        options={currencyOptions}
                        value={currency as any}
                        onChange={(event, value) => {
                            setCurrency(value?.label || '');
                            props.setValidator(
                                props.validator.clearError('currency')
                            );
                        }}
                        renderInput={(params: any): any => (
                            <TextField
                                {...commonProps}
                                {...params}
                                error={props.validator.hasError('currency')}
                                helperText={props.validator.getError(
                                    'currency'
                                )}
                                label="Currency"
                            />
                        )}
                    />
                }
                <Autocomplete
                    id="country"
                    isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                    }
                    options={countryOptions}
                    value={country as any}
                    onChange={(event, value) => {
                        setCountry(value?.label || '');
                        props.setValidator(
                            props.validator.clearError('country')
                        );
                    }}
                    renderInput={(params: any): any => (
                        <TextField
                            {...commonProps}
                            {...params}
                            error={props.validator.hasError('country')}
                            helperText={props.validator.getError('country')}
                            label="Country"
                        />
                    )}
                />
                <FormControl {...(commonProps as FormControlProps)}>
                    <InputLabel>Login type</InputLabel>
                    <Select
                        variant="standard"
                        value={loginType}
                        label="Login type"
                        error={props.validator.hasError('loginType')}
                        onChange={(e) => {
                            setLoginType(e.target.value as LoginType);
                            props.setValidator(
                                props.validator.clearError('loginType')
                            );
                        }}
                    >
                        <MenuItem value={LoginType.AzureAD}>
                            Company login
                        </MenuItem>
                        <MenuItem value={LoginType.AzureB2C}>
                            Instapaid login
                        </MenuItem>
                    </Select>
                    <FormHelperText>
                        {props.validator.getError('loginType')}
                    </FormHelperText>
                </FormControl>

                {loginType === LoginType.AzureAD && (
                    <TextField
                        {...commonProps}
                        label={`${LoginType[loginType]} Tenant ID`}
                        value={tenantId}
                        error={props.validator.hasError('tenantId')}
                        helperText={props.validator.getError('tenantId')}
                        onChange={(e) => {
                            setTenantId(e.currentTarget.value);
                            props.setValidator(
                                props.validator.clearError('tenantId')
                            );
                        }}
                    />
                )}

                <FormControl {...(commonProps as FormControlProps)}>
                    <InputLabel>Salary provider</InputLabel>
                    <Select
                        variant="standard"
                        value={salaryProvider}
                        label="Salary provider"
                        error={props.validator.hasError('salaryProvider')}
                        onChange={(e) => {
                            setSalaryProvider(e.target.value as SalaryProvider);
                            props.setValidator(
                                props.validator.clearError('salaryProvider')
                            );
                        }}
                    >
                        {salaryProviders.map((salaryProvider) => (
                            <MenuItem
                                key={salaryProvider}
                                value={salaryProvider}
                            >
                                {salaryProvider}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                        {props.validator.getError('salaryProvider')}
                    </FormHelperText>
                </FormControl>

                <FormControl {...(commonProps as FormControlProps)}>
                    <InputLabel>Time registration provider</InputLabel>
                    <Select
                        variant="standard"
                        value={timeRegistrationProvider}
                        label="Time registration provider"
                        error={props.validator.hasError(
                            'timeRegistrationProvider'
                        )}
                        onChange={(e) => {
                            setTimeRegistrationProvider(
                                e.target.value as TimeRegistrationProvider
                            );
                            props.setValidator(
                                props.validator.clearError(
                                    'timeRegistrationProvider'
                                )
                            );
                        }}
                    >
                        {timeRegistrationProviders.map(
                            (timeRegistrationProvider) => (
                                <MenuItem
                                    key={timeRegistrationProvider}
                                    value={timeRegistrationProvider}
                                >
                                    {timeRegistrationProvider}
                                </MenuItem>
                            )
                        )}
                    </Select>
                    <FormHelperText>
                        {props.validator.getError('timeRegistrationProvider')}
                    </FormHelperText>
                </FormControl>

                <TextField
                    {...commonProps}
                    label="Price per user"
                    type="number"
                    helperText={props.validator.getError(
                        'pricePerUser',
                        'The amount the company pays for each user'
                    )}
                    error={props.validator.hasError('pricePerUser')}
                    value={pricePerUser}
                    onChange={(e) => {
                        setPricePerUser(e.currentTarget.value);
                        props.setValidator(
                            props.validator.clearError('pricePerUser')
                        );
                    }}
                />

                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                        renderInput={(parentProps) => (
                            <TextField
                                {...commonProps}
                                {...parentProps}
                                error={props.validator.hasError('endOfTrial')}
                                helperText={props.validator.getError(
                                    'endOfTrial'
                                )}
                            />
                        )}
                        label="End of trial"
                        value={endOfTrial}
                        minDate={moment()}
                        inputFormat="DD/MM/YYYY"
                        onChange={(newValue: Moment | null) => {
                            setEndOfTrial(
                                newValue
                                    ? newValue.toDate()
                                    : getInitialEndOfTrial()
                            );
                            props.setValidator(
                                props.validator.clearError('endOfTrial')
                            );
                        }}
                    />
                </LocalizationProvider>

                <FormControl
                    {...(commonProps as FormControlProps)}
                    error={
                        props.validator.hasError('paymentSource') ||
                        props.validator.hasError('paymentProvider')
                    }
                >
                    <InputLabel>Payout method</InputLabel>
                    <Select
                        variant="standard"
                        value={paymentCombination}
                        label="Payout method"
                        onChange={(e) => {
                            setPaymentCombinationWithDependancies(
                                e.target.value as PaymentSettingsCombination
                            );
                            props.setValidator(
                                props.validator.clearError('paymentSource')
                            );
                            props.setValidator(
                                props.validator.clearError('paymentProvider')
                            );
                        }}
                    >
                        {paymentSettingsCombinations.map((item) => (
                            <MenuItem key={item} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>
                        {props.validator.getError('paymentSource')}
                        {props.validator.getError('paymentProvider')}
                    </FormHelperText>
                </FormControl>

                {paymentCombination !== 'Aiia' && (
                    <FormControl
                        {...(commonProps as FormControlProps)}
                        error={props.validator.hasError('approvalType')}
                    >
                        <InputLabel>Approval type</InputLabel>
                        <Select
                            variant="standard"
                            value={approvalType}
                            label="Approval type"
                            onChange={(e) => {
                                setApprovalType(e.target.value as ApprovalType);
                                props.setValidator(
                                    props.validator.clearError('approvalType')
                                );
                            }}
                        >
                            {approvalTypes.map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>
                            {props.validator.getError(
                                'approvalType',
                                "If you choose manual, you'll need to manually approve each withdrawal request made by your employees. Auto-approvals are only supported by DanskeBank."
                            )}
                        </FormHelperText>
                    </FormControl>
                )}

                {paymentSource === 'InstaPaidAccount' && (
                    <>
                        <TextField
                            {...commonProps}
                            label="Low balance notification"
                            type="number"
                            helperText={props.validator.getError(
                                'lowBalanceNotification',
                                "If the company balance reaches below this number, you'll receive an email remainder to deposit funds"
                            )}
                            error={props.validator.hasError(
                                'lowBalanceNotification'
                            )}
                            value={lowBalanceNotification}
                            onChange={(e) => {
                                setLowBalanceNotification(
                                    e.currentTarget.value
                                );
                                props.setValidator(
                                    props.validator.clearError(
                                        'lowBalanceNotification'
                                    )
                                );
                            }}
                        />

                        <FormControl
                            {...(commonProps as FormControlProps)}
                            error={props.validator.hasError(
                                'lowBalanceAudience'
                            )}
                        >
                            <InputLabel>
                                Low balance notification audience
                            </InputLabel>
                            <Select
                                variant="standard"
                                value={lowBalanceAudience}
                                label="Low balance notification audience"
                                onChange={(e) => {
                                    setLowBalanceAudience(
                                        e.target.value as LowBalanceAudience
                                    );
                                    props.setValidator(
                                        props.validator.clearError(
                                            'lowBalanceAudience'
                                        )
                                    );
                                }}
                            >
                                {(
                                    [
                                        'AllAdmins',
                                        'PendingWithdrawalsEmails',
                                        'Custom'
                                    ] as LowBalanceAudience[]
                                ).map((item) => (
                                    <MenuItem key={item} value={item}>
                                        {getLowBalanceAudienceLabel(item)}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>
                                {props.validator.getError(
                                    'lowBalanceAudience',
                                    'Choose who should receive emails about your company account balance running low'
                                )}
                            </FormHelperText>
                        </FormControl>

                        {lowBalanceAudience === 'Custom' && (
                            <TextField
                                {...commonProps}
                                label="Emails to receive the notification about low company balance (comma-separated)"
                                helperText={props.validator.getError(
                                    'lowBalanceEmail',
                                    'This email address(-es) will receive the email notifications if your company balance reaches below the threshold above'
                                )}
                                error={props.validator.hasError(
                                    'lowBalanceEmail'
                                )}
                                value={lowBalanceEmail}
                                onChange={(e) => {
                                    setLowBalanceEmail(e.currentTarget.value);
                                    props.setValidator(
                                        props.validator.clearError(
                                            'lowBalanceEmail'
                                        )
                                    );
                                }}
                            />
                        )}
                    </>
                )}

                <Typography mt={3} mb={0} fontWeight="bold">
                    Admin user details:
                </Typography>

                <TextField
                    {...commonProps}
                    label="First name"
                    value={firstName}
                    error={props.validator.hasError('admin.FirstName')}
                    helperText={props.validator.getError('admin.FirstName')}
                    onChange={(e) => {
                        setFirstName(e.currentTarget.value);
                        props.setValidator(
                            props.validator.clearError('admin.FirstName')
                        );
                    }}
                />

                <TextField
                    {...commonProps}
                    label="Last name"
                    value={lastName}
                    error={props.validator.hasError('admin.LastName')}
                    helperText={props.validator.getError('admin.LastName')}
                    onChange={(e) => {
                        setLastName(e.currentTarget.value);
                        props.setValidator(
                            props.validator.clearError('admin.LastName')
                        );
                    }}
                />

                <TextField
                    {...commonProps}
                    label="Email Address"
                    type="email"
                    value={email}
                    error={props.validator.hasError('admin.Email')}
                    helperText={props.validator.getError('admin.Email')}
                    onChange={(e) => {
                        setEmail(e.currentTarget.value);
                        props.setValidator(
                            props.validator.clearError('admin.Email')
                        );
                    }}
                />

                <MuiTelInput
                    {...commonProps}
                    name="phone_number"
                    label="Phone number"
                    placeholder="+XX XXXXXX"
                    error={props.validator.hasError('admin.PhoneNumber')}
                    helperText={props.validator.getError(
                        'admin.PhoneNumber',
                        'Phone number including country code'
                    )}
                    value={phoneNumber}
                    onChange={(e) => {
                        setPhoneNumber(e);
                        props.setValidator(
                            props.validator.clearError('admin.PhoneNumber')
                        );
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button variant="contained" onClick={onAdd}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};
