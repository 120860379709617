import { Box, TextField, TextFieldProps } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState, useRef, useMemo } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

import { SecondaryButtonRegular } from './Button';
import dayjs from 'dayjs';
import { SMBold } from '../../typography/Typography';
import { DATE_FORMAT } from '../../lib/constants';

export interface MonthPickerProps {
    label: string;
    value: Date;
    onChange: (value: any | any[]) => void;
    onClose?: () => void;
}

export const MonthPicker = ({ value, onChange, onClose }: MonthPickerProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const customInputRef = useRef<any>();

    const label = useMemo(() => {
        if (value) {
            return dayjs(value).format(DATE_FORMAT.MONTH_YEAR);
        }
    }, [value]);

    const onCloseHandle = () => {
        setIsOpen(false);
        onClose ? onClose() : null;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                //label={label}
                value={value}
                open={isOpen}
                openTo="month"
                views={['year', 'month']}
                onChange={onChange}
                onClose={onCloseHandle}
                PopperProps={{ anchorEl: customInputRef.current }}
                renderInput={({
                    ref,
                    inputProps,
                    disabled,
                    onChange,
                    ...props
                }: TextFieldProps) => (
                    <>
                        <TextField
                            ref={customInputRef}
                            style={{ opacity: 0, width: 0, height: 0 }}
                            {...props}
                        />
                        <SecondaryButtonRegular
                            onClick={() => setIsOpen((prev) => !prev)}
                            size={'small'}
                        >
                            <CalendarTodayOutlinedIcon></CalendarTodayOutlinedIcon>
                            <Box ml={1}>
                                <SMBold>{label}</SMBold>
                            </Box>
                        </SecondaryButtonRegular>
                    </>
                )}
            />
        </LocalizationProvider>
    );
};
