import { useCallback, useEffect, useState } from 'react';
import {
    CompanyPaymentDetailsResponse,
    IntegrationStatusResponse
} from '../../models/payment';
import { ImportUsers } from '../users/import/ImportUsers';
import { ZenegyConnect } from './ZenegyConnect';
import {
    Typography,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Container
} from '@mui/material';
import { ZenegyImportSettings } from './ZenegyImportSettings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import { PayrollConnectionStatusHeader } from '../payroll/PayrollConnectionStatusHeader';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export type ZenegyConnectStatusProps = {
    company: CompanyPaymentDetailsResponse;
    onSaveSuccess: () => void;
    showImport: boolean;
};

export const ZenegyConnectStatus = (props: ZenegyConnectStatusProps) => {
    const { setLoading } = useLoader();

    const { backendErrorHandler } = useErrorHandling();
    const [integrationStatus, setIntegrationStatus] =
        useState<IntegrationStatusResponse>();
    const [open, setOpen] = useState(props.company.zenegyCompanyId === null);

    const fetchIntegrationStatus = useCallback(() => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getIntegrationStatus('zenegy')
            .then((response: any) => {
                setIntegrationStatus(response.data);
            })
            .catch(backendErrorHandler())
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, backendErrorHandler]);

    useEffect(() => {
        fetchIntegrationStatus();
    }, [fetchIntegrationStatus]);

    return (
        <>
            <Typography variant="h5" marginBottom={1}>
                Zenegy settings
            </Typography>

            <Accordion
                expanded={open}
                onChange={(event: React.SyntheticEvent, newExpanded: boolean) =>
                    setOpen(newExpanded)
                }
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <PayrollConnectionStatusHeader
                        externalCompanyId={props.company.zenegyCompanyId}
                        providerName="Zenegy"
                        status={integrationStatus?.status}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <Container>
                        {props.company.zenegyCompanyId && (
                            <>
                                {integrationStatus?.status === 'Connected' && (
                                    <>
                                        <Box>
                                            Your company is connected to Zenegy
                                            company{' '}
                                            <strong>
                                                {props.company.zenegyCompanyId}
                                            </strong>
                                            .
                                        </Box>
                                        <Box>
                                            Click the button below to go through
                                            the connect flow again
                                        </Box>
                                        <ZenegyConnect />
                                    </>
                                )}
                                {integrationStatus?.status ===
                                    'ConnectionError' && (
                                    <>
                                        <Box>
                                            Connection to company{' '}
                                            <strong>
                                                {props.company.zenegyCompanyId}
                                            </strong>{' '}
                                            could not be established.
                                        </Box>
                                        <Box>
                                            Click the button below to go through
                                            the connect flow again
                                        </Box>
                                        <ZenegyConnect />
                                    </>
                                )}
                                {integrationStatus?.status ===
                                    'NotConnected' && (
                                    <>
                                        <Box>
                                            You have not connected a Zenegy
                                            account yet.
                                        </Box>
                                        <Box>
                                            Click the button below to connect.
                                        </Box>
                                        <ZenegyConnect />
                                    </>
                                )}
                            </>
                        )}
                        {!props.company.zenegyCompanyId && (
                            <>
                                <Box>
                                    You have not connected a Zenegy account yet.
                                </Box>
                                <Box>Click the button below to connect.</Box>
                                <Box sx={{ marginTop: '1rem' }}>
                                    <ZenegyConnect />
                                </Box>
                            </>
                        )}
                    </Container>
                </AccordionDetails>
            </Accordion>

            {props.company?.zenegyCompanyId && props.showImport && (
                <ImportUsers
                    onImportSuccess={props.onSaveSuccess}
                    importSettings={ZenegyImportSettings}
                />
            )}
        </>
    );
};
