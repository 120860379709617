import { Button, Box, Card, CardContent } from '@mui/material';
import { UnauthenticatedTemplate } from '@azure/msal-react';
import { useConfiguration } from '../contexts/configuration/ConfigurationContext';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import { LoginType } from '../models/company';
import { useEffect, useRef } from 'react';

export const LoginPage = () => {
    const { loginAD, loginB2C } = useConfiguration();
    const [searchParams] = useSearchParams();
    let loginHint = searchParams.get('login_hint');
    let loginType = searchParams.get('login_type')
        ? LoginType[searchParams.get('login_type') as keyof typeof LoginType]
        : undefined;

    return (
        <UnauthenticatedTemplate>
            <Box sx={{ maxWidth: '30rem', margin: 'auto' }}>
                <Card sx={{ textAlign: 'center' }}>
                    <CardContent>
                        Please login to access the InstaPaid backoffice.
                        <Routes>
                            <Route
                                path=""
                                element={
                                    <Button
                                        sx={{ marginTop: '1rem' }}
                                        variant="contained"
                                        fullWidth
                                        title="Instapaid login"
                                        onClick={loginB2C}
                                    >
                                        Instapaid login
                                    </Button>
                                }
                            />
                            <Route
                                path="/company-login"
                                element={
                                    <Button
                                        sx={{ marginTop: '1rem' }}
                                        variant="contained"
                                        fullWidth
                                        title="Company login"
                                        onClick={() => loginAD(loginHint)}
                                    >
                                        Company login
                                    </Button>
                                }
                            />
                        </Routes>
                        {/* {(loginType === LoginType.AzureB2C ||
                            loginType === undefined) && (

                        )}
                        {(loginType === LoginType.AzureAD ||
                            loginType === undefined) && (

                        )} */}
                    </CardContent>
                </Card>
            </Box>
        </UnauthenticatedTemplate>
    );
};
