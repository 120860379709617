import { TEXT_SIZE } from '../../typography/Typography';
import {
    TextField,
    TextFieldProps,
    Typography,
    InputAdornment,
    Box
} from '@mui/material';
import styled from '@emotion/styled';
import { staticColors } from '../../lib/theme';

export const TypographyContainer = styled(Typography)`
    :after {
        content: attr(data-text);
        color: ${staticColors.error};
        position: absolute;
    }
`;

export const InputText = ({
    label,
    placeholder,
    error,
    value,
    onChange,
    required,
    helperText,
    inputAdornment,
    spacing,
    disabled
}: TextFieldProps & { inputAdornment?: React.ReactNode; spacing?: string }) => {
    return (
        <Box style={{ margin: spacing }}>
            <TypographyContainer
                fontWeight={500}
                fontSize={TEXT_SIZE.MEDIUM_SMALL}
                data-text={required ? '*' : ''}
            >
                {label}
            </TypographyContainer>
            <TextField
                fullWidth
                value={value}
                placeholder={placeholder}
                size="small"
                disabled={disabled}
                id="outlined-basic"
                variant="outlined"
                onChange={onChange}
                error={error}
                helperText={helperText}
                sx={{
                    margin: '3px 0',
                    ...(disabled && {
                        backgroundColor: '#f5f5f5',
                        color: '#a1a1a1',
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#D0D5DD'
                        }
                    })
                }}
                InputProps={{
                    sx: { borderRadius: '8px' },
                    endAdornment: (
                        <InputAdornment position="end">
                            {inputAdornment}
                        </InputAdornment>
                    )
                }}
            />
        </Box>
    );
};
