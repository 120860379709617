import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ReactNode, useEffect, useMemo } from 'react';
import { grey } from '@mui/material/colors';
import { isNumber } from '@microsoft/applicationinsights-core-js';
import { staticColors } from '../../lib/theme';

const SwitchSelect = styled.div`
    margin-top: ${(props: any) => props.theme.spacing(2)};
    display: flex;
    position: relative;
    button:not(:last-child) {
        margin-right: 10px;
    }
`;

interface BadgeProps {
    selected?: boolean;
}

const Badge = styled.div<BadgeProps>`
    background: ${(props: any) =>
        props.selected ? props.theme.palette.primary.main : grey[100]};
    color: ${(props) => (props.selected ? 'white' : grey[700])};
    border-radius: 50%;
    height: 28px;
    width: 28px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export interface SwitchItem {
    primaryText: string;
    badgeText: string;
    route: string;
    search?: string;
    path?: string;
    overrideTestPath?: string;
    routeComponent: ReactNode;
}

interface ViewSwitchProps {
    itemList: SwitchItem[];
    setSelectedIndex: (index: number | null) => void;
    selectedIndex: number | null;
}

export const ViewSwitch = ({
    itemList,
    setSelectedIndex,
    selectedIndex
}: ViewSwitchProps) => {
    const selectedStyle = (index: number) =>
        index == selectedIndex
            ? {
                  zIndex: 10
              }
            : {
                  color: 'rgba(0, 0, 0, 0.54)', //TDOD: change
                  borderBottom: '0px'
              };

    const navigate = useNavigate();

    useEffect(() => {
        if (selectedIndex != null && itemList && itemList[selectedIndex]) {
            if (
                !location.pathname.indexOf(
                    itemList[selectedIndex]?.overrideTestPath ?? ''
                )
            ) {
                navigate('./' + itemList[selectedIndex]?.route, {
                    replace: true
                });
            }
        }

        //Temp patch when list length is 1 , need to revisit this
        // if (itemList?.length == 1 && selectedIndex == null) {
        //     navigate('./' + itemList[0]?.route, {
        //         replace: true
        //     });
        // }
    }, [selectedIndex, itemList, location.pathname]);

    const params = useParams();

    useEffect(() => {
        if (itemList) {
            //use path if already there,  else select the first
            if ('' == params['*'] || params['*'] == undefined) {
                setSelectedIndex(0);
            }
        }
    }, [itemList, params]);

    const changeView = (index: number) => {
        setSelectedIndex(index);
        navigate('./' + itemList[index]?.route, {
            replace: true
        });
    };

    useEffect(() => {
        let matched = false;
        itemList?.forEach((el, index) => {
            const regex = new RegExp(el.route);
            let isMatch = regex.test(window.location.pathname);

            if (el.overrideTestPath) {
                isMatch = location.pathname.indexOf(el.overrideTestPath) > -1;
            }

            if (isMatch) {
                setSelectedIndex(index);
                matched = true;
            }
        });

        if (!matched && selectedIndex != 0 && selectedIndex != null) {
            setSelectedIndex(0);
        }
    }, [itemList, location.pathname]);

    const getBadgeText = (el: SwitchItem, selected: boolean) => {
        let badgeText = el.badgeText;
        if (+el.badgeText && parseInt(el.badgeText) > 100) {
            badgeText = '99+';
        }
        return <Badge selected={selected}>{badgeText}</Badge>;
    };

    const rendered = useMemo(() => {
        return (
            <>
                {itemList && (
                    <>
                        {' '}
                        <SwitchSelect>
                            {itemList.map((el, index) => (
                                <Button
                                    variant="outlined"
                                    key={index}
                                    onClick={() => changeView(index)}
                                    style={{
                                        border: 'none',
                                        borderBottom: '2px solid',
                                        borderRadius: '10px 10px 0px 0px',
                                        textTransform: 'capitalize',
                                        ...selectedStyle(index)
                                    }}
                                >
                                    <Typography>{el.primaryText}</Typography>
                                    {el.badgeText && (
                                        <Badge
                                            selected={index == selectedIndex}
                                        >
                                            {el.badgeText}
                                        </Badge>
                                    )}
                                </Button>
                            ))}
                            <div
                                style={{
                                    height: '0.5px',
                                    width: '100%',
                                    background: staticColors.buttonGreyText,
                                    borderRadius: '2px',
                                    position: 'absolute',
                                    right: 0,
                                    zIndex: '9',
                                    bottom: '1px'
                                }}
                            ></div>
                        </SwitchSelect>
                        <Box m={3}></Box>
                        <Routes>
                            {itemList.map((el, index) => {
                                return (
                                    <Route
                                        key={index}
                                        path={el?.path ?? el?.route}
                                        element={el?.routeComponent}
                                    />
                                );
                            })}
                        </Routes>
                    </>
                )}
            </>
        );
    }, [selectedIndex, itemList]);

    return <>{rendered}</>;
};
