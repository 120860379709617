import { Box } from '@mui/material';
import { ApprovalType } from '../../models/company';

export type TransactionApprovalDetailsProps = {
    approvalType: ApprovalType | undefined;
};

export const TransactionApprovalDetails = (
    props: TransactionApprovalDetailsProps
) => {
    return (
        <>
            {props.approvalType === 'Automatic' && (
                <Box>
                    The withdrawal requests of your employees are approved{' '}
                    <b>automatically</b>.
                </Box>
            )}
            {props.approvalType === 'Manual' && (
                <Box>
                    The withdrawal requests of your employees have to be{' '}
                    <b>manually</b> approved by admins.
                </Box>
            )}
        </>
    );
};
