import { ContentWrapper } from '../../components/ContentWrapper';
import { TransactionsList } from './TransactionsList';
import { Typography } from '@mui/material';

export const PaymentsPage = () => {
    return (
        <ContentWrapper>
            <>
                <Typography variant="h5">Pending transactions</Typography>
                <TransactionsList />
            </>
        </ContentWrapper>
    );
};
