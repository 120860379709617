import { useState } from 'react';
import { Validator, useErrorHandling } from '../../utils/errorHandling';
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';
import { DeleteCompanyRequest } from '../../models/payment';
import { useLoader } from '../../contexts/loader/LoaderContext';

import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export type DeleteCompanyDialogProps = {
    companyId: string;
    companyName: string;
    open: boolean;
    validator: Validator;
    setValidator: React.Dispatch<React.SetStateAction<Validator>>;
    onClose: () => void;
    onSubmit: (request: DeleteCompanyRequest) => Promise<void>;
};

export const DeleteCompanyDialog = (props: DeleteCompanyDialogProps) => {
    const [verificationCode, setVerificationCode] = useState<string>('');
    const { setLoading } = useLoader();
    const { backendErrorHandler } = useErrorHandling();

    const { setAlert } = useNotificationsSnackbar();

    const onSubmit = () => {
        if (!props.companyId) return;

        props.onSubmit({
            companyId: props.companyId,
            verificationCode: verificationCode
        });
    };

    const onSendNewCode = () => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .sendDeleteCompanyVerificationEmail({ companyId: props.companyId })
            .then(() => {
                setAlert(
                    'New verification code has been sent to your email',
                    'success'
                );
            })
            .catch(
                backendErrorHandler(
                    'Could not send deletion verification code.'
                )
            )
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>
                You are deleting {props.companyName} company
            </DialogTitle>
            <DialogContent>
                <Box sx={{ marginBottom: '1rem' }}>
                    We've sent a confirmation code to your email, it will be
                    valid for 3 minutes. Please input the code below to complete
                    the action.
                </Box>

                <TextField
                    name="verification_code"
                    label="Verification code"
                    fullWidth
                    variant="standard"
                    value={verificationCode}
                    onChange={(e) => {
                        setVerificationCode(e.currentTarget.value);
                        props.setValidator(
                            props.validator.clearError('verificationCode')
                        );
                    }}
                    error={props.validator.hasError('verificationCode')}
                    helperText={props.validator.getError('verificationCode')}
                />

                <Alert severity="warning" sx={{ marginTop: '1rem' }}>
                    Note: this action cannot be undone.
                </Alert>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                {props.validator.errors && (
                    <Button variant="contained" onClick={onSendNewCode}>
                        Send again
                    </Button>
                )}
                <Button variant="contained" color="error" onClick={onSubmit}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};
