import { Box, Typography } from '@mui/material';
import { CompanyPaymentDetailsResponse } from '../../models/payment';
import { TransactionApprovalDetails } from './TransactionApprovalDetails';

export type LumpSumStatusProps = {
    companyPaymentDetails: CompanyPaymentDetailsResponse;
};

export const LumpSumStatus = (props: LumpSumStatusProps) => {
    return (
        <>
            <Typography variant="h5">Payment provider settings</Typography>
            <Box>
                Your company uses <b>Lump Sum</b> feature to approve
                transactions using the Instapaid account.
            </Box>

            <TransactionApprovalDetails
                approvalType={props.companyPaymentDetails?.approvalType}
            />
        </>
    );
};
