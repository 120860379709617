import { NavigateFunction } from 'react-router-dom';

export const COMPANY_LOCAL_STORAGE_KEY = 'companyId';

export const setCompanyIdInLocalStorage = (
    companyId: string,
    navigate?: NavigateFunction
) => {
    window.sessionStorage.setItem(COMPANY_LOCAL_STORAGE_KEY, companyId);
    navigate?.('/');
    setTimeout(() => {
        location.reload();
    }, 1);
};

export const setCompanyIdInLocalStorageWithoutReload = (companyId: string) => {
    window.sessionStorage.setItem(COMPANY_LOCAL_STORAGE_KEY, companyId);
};

export const getCompanyIdFromLocalStorage = () => {
    return window.sessionStorage.getItem(COMPANY_LOCAL_STORAGE_KEY);
};
