import { IconButton, Typography } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { useErrorHandling } from '../../../utils/errorHandling';
import { useNotificationsSnackbar } from '../../../components/snackbar/NotificationsSnackbarContext';
import { useLoader } from '../../../contexts/loader/LoaderContext';
import PaymentsAPIServiceV2 from '../../../services/PaymentsServiceV2';

export type ImportFiltersHeaderProps = {
    onRefreshSuccess: () => void;
};

export const ImportFiltersHeader = (props: ImportFiltersHeaderProps) => {
    const { backendErrorHandler } = useErrorHandling();
    const { setAlert } = useNotificationsSnackbar();
    const { setLoading } = useLoader();

    const onSyncClick = () => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .refreshImportParameters()
            .then(() => {
                props.onRefreshSuccess();
                setAlert('Refreshed successfully', 'success');
            })
            .catch(backendErrorHandler('Could not refresh import filters: '))
            .finally(() => setLoading(false));
    };

    return (
        <Typography margin="1rem 0 0 0" variant="h6">
            Import settings
            <IconButton
                onClick={onSyncClick}
                aria-label="Reload import filters"
                title="Click to load latest filters data from the salary provider"
            >
                <SyncIcon />
            </IconButton>
        </Typography>
    );
};
