import { useEffect, useState } from 'react';
import { ContentWrapper } from '../../components/ContentWrapper';
import { PageSubHeading } from '../../typography/Typography';
import { useTranslation } from 'react-i18next';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';
import { EmployeesFilters } from './EmployeesFilters';
import { EmployeesTable } from './EmployeeTable';
import { UserDetailed, UsersDetailedResponse } from '../../models/payment';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useEmployeesOverview } from '../users/EmployeesOverview';
import { use } from 'i18next';
import { getFilterToParams } from '../users/UserActiveFilter';
import { useNavigate, useParams } from 'react-router-dom';
import {
    WithConfirmation,
    WithConfirmationWrappedComponentProps
} from '../../utils/withConfirmation';
import { useEmployeeStatusConfirmation } from '../users/useEmployeeStatusConfirmation';
import { usePagination } from '../../components/shared/TablePagination';

export const AllEmployeesPage = WithConfirmation(
    ({ showConfirmDialog }: WithConfirmationWrappedComponentProps) => {
        const { t } = useTranslation();

        const [username, setUsername] = useState<string>('');
        const [userDetailResponse, setUserDetailResponse] = useState<
            UsersDetailedResponse<UserDetailed>
        >({
            users: [],
            total: 0,
            filteredTotal: 0
        });

        const [activeType, setActiveType] = useState<string>('');

        const [rowsPerPage, setRowsPerPage] = useState<number>(16);

        const { setAlert } = useNotificationsSnackbar();
        const { page, resetPage } = usePagination();

        const fetchSearch = async () => {
            if (!username || username == '' || username?.length < 3) {
                setUserDetailResponse({
                    users: [],
                    total: 0,
                    filteredTotal: 0
                });
                return;
            }
            try {
                let activeStatus = getFilterToParams(activeType);
                const results =
                    await PaymentsAPIServiceV2.getInstance().searchByUsername({
                        username,
                        limit: rowsPerPage,
                        offset: page,
                        ...activeStatus
                    });
                setUserDetailResponse(results.data);
                console.log('results', results);
            } catch (e) {
                setAlert('Error fetching users', 'error');
            }
        };

        const [checked, setChecked] = useState<string[]>([]);

        useEffect(() => {
            fetchSearch();
        }, [username, activeType, page, rowsPerPage]);

        useEffect(() => {
            if (username) {
                resetPage();
            }
        }, [username]);

        const { handleStatusChangeWithCompanyId } =
            useEmployeeStatusConfirmation(showConfirmDialog, fetchSearch);

        return (
            <ContentWrapper>
                <>
                    <PageSubHeading>
                        {t('allEmployees_subHeading')}
                    </PageSubHeading>
                    <EmployeesFilters
                        onUsernameChange={(name) => {
                            setUsername(name);
                        }}
                        selectedActiveType={activeType}
                        onActiveTypeChange={(value) => {
                            setActiveType(value as string);
                        }}
                        onResetFilters={() => {
                            setUsername('');
                            setActiveType('');
                        }}
                    />
                    <EmployeesTable
                        usersDetailed={userDetailResponse}
                        checked={checked}
                        setChecked={setChecked}
                        allChecked={false}
                        onRowClick={() => {}}
                        onSendActivationMailClicked={() => {}}
                        rowsPerPage={rowsPerPage}
                        total={userDetailResponse.filteredTotal}
                        handleChangeRowsPerPage={(val) => {
                            setRowsPerPage(val);
                        }}
                        onStatusClick={function (
                            userId: string,
                            companyId: string,
                            isActive: boolean
                        ): void {
                            handleStatusChangeWithCompanyId(
                                userId,
                                companyId,
                                isActive,
                                fetchSearch
                            );
                        }}
                    />
                </>
            </ContentWrapper>
        );
    }
);
