export const cardStyles: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    margin: '10px',
    height: '140px',
    justifyContent: 'space-around',
    borderRadius: '8px',
    boxShadow:
        '0px 1px 2px 0px rgba(16, 24, 40, 0.06); box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1)'
};
