import { Button, Box, Typography, Alert } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useLoader } from '../../../contexts/loader/LoaderContext';
import { useNotificationsSnackbar } from '../../../components/snackbar/NotificationsSnackbarContext';
import { JobDetailsResponse } from '../../../models/payment';
import { useErrorHandling } from '../../../utils/errorHandling';
import { ImportJobDetails } from './ImportJobDetails';
import PaymentsAPIServiceV2 from '../../../services/PaymentsServiceV2';

export type ImportSettingsProps = {
    setSettingsTouched: (settingsTouched: boolean) => void;
};

export type ImportUsersProps = {
    importSettings: (props: ImportSettingsProps) => JSX.Element;
    onImportSuccess: () => void;
};

export const ImportUsers = (props: ImportUsersProps) => {
    const { setLoading } = useLoader();

    const { setAlert } = useNotificationsSnackbar();
    const { backendErrorHandler } = useErrorHandling();
    const [jobDetails, setJobDetails] = useState<JobDetailsResponse>();
    const [settingsTouched, setSettingsTouched] = useState(false);
    const initialJobLoaded = useRef(false);

    const onClick = () => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .importUsers()
            .then((response: any) => {
                setJobDetails(undefined);
                onRefreshJobStatus(response.data.jobId, undefined);
                props.onImportSuccess();
                setAlert('Import initiated successfully.', 'success');
            })
            .catch(backendErrorHandler('Could not import users: '))
            .finally(() => {
                setLoading(false);
            });
    };

    const isCurrentJobPending = useCallback(
        (jobDetails: JobDetailsResponse | undefined): boolean => {
            return (
                jobDetails !== undefined &&
                (jobDetails.jobStatus === 'InProgress' ||
                    jobDetails.jobStatus === 'NotStarted')
            );
        },
        []
    );

    const onRefreshJobStatus = useCallback(
        (
            jobId: string | undefined,
            jobDetails: JobDetailsResponse | undefined
        ) => {
            if (
                !jobId ||
                (jobDetails !== undefined && !isCurrentJobPending(jobDetails))
            )
                return;

            setLoading(true);
            PaymentsAPIServiceV2.getInstance()
                .getJobStatus(jobId)
                .then((response: any) => {
                    setJobDetails(response.data);
                })
                .catch(backendErrorHandler('Could not refresh job status: '))
                .finally(() => {
                    setLoading(false);
                });
        },
        [setJobDetails, backendErrorHandler, setLoading, isCurrentJobPending]
    );

    const loadPendingJob = useCallback(() => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getCurrentImportJob()
            .then((response: any) => {
                if (response.data) {
                    const currentJob = response.data as JobDetailsResponse;
                    setJobDetails(currentJob);
                    onRefreshJobStatus(currentJob.jobId, currentJob);
                } else {
                    setJobDetails(undefined);
                }
            })
            .catch(
                backendErrorHandler('Error while loading current import job: ')
            )
            .finally(() => {
                initialJobLoaded.current = true;
                setLoading(false);
            });
    }, [backendErrorHandler, setLoading, onRefreshJobStatus]);

    const onCancelJob = useCallback(
        (jobId: string | undefined) => {
            if (!jobId) return;

            setLoading(true);
            PaymentsAPIServiceV2.getInstance()
                .cancelJob(jobId)
                .then(() => {
                    loadPendingJob();
                    setAlert('Job cancelled successfully', 'success');
                })
                .catch(
                    backendErrorHandler('Error while cancelling import job: ')
                )
                .finally(() => {
                    initialJobLoaded.current = true;
                    setLoading(false);
                });
        },
        [setLoading, setAlert, loadPendingJob, backendErrorHandler]
    );

    useEffect(() => {
        if (!initialJobLoaded.current) {
            loadPendingJob();
        }

        const intervalId = setInterval(() => {
            if (jobDetails?.jobId) {
                onRefreshJobStatus(jobDetails?.jobId, jobDetails);
            }
        }, 5000);

        return () => clearInterval(intervalId);
    }, [loadPendingJob, onRefreshJobStatus, jobDetails]);

    return (
        <>
            <Typography marginTop="1rem" variant="h5">
                Import users
            </Typography>
            <Box>
                Click the button below to import users (they will be imported as
                inactive, you can enable the profiles on the 'Users' tab)
            </Box>
            <Box marginTop="1rem">
                The user data will be updated using data if you re-import the
                same users again. The only exception are the bank details - if
                they were updated manually on InstaPaid side, they will not be
                overriden by data from payroll provider.
            </Box>

            {<props.importSettings setSettingsTouched={setSettingsTouched} />}

            {settingsTouched && (
                <Alert sx={{ marginBottom: 1 }} severity="warning">
                    Please save the import settings or cancel the changes to
                    import
                </Alert>
            )}

            {isCurrentJobPending(jobDetails) && (
                <Alert sx={{ marginBottom: 1 }} severity="warning">
                    The import job is in progress. Please wait for it to
                    complete or click the 'Cancel Job' button.
                </Alert>
            )}

            <Button
                disabled={settingsTouched || isCurrentJobPending(jobDetails)}
                onClick={onClick}
                variant="contained"
            >
                Import users from payroll system
            </Button>

            {jobDetails && (
                <>
                    <br />
                    <ImportJobDetails jobDetails={jobDetails} />

                    {isCurrentJobPending(jobDetails) && (
                        <Stack mb={2} spacing={2} direction="row">
                            <Button
                                onClick={() =>
                                    onRefreshJobStatus(
                                        jobDetails?.jobId,
                                        jobDetails
                                    )
                                }
                                variant="outlined"
                            >
                                Refresh status
                            </Button>

                            <Button
                                onClick={() => onCancelJob(jobDetails?.jobId)}
                                variant="contained"
                                color="error"
                            >
                                Cancel job
                            </Button>
                        </Stack>
                    )}
                </>
            )}
        </>
    );
};
