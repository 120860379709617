import { MSALHolder } from '../lib/msal';
import BaseAPI from './baseServiceV2';
import { AxiosInstance } from 'axios';

class ReportsInternalService extends BaseAPI {
    client!: AxiosInstance;

    static instance: ReportsInternalService;

    private constructor() {
        super();
        (async () => {
            const REPORTS_INTERNAL_API_BASE_URL = (
                await MSALHolder.getInstance()
            ).getSpaConfiguration()?.backendReportsInternalServiceURL;
            this.client = this.getClient(REPORTS_INTERNAL_API_BASE_URL!);
        })();

        ReportsInternalService.instance = this;
    }

    getDepartments = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`api/departments`, { headers });
    };

    getEmployeeSickness = async (departments: string[]) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`api/employeeSickness`, {
            headers,
            params: { departments: departments.join(',') }
        });
    };

    static getInstance = () => {
        if (this.instance) {
            return this.instance;
        } else {
            this.instance = new ReportsInternalService();
            return this.instance;
        }
    };
}

export default ReportsInternalService;
