import { SvgIconProps, SvgIcon } from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';

export const SearchIcon = createSvgIcon(
    <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21 21L16.65 16.65M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>,
    'SearchIcon'
);
