import { Divider, Grid } from '@mui/material';
import {
    FONT_WEIGHT,
    SMBold,
    SMSemiBold,
    TEXT_SIZE
} from '../../../typography/Typography';
import { useTheme } from '@emotion/react';
import { useContext, useMemo } from 'react';
import { useFormatting } from '../../../utils/formatting';
import { UserContext } from '../../../contexts/user/UserContext';
import _ from 'lodash';
import {
    TableCell,
    TableCellNoBorder,
    TableRow
} from '../../../components/shared/styles/Table';
import { staticColors } from '../../../lib/theme';

export enum BalanceSummaryItemType {
    BALANCE,
    SUB_TOTAL,
    TOTAL
}

export interface BalanceSummaryListItemProps {
    label: string;
    key?: any;
    type?: BalanceSummaryItemType;
    balance?: number | string | React.ReactNode;
    col1?: string | number;
    col2?: string | number;
    subLabel?: string;
    showDivider?: boolean;
    balanceColor?: string;
}

const greyTextStyle = { color: 'grey' };

export const BalanceSummaryListItem = ({
    balance,
    key,
    type = BalanceSummaryItemType.BALANCE,
    label,
    col1,
    col2,
    subLabel,
    showDivider,
    balanceColor
}: BalanceSummaryListItemProps) => {
    const theme = useTheme();

    const { getBalanceLabel } = useFormatting();
    const { company } = useContext(UserContext);

    const color = useMemo(() => {
        if (balanceColor) {
            return balanceColor;
        }
        if (_.isNumber(balance) && balance >= 0) {
            return theme.palette.text.success;
        } else {
            return theme.palette.text.error;
        }
    }, [balance, balanceColor]);

    const backgroundColor = useMemo(() => {
        switch (type) {
            case BalanceSummaryItemType.TOTAL:
                return staticColors.rowBackgroundDeep;
            case BalanceSummaryItemType.SUB_TOTAL:
                return staticColors.rowBackgroundLight;
            default:
                return undefined;
        }
    }, [type]);

    const fontWeight = useMemo(() => {
        return type == BalanceSummaryItemType.SUB_TOTAL ||
            type == BalanceSummaryItemType.TOTAL
            ? FONT_WEIGHT.SEMI_BOLD
            : FONT_WEIGHT.MEDIUM;
    }, [type]);

    const textColor = useMemo(() => {
        return type == BalanceSummaryItemType.TOTAL
            ? 'white'
            : theme.palette.text.primary;
    }, [type]);

    return (
        <TableRow
            key={key}
            style={{
                backgroundColor,
                borderBottom: 'transparent'
            }}
        >
            <TableCellNoBorder>
                <SMSemiBold color={textColor} fontWeight={fontWeight}>
                    {label}{' '}
                    {subLabel && <span style={greyTextStyle}>{subLabel}</span>}
                </SMSemiBold>
            </TableCellNoBorder>
            <TableCellNoBorder style={greyTextStyle} align="right">
                {col1}
            </TableCellNoBorder>
            <TableCellNoBorder style={greyTextStyle} align="right">
                {col2}
            </TableCellNoBorder>
            <TableCellNoBorder align="right">
                <SMSemiBold color={textColor} fontWeight={fontWeight}>
                    {typeof balance == 'number'
                        ? getBalanceLabel(
                              balance,
                              company?.currency,
                              undefined,
                              true
                          )
                        : balance}
                </SMSemiBold>
            </TableCellNoBorder>
        </TableRow>
    );
};
