import {
    Table,
    TableBody,
    TableContainer,
    Button,
    Menu,
    MenuItem,
    useTheme,
    Card,
    Stack,
    Typography
} from '@mui/material';
import { useUser } from '../../contexts/user/UserContext';
import {
    PaymentsUser,
    UserDetailed,
    UsersDetailedResponse
} from '../../models/payment';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import Chip, { chipColors } from '../../components/shared/Chip';
import {
    TableCell,
    TableCellNoPadding,
    TableHeader,
    TableHeaderCell,
    TableHeaderCellWithCheckbox,
    TableRow,
    TableSortLabel
} from '../../components/shared/styles/Table';
import { TablePagination } from '../../components/shared/TablePagination';
import CardHeader from '../../components/shared/CardHeader';
import { Dropdown, DropdownType } from '../../components/shared/Dropdown';
import { Checkbox } from '../../components/shared/CheckBox';
import { UserTimeRegistrationRecordDetails } from '../users/UserTimeRegistrationRecordDetails';
import { SMBold } from '../../typography/Typography';

export type AllEmployeeTableProps<T = UserDetailed> = {
    usersDetailed: UsersDetailedResponse<T>;
    checked: string[];
    allChecked: boolean;
    setChecked: (checked: string[]) => void;
    onRowClick: (userId: string) => void;
    onCheckAllClick?: () => () => void;
    onEditClick?: (userId: string) => () => void;
    onUpdateBankAccountDetailsClick?: (userId: string) => () => void;
    onSendActivationMailClicked: (userId: string, name: string) => void;
    handleBulkEnable?: (checked: string[]) => void;
    handleBulkDisable?: (checked: string[]) => void;
    handleDeleteUser?: (userId: string) => void;
    total?: number;
    handleChangePage?: (
        event: React.MouseEvent<HTMLButtonElement> | null,
        page: number
    ) => void;
    handleChangeRowsPerPage?: (rowsPerPage: number) => void;
    rowsPerPage: number;
    onStatusClick: (
        userId: string,
        companyId: string,
        isActive: boolean
    ) => void;
};

type SortingType = 'asc' | 'desc' | undefined;

export const EmployeesTable = ({
    usersDetailed,
    setChecked,
    checked,
    onRowClick,
    onCheckAllClick,
    total,
    handleChangeRowsPerPage,
    rowsPerPage,
    onStatusClick
}: AllEmployeeTableProps) => {
    const [checkedMap, setCheckedMap] = useState<{ [key: string]: boolean }>(
        {}
    );

    useEffect(() => {
        let map = {} as { [key: string]: boolean };

        checked.forEach((userId) => {
            map[userId] = true;
        });
        setCheckedMap(map);
    }, [checked]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [rowUser, setRowUser] = useState<PaymentsUser>();

    const theme = useTheme();

    const getStatusView = (
        id: string,
        isActive: boolean,
        isDisabled: boolean
    ) => {
        let text = 'Imported';
        let color = 'muted';

        if (isActive && !isDisabled) {
            text = 'Enabled';
            color = 'success';
        }
        if (!isActive && isDisabled) {
            text = 'Disabled';
            color = 'error';
        }

        return <Chip label={text} color={color as any} />;
    };

    const { t } = useTranslation();

    const getStatusButton = (rowUser?: PaymentsUser) => {
        const color = rowUser?.isActive ? 'success' : 'error';
        const text = rowUser?.isActive ? 'Disable' : 'Enable';

        return (
            <div
                style={{ width: '100%' }}
                onClick={() => {
                    onStatusClick(
                        rowUser?.userId!,
                        rowUser?.companyId!,
                        !rowUser?.isActive
                    );
                    handleMenuClose();
                }}
                color={color}
            >
                {text}
            </div>
        );
    };

    const userList = useMemo(() => {
        return usersDetailed.users as any[]; //TODO: make global type
    }, [usersDetailed.users]);

    const handleMenuClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        rowUser: PaymentsUser
    ) => {
        setAnchorEl(event.currentTarget);
        setRowUser(rowUser);
        setChecked([rowUser?.userId]);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setChecked([]);
    };

    const actions = <Stack direction="row"></Stack>;

    const navigation = useNavigate();
    const navigateToUserDetails = (rowUser: PaymentsUser) => {
        navigation(
            `/users/${rowUser.userId}?changeCompanyId=${rowUser?.companyId}`
        );
    };

    return (
        <Card>
            <CardHeader
                label={t('list')}
                chip={
                    usersDetailed.total ? (
                        <Chip
                            icon={<></>}
                            label={
                                <SMBold color={chipColors.success.text}>
                                    {` ${rowsPerPage} of ${usersDetailed.filteredTotal}`}
                                </SMBold>
                            }
                            color={'success'}
                        />
                    ) : (
                        <></>
                    )
                }
                actions={actions}
            />
            <TableContainer style={{ minWidth: '1424px' }}>
                <Table sx={{}} aria-label="simple table">
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCellWithCheckbox>
                                {t('name')}
                            </TableHeaderCellWithCheckbox>
                            <TableHeaderCellWithCheckbox>
                                {t('email')}
                            </TableHeaderCellWithCheckbox>
                            <TableHeaderCellWithCheckbox>
                                {t('mobile')}
                            </TableHeaderCellWithCheckbox>
                            <TableHeaderCellWithCheckbox>
                                {t('company')}
                            </TableHeaderCellWithCheckbox>
                            <TableHeaderCellWithCheckbox>
                                {t('salary_type')}
                            </TableHeaderCellWithCheckbox>

                            <TableHeaderCellWithCheckbox>
                                {t('status')}
                            </TableHeaderCellWithCheckbox>

                            <TableHeaderCellWithCheckbox>
                                {t('time_registration_id')}
                            </TableHeaderCellWithCheckbox>

                            <TableCell></TableCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {userList?.map((rowUser) => {
                            return (
                                <TableRow
                                    style={{
                                        backgroundColor: checkedMap['' as any]
                                            ? theme.palette.selectedRowColor
                                            : 'inherit'
                                    }}
                                    key={rowUser.userId + rowUser.companyId}
                                    onClick={() => {
                                        //debugger;
                                        onRowClick(
                                            rowUser.userId + rowUser.companyId
                                        );
                                    }}
                                >
                                    <>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            onClick={() =>
                                                navigateToUserDetails(rowUser)
                                            }
                                            style={{
                                                cursor: 'pointer',
                                                textDecoration: 'underline'
                                            }}
                                        >
                                            {rowUser.firstName}{' '}
                                            {rowUser.lastName}
                                        </TableCell>
                                        <TableCell>{rowUser.email}</TableCell>
                                        <TableCell>
                                            {rowUser.phoneNumber}
                                        </TableCell>

                                        <TableCell>
                                            {rowUser?.companyName}
                                        </TableCell>

                                        <TableCell>
                                            {rowUser?.employmentType}
                                        </TableCell>

                                        <TableCell>
                                            {getStatusView(
                                                rowUser.userId,
                                                rowUser.isActive,
                                                rowUser.isDisabled
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                <UserTimeRegistrationRecordDetails
                                                    timeRegistrationProviderId={
                                                        rowUser.timeRegistrationProviderId
                                                    }
                                                />
                                            }
                                        </TableCell>
                                    </>

                                    <TableCellNoPadding>
                                        <Button
                                            color="secondary"
                                            aria-controls={
                                                open ? 'basic-menu' : undefined
                                            }
                                            aria-haspopup="true"
                                            aria-expanded={
                                                open ? 'true' : undefined
                                            }
                                            onClick={(event) => {
                                                handleMenuClick(event, rowUser);
                                                onRowClick(rowUser.userId);
                                                //renderCheckBox(rowUser);
                                            }}
                                        >
                                            <MoreVertIcon />
                                        </Button>
                                    </TableCellNoPadding>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>

                {userList.length == 0 && (
                    <Typography
                        style={{
                            padding: '20px'
                        }}
                        textAlign={'center'}
                    >
                        To begin your search, please enter at least 3 characters
                        in the search bar and press Enter.
                    </Typography>
                )}

                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button'
                    }}
                    sx={{ boxShadow: 'none' }}
                >
                    <MenuItem>
                        <>{getStatusButton(rowUser)}</>
                    </MenuItem>

                    <Link
                        style={{ textDecoration: 'none', color: 'black' }}
                        to={{
                            pathname: `/users/${rowUser?.userId}`,
                            search: `changeCompanyId=${rowUser?.companyId}`
                        }}
                    >
                        <MenuItem onClick={() => {}} sx={{ boxShadow: 'none' }}>
                            {t('see_earnings')}
                        </MenuItem>
                    </Link>
                </Menu>
            </TableContainer>
            <TablePagination
                count={total!}
                page={0}
                onPageChange={() => {}}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage as any} //TODO: Fix this
                rowsPerPageOptions={[
                    {
                        label: '16',
                        value: 16
                    },
                    {
                        label: '24',
                        value: 24
                    },
                    {
                        label: '32',
                        value: 32
                    },
                    {
                        label: 'All',
                        value: -1
                    }
                ]}
            />
        </Card>
    );
};
