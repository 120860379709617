import { Alert, Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ContentWrapper } from '../../components/ContentWrapper';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { PaymentsResponse } from '../../models/payment';
import { TransactionStatus } from '../../models/transaction';
import { useErrorHandling } from '../../utils/errorHandling';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export const AiiaAuthorizeCallbackPage = () => {
    const [error, setError] = useState<string>();
    const { setAlert } = useNotificationsSnackbar();

    const { setLoading } = useLoader();
    const { backendErrorHandler } = useErrorHandling();

    const useQuery = () => {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();
    let navigate = useNavigate();

    useEffect(() => {
        const authorizationId = query.get('authorizationId') ?? '';
        if (!authorizationId) {
            setError(
                'Something went wrong - could not determine the authorization id.'
            );
        } else {
            setLoading(true);
            PaymentsAPIServiceV2.getInstance()
                .aiiaGetAuthorizationPayments(authorizationId)
                .then((response: any) => {
                    const payments = response.data as PaymentsResponse;
                    if (
                        payments.every(
                            (_) => _.status === TransactionStatus.Succeeded
                        )
                    ) {
                        setAlert(
                            'AiiA payment authorized successfully',
                            'success'
                        );
                        return navigate('/settings');
                    } else if (
                        payments.some(
                            (_) => _.status === TransactionStatus.Failed
                        )
                    ) {
                        setError(
                            'Some of the payments in the batch could not be completed. Please try again, or, if this keeps happening, contact support'
                        );
                    } else if (
                        payments.some(
                            (_) => _.status === TransactionStatus.AwaitingBank
                        )
                    ) {
                        setError(
                            'Some of the payments are still processing. Please check the transactions page later for status updates.'
                        );
                    }
                })
                .catch(
                    backendErrorHandler(
                        'Could not load authorization payments list: '
                    )
                )
                .finally(() => setLoading(false));
        }
    }, [query, navigate, setAlert, setLoading, backendErrorHandler]);

    return (
        <>
            {error && (
                <ContentWrapper>
                    <Box textAlign="center">
                        <Alert severity="error">{error}</Alert>
                        <Button
                            sx={{ marginTop: '1rem' }}
                            variant="contained"
                            component={Link}
                            to="/history"
                        >
                            View transactions history
                        </Button>
                    </Box>
                </ContentWrapper>
            )}
        </>
    );
};
