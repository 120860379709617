import { SvgIcon, SvgIconProps } from '@mui/material';

export const DashboardIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 16">
            <rect width="16" height="16" rx="3" />
            <path
                d="M10.5 11.3337V7.16699M8 11.3337V4.66699M5.5 11.3337V8.83366"
                stroke="#FCFCFD"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </SvgIcon>
    );
};
