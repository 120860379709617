import { useState, useEffect, useCallback } from 'react';
import { Alert } from '@mui/material';
import { debounce } from 'lodash';
import { UserTableType, UsersTable } from '../UsersTable';
import { AddUserForm } from '../add/AddUserForm';
import { UpdateUserForm } from '../update/UpdateUserForm';
import { UpdateBankAccountForm } from '../bankAccountDetails/UpdateBankAccountForm';
import {
    ZenegyEmployeeTypeEnum,
    GetZenegyUsersDetailedReqest as GetUsersDetailedRequest,
    ZenegySalaryModeEnum,
    ZenegySalaryTypeEnum,
    UserDetailedResponse,
    ZenegyUserDetailed,
    CompanyUsersResponse,
    PaymentsUser
} from '../../../models/payment';
import { ZenegyUsersFilters } from './ZenegyUsersFilters';
import { useErrorHandling } from '../../../utils/errorHandling';
import { useEmployeesOverview } from '../EmployeesOverview';
import {
    WithConfirmation,
    WithConfirmationWrappedComponentProps
} from '../../../utils/withConfirmation';
import { useEmployeeStatusConfirmation } from '../useEmployeeStatusConfirmation';
import { useParams } from 'react-router-dom';
import { useAppDispatch, usePayments } from '../../../lib/hooks';
import { FETCH_STATE } from '../../../lib/slices/types';
import {
    getCompaniesUsers,
    getZenergyUsersDetailed
} from '../../../lib/slices/paymentsSlice';
import { checkValueAndCall } from '../../../lib/functionUtil';
import { getFilterToParams } from '../UserActiveFilter';
import { setLoader } from '../../../lib/slices/globalLoaderSlice';
import { saveRowsPerPageForUserTable } from '../../../lib/localStorage';
import { EditUsersDialog } from '../../company/EditUsersDialog';
import { UserType } from '../../../models/company';
import { usePagination } from '../../../components/shared/TablePagination';

interface ZenegyEmployeesProps extends WithConfirmationWrappedComponentProps {
    userType: UserType;
}

export const ZenegyEmployeesOverview = WithConfirmation(
    (props: ZenegyEmployeesProps) => {
        const [usersDetailed, setUsersDetailed] =
            useState<UserDetailedResponse<ZenegyUserDetailed>>();

        const [companyUsersDetailed, setCompanyUsersDetailed] =
            useState<CompanyUsersResponse>();

        const [error, setError] = useState<string>();
        const [updateUserId, setUpdateUserId] = useState<string>();
        const [updateUserDialogOpen, setUpdateUserDialogOpen] = useState(false);
        const [accessRowUser, setAccessRowUser] = useState<PaymentsUser>();
        const [editUserAccessOpen, setEditUserAccessOpen] = useState(false);
        const [updateBankAccountUserId, setUpdateBankAccountUserId] =
            useState<string>();
        const [
            updateBankAccountUserDialogOpen,
            setUpdateBankAccountUserDialogOpen
        ] = useState(false);

        const [checked, setChecked] = useState<string[]>([]);

        const [selectedEmployeeTypes, setSelectedEmployeeTypes] = useState<
            ZenegyEmployeeTypeEnum[]
        >([]);

        const [selectedPandayEmployeeIds, setPandayEmployeeTypesIds] = useState<
            string[]
        >([]);
        const [selectedSalaryModes, setSelectedSalaryModes] = useState<
            ZenegySalaryModeEnum[]
        >([]);
        const [selectedSalaryTypes, setSelectedSalaryTypes] = useState<
            ZenegySalaryTypeEnum[]
        >([]);
        const [selectedDepartments, setSelectedDepartments] = useState<
            string[]
        >([]);

        const [selectedActiveType, setSelectedActiveType] =
            useState<string>('');

        const [selectedInstapaidSalaryTypes, setSelectedInstapaidSalaryTypes] =
            useState<string[]>([]);

        const [username, setUsername] = useState<string>('');

        const [rowsPerPage, setRowsPerPage] = useState(16);
        const { backendErrorHandler } = useErrorHandling();
        const {
            onRowClick,
            onCheckAllClick,
            allUsersChecked,
            onCheckAllCompanyUsersClick,
            allCompanyUsersChecked
        } = useEmployeesOverview();

        const { page, setPage, resetPage } = usePagination();

        useEffect(() => {
            if (username) {
                resetPage();
            }
        }, [username]);

        const dispatch = useAppDispatch();

        const { zenergyUsersDetailed, companyUsers } = usePayments();

        useEffect(() => {
            dispatch(
                setLoader(zenergyUsersDetailed.status === FETCH_STATE.LOADING)
            );
            if (zenergyUsersDetailed.status == FETCH_STATE.FAILED) {
                backendErrorHandler('Error fetching users: ', () => {
                    setError('Users could not be fetched');
                });
            }
            if (zenergyUsersDetailed.status == FETCH_STATE.SUCCEEDED) {
                setUsersDetailed(zenergyUsersDetailed.usersResponse);
            }
        }, [zenergyUsersDetailed]);

        const reloadUsersPaymentsService = useCallback(() => {
            let activeStatus = getFilterToParams(selectedActiveType);

            const request: GetUsersDetailedRequest = {
                salaryModes: selectedSalaryModes,
                departmentIds: selectedDepartments ?? [],
                employeeTypes: selectedEmployeeTypes,
                salaryTypes: selectedSalaryTypes,
                plandayEmployeeTypeIds: selectedPandayEmployeeIds,
                instapaidSalaryType: selectedInstapaidSalaryTypes,
                ...activeStatus,
                username,
                limit: rowsPerPage > 0 ? rowsPerPage : 0, // can be set to -1 to show all
                offset: page * rowsPerPage
            };

            dispatch(getZenergyUsersDetailed(request));
        }, [
            selectedSalaryModes,
            selectedDepartments,
            selectedEmployeeTypes,
            selectedSalaryTypes,
            selectedPandayEmployeeIds,
            selectedInstapaidSalaryTypes,
            selectedActiveType,
            username,
            page,
            rowsPerPage
        ]);

        let debouncedReload = debounce(() => {
            reloadUsersPaymentsService();
        }, 100);

        const reloadUsers = useCallback(() => {
            debouncedReload?.cancel();
            debouncedReload();
            setChecked([]);
        }, [reloadUsersPaymentsService]);

        // useEffect(() => {
        //     let rowsPerPage = getRowsPerPageForUserTable();
        //     setRowsPerPage(rowsPerPage);
        // }, []);

        useEffect(() => {
            reloadUsers();
        }, [reloadUsers]);

        useEffect(() => {
            dispatch(setLoader(companyUsers.status === FETCH_STATE.LOADING));
            if (companyUsers.status == FETCH_STATE.FAILED) {
                backendErrorHandler('Error fetching users: ', () => {
                    setError('Company Admins could not be fetched');
                });
            }
            if (companyUsers.status == FETCH_STATE.SUCCEEDED) {
                setCompanyUsersDetailed(companyUsers?.companySettingsUsers);
            }
        }, [companyUsers]);

        const reloadCompanyUsersService = useCallback(() => {
            dispatch(
                getCompaniesUsers({
                    limit: rowsPerPage > 0 ? rowsPerPage : 0,
                    offset: page * rowsPerPage
                })
            );
        }, [page, rowsPerPage]);

        let debouncedCompanyUsersReload = debounce(() => {
            reloadCompanyUsersService();
        }, 100);

        const reloadCompanyUsers = useCallback(() => {
            debouncedCompanyUsersReload?.cancel();
            debouncedCompanyUsersReload();
            setChecked([]);
        }, [reloadCompanyUsersService]);

        useEffect(() => {
            reloadCompanyUsers();
        }, [reloadCompanyUsers]);

        const onEditClick = (userId: string) => () => {
            setUpdateUserId(userId);
            setUpdateUserDialogOpen(true);
        };

        const onEditUserAccessClick = (data: any) => () => {
            setAccessRowUser(data);
            setEditUserAccessOpen(true);
        };

        const onUpdateBankAccountDetailsClick = (userId: string) => () => {
            setUpdateBankAccountUserId(userId);
            setUpdateBankAccountUserDialogOpen(true);
        };

        const handleSalaryModesChange = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedSalaryModes);
        };

        const handleSalaryTypesChange = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedSalaryTypes);
        };

        const handleEmployeeTypesChange = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedEmployeeTypes);
        };

        const handlePlandayEmployeeTypesChange = (value: string | string[]) => {
            checkValueAndCall(value, setPandayEmployeeTypesIds);
        };

        const handleDepartmentChange = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedDepartments);
        };

        const handleChangePage = (
            event: React.MouseEvent<HTMLButtonElement> | null,
            newPage: number
        ) => {
            setPage(newPage);
            saveRowsPerPageForUserTable(rowsPerPage);
        };

        const handleChangeRowsPerPage = (rowsPerPage: number) => {
            setRowsPerPage(rowsPerPage);
            saveRowsPerPageForUserTable(rowsPerPage);
            setPage(0);
        };

        const {
            handleBulkEnable,
            handleBulkDisable,
            handleStatusChange,
            handleRoleToggleChange,
            sendActivationEmail,
            handleDeleteUser
        } = useEmployeeStatusConfirmation(props.showConfirmDialog, reloadUsers);

        const handleSentActivationEmail = (userId: string, name: string) => {
            sendActivationEmail(userId, name, setError);
        };

        const handleSetSelectedActiveType = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedActiveType);
        };

        const handleInstapaidSalaryTypeChange = (value: string | string[]) => {
            checkValueAndCall(value, setSelectedInstapaidSalaryTypes);
        };

        return (
            <>
                {error && <Alert severity="error">{error}</Alert>}
                {usersDetailed && (
                    <div style={{ position: 'relative' }}>
                        <div
                            style={{
                                position: 'absolute',
                                right: 5,
                                top: -80
                            }}
                        >
                            {props?.userType === 'Normal' && (
                                <AddUserForm
                                    onUserListChange={reloadUsers}
                                    name="Add user"
                                />
                            )}
                        </div>
                        {props?.userType === 'Normal' && (
                            <ZenegyUsersFilters
                                onPlanDayEmployeeTypeChange={
                                    handlePlandayEmployeeTypesChange
                                }
                                selectedPlanDayEmployeeTypes={
                                    selectedPandayEmployeeIds
                                }
                                selectedDepartments={selectedDepartments}
                                onDepartmentChange={handleDepartmentChange}
                                selectedSalaryModes={selectedSalaryModes}
                                onSalaryModeChange={handleSalaryModesChange}
                                selectedEmployeeTypes={selectedEmployeeTypes}
                                onEmployeeTypeChange={handleEmployeeTypesChange}
                                selectedSalaryTypes={selectedSalaryTypes}
                                onSalaryTypeChange={handleSalaryTypesChange}
                                username={username}
                                onUsernameChange={setUsername}
                                selectedActiveType={selectedActiveType}
                                onActiveTypeChange={handleSetSelectedActiveType}
                                selectedInstaPaidSalaryTypes={
                                    selectedInstapaidSalaryTypes
                                }
                                onInstaPaidSalaryTypeChange={
                                    handleInstapaidSalaryTypeChange
                                }
                            />
                        )}

                        <UsersTable
                            displayAdmin={
                                props?.userType === 'Normal' ? false : true
                            }
                            addUserForm={
                                props?.userType === 'Admin' && (
                                    <AddUserForm
                                        onUserListChange={reloadCompanyUsers}
                                        name="Create user"
                                    />
                                )
                            }
                            total={
                                props?.userType === 'Normal'
                                    ? zenergyUsersDetailed?.usersResponse
                                          ?.filteredTotal
                                    : companyUsersDetailed?.total
                            }
                            handleDeleteUser={(userId: string) =>
                                handleDeleteUser(
                                    userId,
                                    props?.userType === 'Normal'
                                        ? reloadUsers
                                        : reloadCompanyUsers
                                )
                            }
                            checked={checked}
                            setChecked={setChecked}
                            allChecked={
                                props?.userType === 'Normal'
                                    ? allUsersChecked(usersDetailed, checked)
                                    : allCompanyUsersChecked(
                                          companyUsersDetailed,
                                          checked
                                      )
                            }
                            onRowClick={(userId: string) =>
                                onRowClick(userId, checked, setChecked)
                            }
                            onCheckAllClick={() =>
                                props?.userType === 'Normal'
                                    ? onCheckAllClick(
                                          usersDetailed,
                                          checked,
                                          setChecked
                                      )
                                    : onCheckAllCompanyUsersClick(
                                          companyUsersDetailed,
                                          checked,
                                          setChecked
                                      )
                            }
                            onStatusClick={(
                                userId: string,
                                isActive: boolean
                            ) => {
                                handleStatusChange(
                                    userId,
                                    isActive,
                                    props?.userType === 'Normal'
                                        ? reloadUsers
                                        : reloadCompanyUsers
                                );
                            }}
                            onRoleToggleChange={(
                                userId: string,
                                isAdmin: boolean
                            ) =>
                                handleRoleToggleChange(
                                    userId,
                                    isAdmin,
                                    props?.userType === 'Normal'
                                        ? reloadUsers
                                        : reloadCompanyUsers
                                )
                            }
                            onEditClick={
                                props?.userType === 'Normal'
                                    ? onEditClick
                                    : onEditUserAccessClick
                            }
                            onUpdateBankAccountDetailsClick={
                                onUpdateBankAccountDetailsClick
                            }
                            onSendActivationMailClicked={
                                handleSentActivationEmail
                            }
                            usersDetailed={
                                props?.userType === 'Normal'
                                    ? usersDetailed
                                    : companyUsersDetailed
                            }
                            handleBulkEnable={handleBulkEnable}
                            handleBulkDisable={handleBulkDisable}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            rowsPerPage={rowsPerPage}
                            userTableType={UserTableType.Zenergy}
                        />
                    </div>
                )}

                <UpdateUserForm
                    onUserListChange={reloadUsers}
                    userId={updateUserId}
                    open={updateUserDialogOpen}
                    setOpen={(open: boolean) => setUpdateUserDialogOpen(open)}
                />
                <UpdateBankAccountForm
                    onUserListChange={reloadUsers}
                    userId={updateBankAccountUserId}
                    open={updateBankAccountUserDialogOpen}
                    setOpen={(open: boolean) =>
                        setUpdateBankAccountUserDialogOpen(open)
                    }
                />

                <EditUsersDialog
                    dialogOpen={editUserAccessOpen}
                    onClose={() => setEditUserAccessOpen(false)}
                    rowData={editUserAccessOpen && accessRowUser}
                    refreshList={reloadCompanyUsers}
                />
            </>
        );
    }
);
