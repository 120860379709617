import React, { useEffect, useState } from 'react';
import {
    Modal as ModalContainer,
    Box,
    FormGroup,
    Grid,
    FormControlLabel,
    Typography
} from '@mui/material';
import styled from '@emotion/styled';
import {
    SecondaryButtonRegular,
    PrimaryButton
} from '../../components/shared/Button';
import { useTranslation } from 'react-i18next';
import { FONT_WEIGHT, TEXT_SIZE, Title } from '../../typography/Typography';
import { useAppDispatch, usePayments } from '../../lib/hooks';
import { Checkbox } from '../../components/shared/CheckBox';
import { RadioButton } from '../../components/shared/RadioButton';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { UserRolesData } from '../../models/company';
import { BlueCircle } from '../../icons/BlueCircle';
import UsersAPIServiceV2 from '../../services/UserServiceV2';
import { useErrorHandling } from '../../utils/errorHandling';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { setLoader } from '../../lib/slices/globalLoaderSlice';
import { FETCH_STATE } from '../../lib/slices/types';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';
import { useTheme } from '@emotion/react';

const ConfirmationBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1200px;
    max-height: 611px;
    min-width: 400px;
    background-color: #ffffff;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    outline: none;
    display: inline-table;
    justify-content: center;
`;

const AccessRightGrid = styled(Grid)`
    border: 1px solid #d0d5dd;
    padding: 0;
    max-height: 100%;
    max-width: 100%;
    border-radius: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const ExtraRow = styled(Typography)`
    align-self: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

interface EditUsersProps {
    onClose?: () => void;
    dialogOpen: boolean;
    rowData: any;
    refreshList: any;
}

type CheckboxItem<T> = {
    id: string;
    name: T;
    isUsersSyncEnabled: boolean;
};

interface StateChange {
    id: string;
    newState: boolean;
}

export const EditUsersDialog = (props: EditUsersProps) => {
    const dispatch = useAppDispatch();
    const { setAlert } = useNotificationsSnackbar();
    const { t } = useTranslation();
    const [settingsDeselected, setSettingsDeselected] = useState(false);
    const [checked, setChecked] = useState<string[]>([]);
    const { backendErrorHandler } = useErrorHandling();
    const { setLoading } = useLoader();
    const { companiesByUserId, adminCompanies } = usePayments();
    const theme = useTheme();

    const initializeUserRoles = () => {
        const roles = Object.keys(UserRolesData)
            .filter((key) => !isNaN(Number(UserRolesData[key as any])))
            .map((key, index) => ({
                id: index.toString(),
                name: key,
                isUsersSyncEnabled: props.rowData.role === key
            }));

        if (props.rowData.role === 'Admin') {
            return roles.filter((role) => role.name === 'Admin');
        }

        return roles;
    };

    const [userRoles, setUserRoles] = useState<CheckboxItem<string>[]>(
        initializeUserRoles()
    );

    useEffect(() => {
        setUserRoles(initializeUserRoles());
    }, [props.rowData.role]);

    const [companiesByUser, setCompaniesByUser] = useState<
        CheckboxItem<string>[]
    >([]);

    const [checkboxStateChanges, setCheckboxStateChanges] = useState<
        StateChange[]
    >([]);

    useEffect(() => {
        dispatch(setLoader(companiesByUserId.status === FETCH_STATE.LOADING));
    }, [companiesByUserId.status]);

    useEffect(() => {
        dispatch(setLoader(adminCompanies.status === FETCH_STATE.LOADING));
    }, [adminCompanies.status]);

    useEffect(() => {
        if (props.rowData.userId) {
            setLoading(true);
            PaymentsAPIServiceV2.getInstance()
                .getCompanyByUserId(props.rowData.userId)
                .then((response: any) => {
                    setCompaniesByUser(
                        response?.data?.companies?.map((company: any) => ({
                            id: company.companyId,
                            name: company.name,
                            isUsersSyncEnabled: true,
                            role: company?.role
                        }))
                    );
                })
                .finally(() => setLoading(false));
        }
    }, [props.rowData.userId, setLoading]);

    const [adminCompaniesList, setAdminCompaniesList] = useState<
        CheckboxItem<string>[]
    >([]);

    useEffect(() => {
        setLoading(true);
        const enabledCompanyIds = new Set(
            companiesByUser
                ?.filter((company: any) => company?.role === 'Admin')
                .map((company) => company?.id)
        );

        PaymentsAPIServiceV2.getInstance()
            .getAdminCompanies()
            .then((companies: any) => {
                setAdminCompaniesList(
                    companies?.data?.companies
                        ?.map((company: any) => ({
                            id: company.companyId,
                            name: company.name,
                            isUsersSyncEnabled: enabledCompanyIds.has(
                                company.companyId
                            )
                        }))
                        .sort((a: any, b: any) => a.name.localeCompare(b.name))
                );
            })
            .finally(() => setLoading(false));
    }, [companiesByUser, setLoading]);

    const ModalHeading = ({ label }: { label: string }) => {
        return (
            <Typography
                fontWeight={FONT_WEIGHT.BOLD}
                fontSize={TEXT_SIZE.MEDIUM_SMALL}
                m={1}
            >
                {label}
            </Typography>
        );
    };

    const renderCheckboxes = <T extends string>(
        title: string,
        items: CheckboxItem<T>[],
        setter: (items: CheckboxItem<T>[]) => void
    ) => {
        return (
            <AccessRightGrid item xs={12}>
                <Title
                    fontWeight={600}
                    p={'10px 12px'}
                    textAlign={'start'}
                    borderBottom={'1px solid #EAECF0'}
                >
                    {title}
                </Title>
                {items && (
                    <FormGroup
                        sx={{
                            overflowY: 'auto',
                            maxHeight: '230px',
                            display: 'block',
                            textAlign: 'start'
                        }}
                    >
                        {items.map((item: any, index) => (
                            <FormControlLabel
                                sx={{
                                    borderTop: '1px solid #D0D5DD',
                                    margin: 0,
                                    display: 'block',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis'
                                }}
                                onClick={(e) =>
                                    handleCheckboxClick(e, item, items, setter)
                                }
                                key={index}
                                control={
                                    <Checkbox
                                        size="small"
                                        sx={{
                                            padding: '0.3rem 0.5rem',
                                            color: '#ECFDF3 !important',
                                            borderColor: '#089855 !important'
                                        }}
                                        checked={item.isUsersSyncEnabled}
                                    />
                                }
                                label={item.name}
                            />
                        ))}
                    </FormGroup>
                )}
            </AccessRightGrid>
        );
    };

    const renderRadioButtons = <T extends string>(
        title: string,
        items: CheckboxItem<T>[],
        setter: (items: CheckboxItem<T>[]) => void
    ) => {
        return (
            <Grid
                item
                xs={12}
                border={'1px solid #D0D5DD'}
                padding={0}
                height={'100%'}
                borderRadius={'2px'}
                maxHeight={'100%'}
            >
                <Title
                    fontWeight={600}
                    p={'10px 12px'}
                    textAlign={'start'}
                    borderBottom={'1px solid #EAECF0'}
                >
                    {title}
                </Title>
                {items && (
                    <FormGroup
                        sx={{
                            overflow: 'hidden'
                        }}
                    >
                        {items.map((item: any, index) => (
                            <FormControlLabel
                                sx={{
                                    borderTop: '1px solid #D0D5DD',
                                    margin: 0
                                }}
                                onClick={(e) =>
                                    handleRadiobuttonClick(
                                        e,
                                        item,
                                        items,
                                        setter
                                    )
                                }
                                key={index}
                                control={
                                    <RadioButton
                                        size="small"
                                        checked={item.isUsersSyncEnabled}
                                        customRadio
                                        checkedColor={
                                            theme.palette.text.success
                                        }
                                    />
                                }
                                label={item.name}
                            />
                        ))}
                    </FormGroup>
                )}
            </Grid>
        );
    };

    const handleCheckboxClick = <T extends string>(
        e: React.MouseEvent<HTMLLabelElement, MouseEvent>,
        clickedItem: CheckboxItem<T>,
        items: CheckboxItem<T>[] | undefined,
        setter: (items: CheckboxItem<T>[]) => void
    ) => {
        e.preventDefault();
        if (!items) return;

        const currentIndex = items.indexOf(clickedItem);

        const newItems = items.map((item) => ({ ...item }));

        newItems[currentIndex].isUsersSyncEnabled =
            !items[currentIndex].isUsersSyncEnabled;

        if (newItems[currentIndex].isUsersSyncEnabled === false) {
            setSettingsDeselected(true);
        }

        const stateChange: StateChange = {
            id: clickedItem.id,
            newState: newItems[currentIndex].isUsersSyncEnabled
        };

        const existingIndex = checkboxStateChanges.findIndex(
            (change) => change.id === stateChange.id
        );

        if (existingIndex === -1) {
            setCheckboxStateChanges((prevChanges) => [
                ...prevChanges,
                stateChange
            ]);
        } else {
            setCheckboxStateChanges((prevChanges) => {
                prevChanges[existingIndex] = stateChange;
                return prevChanges;
            });
        }

        setter(newItems);
    };

    const handleRadiobuttonClick = <T extends string>(
        e: React.MouseEvent<HTMLLabelElement, MouseEvent>,
        clickedItem: CheckboxItem<T>,
        items: CheckboxItem<T>[] | undefined,
        setter: (items: CheckboxItem<T>[]) => void
    ) => {
        e.preventDefault();
        if (!items) return;

        const newItems = items.map((item) => ({
            ...item,
            isUsersSyncEnabled: item.name === clickedItem.name
        }));

        setter(newItems);
    };

    const getInitials = (name: any) => {
        const hasTokens = name.indexOf(' ') !== -1;
        return (
            name.substring(0, hasTokens ? 1 : 2) +
            (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '')
        );
    };

    const allCompaniesChecked = (companyDetailed: any, checked: string[]) =>
        companyDetailed?.length !== undefined &&
        checked.length === filteredUsersLength(companyDetailed);

    const filteredUsersLength = (companyDetailed: any) => {
        let totalLength = companyDetailed?.length ?? 0;
        return totalLength;
    };

    const onCheckAllClick = (
        companyDetailed: any,
        checked: string[],
        setter: React.Dispatch<React.SetStateAction<string[]>>
    ) => {
        const allChecked = allCompaniesChecked(companyDetailed, checked);

        if (allChecked) {
            setter([]);
            setAdminCompaniesList((prevCompanies) =>
                prevCompanies.map((company) => ({
                    ...company,
                    isUsersSyncEnabled: false
                }))
            );
        } else {
            const companyIds = companyDetailed.map(
                (user: any) => user?.companyId
            );
            setter(companyIds);
            setAdminCompaniesList((prevCompanies) =>
                prevCompanies.map((company) => ({
                    ...company,
                    isUsersSyncEnabled: true
                }))
            );
        }

        setCheckboxStateChanges((prevChanges) => {
            const stateChanges: StateChange[] = companyDetailed.map(
                (company: any) => ({
                    id: company.id,
                    newState: !allChecked
                })
            );
            return [...prevChanges, ...stateChanges];
        });
    };

    const handleSubmit = async () => {
        setSettingsDeselected(false);

        const updatedCompanies: any = [];

        checkboxStateChanges.forEach((company) => {
            if (company?.newState) {
                updatedCompanies.push({
                    companyId: company.id,
                    action: 'add'
                });
            } else {
                updatedCompanies.push({
                    companyId: company.id,
                    action: 'delete'
                });
            }
        });

        setLoading(true);
        await UsersAPIServiceV2.getInstance()
            .createUserInMultiCompanies({
                firstName: props?.rowData?.firstName,
                lastName: props?.rowData?.lastName,
                email: props?.rowData?.email,
                phoneNumber: props?.rowData?.phoneNumber,
                isActive: props?.rowData?.isActive,
                role: props?.rowData?.role,
                companies: updatedCompanies
            })
            .then(() => {
                setAlert('Access right updated succeessfully', 'success');
                if (props.onClose) {
                    props.onClose();
                }
                props?.refreshList();
            })
            .catch(backendErrorHandler('Something went wrong'))
            .finally(() => {
                setLoading(false);
                setCheckboxStateChanges([]);
            });
    };

    return (
        <ModalContainer open={props.dialogOpen} onClose={props.onClose}>
            <ConfirmationBox>
                <Box textAlign={'center'} marginTop={'10px'}>
                    <ModalHeading label={t('edit_users')} />
                    <BlueCircle />
                    {props.rowData && (
                        <Typography
                            fontSize={20}
                            fontWeight={'bold'}
                            marginTop={'-45px'}
                            marginBottom={2}
                            color={'#1570EF'}
                        >
                            {getInitials(
                                props.rowData.firstName +
                                    ' ' +
                                    props.rowData?.lastName
                            )}
                        </Typography>
                    )}
                    <Typography
                        color={'#089855'}
                        fontWeight={FONT_WEIGHT.SEMI_BOLD}
                    >
                        {props.rowData.firstName +
                            ' ' +
                            props.rowData?.lastName}
                    </Typography>

                    <Grid
                        container
                        justifyContent={'space-between'}
                        marginTop={'20px'}
                    >
                        <Grid item xs={5.8}>
                            {userRoles &&
                                renderRadioButtons(
                                    t('user_type'),
                                    userRoles,
                                    setUserRoles
                                )}
                        </Grid>
                        <Grid item xs={5.8}>
                            {adminCompaniesList &&
                                renderCheckboxes(
                                    t('give_access_rights'),
                                    adminCompaniesList,
                                    setAdminCompaniesList
                                )}
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        justifyContent={'space-between'}
                        margin={'0px 0px 20px'}
                    >
                        <Grid item xs={5.8}></Grid>
                        <Grid
                            item
                            xs={5.8}
                            display={'flex'}
                            sx={{
                                backgroundColor: '#F2F4F7',
                                cursor: 'pointer'
                            }}
                            onClick={() => {
                                onCheckAllClick(
                                    companiesByUser,
                                    checked,
                                    setChecked
                                );
                            }}
                        >
                            <Checkbox
                                sx={{
                                    margin: '0px 0px 0px 1px',
                                    padding: '0.3rem 0.5rem'
                                }}
                                edge="start"
                                checked={allCompaniesChecked(
                                    companiesByUser,
                                    checked
                                )}
                                size="small"
                                tabIndex={-1}
                                inputProps={{
                                    style: {
                                        borderRadius: '4px'
                                    },
                                    'aria-labelledby':
                                        'checkbox-list-secondary-label-all'
                                }}
                            />
                            <ExtraRow>{t('select_all_companies')}</ExtraRow>
                        </Grid>
                    </Grid>

                    <Typography textAlign={'start'}>
                        {t('edit_users_note')}
                    </Typography>
                    <Grid
                        m={'20px 0'}
                        container
                        justifyContent={'space-around'}
                    >
                        <SecondaryButtonRegular
                            onClick={props.onClose}
                            sx={{ width: '48%' }}
                        >
                            {t('close')}
                        </SecondaryButtonRegular>

                        <PrimaryButton
                            sx={{ width: '48%' }}
                            onClick={handleSubmit}
                        >
                            {t('save_settings')}
                        </PrimaryButton>
                    </Grid>
                </Box>
            </ConfirmationBox>
        </ModalContainer>
    );
};
