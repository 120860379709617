import { MSALHolder } from '../lib/msal';
import {
    ChangeUsersStatusRequest,
    CreateCompanyRequest,
    CreateUserRequest,
    UpdateCompanyAuthSettingsRequest,
    UpdateCompanyRequest,
    UpdateConsentsRequest,
    UpdateUserRequest,
    UpdateUserRoleRequest,
    UploadCompanyLogoRequest,
    CreateUsersInMultiCompaniesRequest
} from '../models/company';
import BaseAPI from './baseServiceV2';
import { AxiosInstance } from 'axios';

class UsersAPIServiceV2 extends BaseAPI {
    client!: AxiosInstance;

    static instance: UsersAPIServiceV2;

    private constructor() {
        super();
        (async () => {
            const USER_API_BASE_URL = (
                await MSALHolder.getInstance()
            ).getSpaConfiguration()?.backendUserServiceUrl;
            this.client = this.getClient(USER_API_BASE_URL!);
        })();

        UsersAPIServiceV2.instance = this;
    }

    sendActivationEmail = async (userId: string) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(
            `/api/v2/users/reinvite/${userId}`,
            {},
            { headers }
        );
    };

    getUser = async (userId: string) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/users/get/${userId}`, { headers });
    };

    getCurrentCompanyWithUsers = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get('/api/v2/companies/current/users', { headers });
    };

    getCurrentCompany = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get('/api/v2/companies/current', { headers });
    };

    createUser = async (user: CreateUserRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/users', user, { headers });
    };

    updateUserRole = async (request: UpdateUserRoleRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.put('/api/v2/user/updateRole', request, { headers });
    };

    getConsents = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get('/api/v2/consents', { headers });
    };

    postponeConsents = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.put('/api/v2/consents/postpone', null, { headers });
    };

    updateCurrentCompany = async (request: UpdateCompanyRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.put('/api/v2/companies/update', request, {
            headers
        });
    };

    getTermsVersions = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get('/api/v2/termsVersions/current', { headers });
    };

    getCurrentUser = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get('/api/v2/users/current', { headers });
    };

    enableUsers = async (
        request: ChangeUsersStatusRequest,
        companyId?: string
    ) => {
        const headers = await this.getAuthHeaders(companyId);

        return this.client.post(`/api/v2/users/enable`, request, { headers });
    };

    disableUsers = async (
        request: ChangeUsersStatusRequest,
        companyId?: string
    ) => {
        const headers = await this.getAuthHeaders(companyId);
        return this.client.post(`/api/v2/users/disable`, request, { headers });
    };

    updateUsersEnabled = async (
        enabled: boolean,
        request: ChangeUsersStatusRequest,
        companyId?: string
    ) => {
        return enabled
            ? this.enableUsers(request, companyId)
            : this.disableUsers(request, companyId);
    };

    updateUser = async (user: UpdateUserRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(`/api/v2/users/update/${user.id}`, user, {
            headers
        });
    };

    getSupportedLanguages = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get('/api/languages', { headers });
    };

    uploadCompanyLogo = async (request: UploadCompanyLogoRequest) => {
        const headers = await this.getAuthHeaders();
        if (headers.headers) {
            // since we are uploading a file, we need to allow the browser to set Content-Type header automatically
            headers.headers['Content-Type'] = undefined;
        }
        return this.client.post(
            '/api/v2/companies/uploadLogo',
            request.formData,
            {
                headers
            }
        );
    };

    updateConsents = async (request: UpdateConsentsRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.put('/api/v2/consents/update', request, { headers });
    };

    updateCurrentAuthSettings = async (
        request: UpdateCompanyAuthSettingsRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.put(
            '/api/v2/companies/authSettings/update',
            request,
            {
                headers
            }
        );
    };

    createCompany = async (company: CreateCompanyRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/companies', company, { headers });
    };

    getCompanies = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get('/api/companies/all', { headers });
    };

    deleteUser = async (userId: string) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(
            `/api/v2/users/delete`,
            {
                userId
            },
            { headers }
        );
    };

    createUserInMultiCompanies = async (
        request: CreateUsersInMultiCompaniesRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/users/companies', request, { headers });
    };

    static getInstance = () => {
        if (this.instance) {
            return this.instance;
        } else {
            this.instance = new UsersAPIServiceV2();
            return this.instance;
        }
    };
}

export default UsersAPIServiceV2;
