import { createSlice } from '@reduxjs/toolkit';

const globalLoaderSlice = createSlice({
    name: 'globalLoader',
    initialState: {
        isLoading: false
    },
    reducers: {
        showLoader: (state) => {
            state.isLoading = true;
        },
        hideLoader: (state) => {
            state.isLoading = false;
        },
        setLoader: (state, action) => {
            state.isLoading = action.payload;
        }
    }
});

export const { showLoader, hideLoader, setLoader } = globalLoaderSlice.actions;
export default globalLoaderSlice.reducer;
