import {
    Box,
    Card,
    Stack,
    Table,
    TableBody,
    TableContainer,
    useTheme
} from '@mui/material';
import {
    TableCell,
    TableFooter,
    TableFooterCell,
    TableHeader,
    TableHeaderCell,
    TableRow
} from '../../components/shared/styles/Table';
import { useTranslation } from 'react-i18next';
import CardHeader from '../../components/shared/CardHeader';
import { TableCard } from '../../components/shared/Card';
import Chip from '../../components/shared/Chip';
import { SMBold, XSMedium } from '../../typography/Typography';
import { ReconciliationTransaction } from '../../lib/slices/paymentsSlice';
import { useFormatting } from '../../utils/formatting';
import { UserContext, useUser } from '../../contexts/user/UserContext';
import { useContext } from 'react';

interface ReconciliationTableProps {
    label: string;
    items: ReconciliationTransaction[];
    totalSum?: string;
    totalAmount?: string;
    chipText?: string;
}

export const ReconciliationTable = ({
    label,
    items,
    chipText,
    totalSum,
    totalAmount
}: ReconciliationTableProps) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const { formatDate, getBalanceLabel } = useFormatting();

    const { company } = useContext(UserContext);

    return (
        <TableCard>
            <CardHeader
                label={
                    <Stack direction={'row'} alignItems={'center'}>
                        {label}{' '}
                        <Box pl={1}>
                            <Chip
                                icon={<></>}
                                label={
                                    <XSMedium
                                        color={theme.palette.success.main}
                                    >
                                        {chipText}
                                    </XSMedium>
                                }
                            ></Chip>
                        </Box>
                    </Stack>
                }
            ></CardHeader>
            <TableContainer>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHeaderCell>{t('date')}</TableHeaderCell>
                            <TableHeaderCell>
                                {t('description')}
                            </TableHeaderCell>
                            <TableHeaderCell align="right">
                                {t('amount')}
                            </TableHeaderCell>
                            <TableHeaderCell align="right">
                                {t('Sum')}
                            </TableHeaderCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {items.map((item, index) => {
                            return (
                                <TableRow>
                                    <TableCell>
                                        {formatDate(new Date(item.date))}
                                    </TableCell>
                                    <TableCell>{item.description}</TableCell>
                                    <TableCell align="right">
                                        {getBalanceLabel(
                                            item.amount,
                                            company.currency
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        {getBalanceLabel(
                                            item.sum,
                                            company.currency
                                        )}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TableFooterCell>
                                <SMBold>{t('total')}</SMBold>
                            </TableFooterCell>
                            <TableFooterCell></TableFooterCell>
                            <TableFooterCell align="right">
                                <SMBold>{totalAmount}</SMBold>
                            </TableFooterCell>
                            <TableFooterCell align="right">
                                <SMBold>{totalSum}</SMBold>
                            </TableFooterCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </TableCard>
    );
};
