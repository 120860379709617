import { Routes, Route } from 'react-router-dom';
import { Home } from '../pages/Home';
import { PaymentsPage } from '../pages/payments/PaymentsPage';
import { UsersPage } from '../pages/users/UsersPage';
import { AiiaConnectCallbackPage } from '../pages/aiia/AiiaConnectCallbackPage';
import { ZenegyCallbackPage } from '../pages/zenegy/ZenegyCallbackPage';
import { AiiaAuthorizeCallbackPage } from '../pages/aiia/AiiaAuthorizeCallbackPage';
import { HistoryPage } from '../pages/history/HistoryPage';
import { BankPage } from '../pages/bank/BankPage';
import { PayrollPage } from '../pages/payroll/PayrollPage';
import { EmailsPage } from '../pages/emails/EmailsPage';
import { TimeRegistrationPage } from '../pages/timeRegistration/TimeRegistrationPage';
import { SubscriptionPage } from '../pages/subscription/SubscriptionPage';
import { CompanyAccountsPage } from '../pages/companyAccounts/CompanyAccountsPage';
import { UserDetailsPage } from '../pages/users/UserDetailsPage';
import React from 'react';
import { ReconciliationPage } from '../pages/reconciliation/ReconciliationPage';
import { CompaniesPageV2 } from '../pages/company/CompaniesPageV2';
import { ReportsDashboard } from '../pages/reports/ReportsDashboard';
import { AllEmployeesPage } from '../pages/employees/AllEmployees';

const CompaniesPage = React.lazy(() =>
    import('../pages/company/CompaniesPage').then(({ CompaniesPage }) => ({
        default: CompaniesPage
    }))
);
const SettingsPage = React.lazy(() =>
    import('../pages/settings/SettingsPage').then(({ SettingsPage }) => ({
        default: SettingsPage
    }))
);

export function Pages() {
    return (
        <Routes>
            <Route path="payments" element={<PaymentsPage />} />
            <Route path="history/page/:pageNumber" element={<HistoryPage />} />
            <Route
                path="settings/*"
                element={
                    <React.Suspense fallback={<></>}>
                        <SettingsPage />
                    </React.Suspense>
                }
            />
            <Route
                path="companies"
                element={
                    <React.Suspense fallback={<></>}>
                        <CompaniesPage />
                    </React.Suspense>
                }
            />
            <Route path="companies/v2" element={<CompaniesPageV2 />} />
            <Route path="companyAccounts" element={<CompanyAccountsPage />} />
            <Route path="emails" element={<EmailsPage />} />
            <Route path="bank" element={<BankPage />} />
            <Route path="payroll" element={<PayrollPage />} />
            <Route path="reconciliation" element={<ReconciliationPage />} />
            <Route
                path="time-registrations"
                element={<TimeRegistrationPage />}
            />
            <Route path="subscription" element={<SubscriptionPage />} />
            <Route path="users-list/page/:pageNumber" element={<UsersPage />} />
            <Route path="users/:userId/*" element={<UserDetailsPage />} />
            <Route
                path="payments/aiiaConnectCallback"
                element={<AiiaConnectCallbackPage />}
            />
            <Route
                path="payments/aiiaAuthorizeCallback"
                element={<AiiaAuthorizeCallbackPage />}
            />
            <Route
                path="zenegy/connectCallback"
                element={<ZenegyCallbackPage />}
            />
            <Route path="/reports" element={<ReportsDashboard />} />
            <Route path="/" element={<Home />} />
            <Route
                path="all-employees/page/:pageNumber"
                element={<AllEmployeesPage />}
            />
        </Routes>
    );
}
