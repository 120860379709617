import { useState } from 'react';
import { useNotificationsSnackbar } from '../../../components/snackbar/NotificationsSnackbarContext';
import { CreateUserRequest } from '../../../models/company';
import { AddUserDialog } from './AddUserDialog';
import { useLoader } from '../../../contexts/loader/LoaderContext';
import { Validator, useErrorHandling } from '../../../utils/errorHandling';
import { PrimaryButton } from '../../../components/shared/Button';
import UsersAPIServiceV2 from '../../../services/UserServiceV2';

type AddUserFormProps = {
    onUserListChange: () => void;
    name: string;
};

export const AddUserForm = (props: AddUserFormProps) => {
    const { setAlert } = useNotificationsSnackbar();
    const { setLoading } = useLoader();
    const { backendErrorHandler } = useErrorHandling();
    const [open, setOpen] = useState(false);
    const [validator, setValidator] = useState(new Validator());

    const onClickOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onSubmit = async (
        request: CreateUserRequest,
        onAdd: () => void
    ): Promise<void> => {
        setLoading(true);
        UsersAPIServiceV2.getInstance()
            .createUser(request)
            .then(() => {
                setAlert('New user added successfully.', 'success');
                setOpen(false);
                setValidator(validator.clearErrors());
                props.onUserListChange();
                onAdd();
            })
            .catch(
                backendErrorHandler(undefined, (e, errorDetails) => {
                    setValidator(new Validator(errorDetails?.errors));
                })
            )
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <PrimaryButton variant="contained" onClick={onClickOpen}>
                {props?.name}
            </PrimaryButton>

            <AddUserDialog
                validator={validator}
                setValidator={setValidator}
                open={open}
                onClose={onClose}
                onSubmit={onSubmit}
            />
        </>
    );
};
