import { useMsal } from '@azure/msal-react';
import { Button, Card, CardContent, Alert } from '@mui/material';
import { Box } from '@mui/system';
import { BackendError } from '../../utils/errorHandling';
import { ExitImpersonationAlert } from '../../components/ExitImpersonationAlert';

export type UserLoadErrorPageProps = {
    backendError: BackendError | undefined;
    statusCode?: number;
};

export const UserLoadErrorPage = (props: UserLoadErrorPageProps) => {
    const { instance } = useMsal();

    const getCardContent = (statusCode?: number) => {
        if (statusCode === 403) {
            return (
                <>
                    <Box>
                        Seems like you don't have admin access to InstaPaid
                        Backoffice.
                    </Box>
                    <Box>
                        If you'd like to register your company with InstaPaid,
                        contact us and we'll help you get started.
                    </Box>
                    <Button
                        sx={{ marginTop: '1rem' }}
                        fullWidth
                        variant="contained"
                        title="Reach out"
                        href={`mailto:kayser@leapfroggers.io`}
                    >
                        Email kayser@leapfroggers.io
                    </Button>
                    <Button
                        sx={{ marginTop: '1rem' }}
                        fullWidth
                        variant="outlined"
                        color="error"
                        title="Sign Out"
                        onClick={() =>
                            instance.logoutRedirect({
                                postLogoutRedirectUri: '/'
                            })
                        }
                    >
                        Log out
                    </Button>
                </>
            );
        }

        return (
            <>
                Could not load user data. Please try again later or contact
                support.
                {props.backendError?.detail && (
                    <Alert
                        sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                        severity="error"
                    >
                        {props.backendError?.detail}
                    </Alert>
                )}
                <ExitImpersonationAlert />
                <Button
                    sx={{ marginTop: '1rem' }}
                    fullWidth
                    variant="contained"
                    title="Sign Out"
                    onClick={() =>
                        instance.logoutRedirect({ postLogoutRedirectUri: '/' })
                    }
                >
                    Re-login
                </Button>
            </>
        );
    };

    return (
        <Box sx={{ maxWidth: '35rem', margin: 'auto' }}>
            <Card sx={{ textAlign: 'center' }}>
                <CardContent>{getCardContent(props.statusCode)}</CardContent>
            </Card>
        </Box>
    );
};
