import { debounce } from '@mui/material';
import {
    createContext,
    useContext,
    useState,
    useCallback,
    useRef
} from 'react';

type LoaderProviderProps = {
    children: JSX.Element;
};

type State = {
    /** @deprecated loading is deprecated. use the one from redux */
    loading: boolean;
};
const initialState: State = {
    loading: false
};

const LoaderContext = createContext({
    ...initialState,
    /** @deprecated isLoading is deprecated. use the one from redux */
    setLoading: (loading: boolean) => {}
});

export const LoaderProvider = (props: LoaderProviderProps) => {
    const [loading, setLoadingInternal] = useState(false);
    let loadingProcessCounter = useRef(0);

    const setLoading = useCallback((loading: boolean) => {
        loadingProcessCounter.current = loading
            ? loadingProcessCounter.current + 1
            : loadingProcessCounter.current - 1;
        if (loadingProcessCounter.current > 0) {
            setLoadingInternal(true);
        } else {
            //Temporary fix to get context working with reduce , TODO: move to redux instead of Provider
            debounce(() => setLoadingInternal(false), 500)();
        }
    }, []);

    return (
        <LoaderContext.Provider value={{ loading, setLoading }}>
            {props.children}
        </LoaderContext.Provider>
    );
};

export const useLoader = () => useContext(LoaderContext);
