import { SvgIcon, SvgIconProps } from '@mui/material';

export const EmailsIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 16">
            <rect width="16" height="16" rx="3" />
            <path
                d="M12.1668 5.50033C12.1668 5.04199 11.7918 4.66699 11.3335 4.66699H4.66683C4.2085 4.66699 3.8335 5.04199 3.8335 5.50033M12.1668 5.50033V10.5003C12.1668 10.9587 11.7918 11.3337 11.3335 11.3337H4.66683C4.2085 11.3337 3.8335 10.9587 3.8335 10.5003V5.50033M12.1668 5.50033L8.00016 8.41699L3.8335 5.50033"
                stroke="#F5FAFF"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </SvgIcon>
    );
};
