import { useMemo, useState } from 'react';
import { UserActiveTypeEnum } from '../../models/payment';
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent
} from '@mui/material';
import { Dropdown } from '../../components/shared/Dropdown';

const useUserActiveFilter = (usersDetailed: any) => {
    const [selectedActiveType, setSelectedActiveType] =
        useState<UserActiveTypeEnum>();

    const { filteredUserDetailsList } = useMemo(() => {
        if (selectedActiveType && selectedActiveType != 'All') {
            let isActive = false;
            let isDisabled = false;

            if (selectedActiveType == 'Enabled') {
                isActive = true;
                isDisabled = false;
            }

            if (selectedActiveType == 'Disabled') {
                isActive = false;
                isDisabled = true;
            }

            let filteredUserDetailsList: any[] = [];

            return {
                filteredUserDetailsList: {
                    users: filteredUserDetailsList,
                    total: filteredUserDetailsList.length
                }
            };
        } else {
            return {
                filteredUserDetailsList: usersDetailed
            };
        }
    }, [usersDetailed, selectedActiveType]);

    const handleActiveTypeChange = (value: string | string[]) => {
        setSelectedActiveType(value as any);
    };

    const renderOptions = () => (
        <Grid item xs={12} sm={2} md={2}>
            <FormControl fullWidth>
                <Dropdown
                    label="Enabled/Disabled"
                    choices={[
                        { label: 'Enabled', value: 'Enabled' },
                        { label: 'Disabled', value: 'Disabled' },
                        { label: 'Imported', value: 'Imported' },
                        { label: 'All', value: 'All' }
                    ]}
                    value={selectedActiveType}
                    onChange={handleActiveTypeChange}
                />
            </FormControl>
        </Grid>
    );

    return {
        selectedActiveType,
        setSelectedActiveType,
        filteredUserDetailsList,
        handleActiveTypeChange,
        renderOptions
    } as const;
};

export default useUserActiveFilter;
