import { MSALHolder } from '../lib/msal';
import {
    AiiaExchangeCodeRequest,
    ApproveTransactionsRequest,
    CreateCompanyAccountTransactionRequest,
    DeleteCompanyRequest,
    FailTransactionsRequest,
    GetAiiaAuthorizeUrlRequest,
    GetAiiaRetryAuthorizeUrlRequest,
    GetIntectUsersDetailedReqest,
    GetZenegyUsersDetailedReqest,
    IntectAuthorizeRequest,
    IntegrationProvider,
    PlandaySetupRequest,
    RejectTransactionsRequest,
    SendDeleteCompanyVerificationEmailRequest,
    SucceedTransactionsRequest,
    UpdateCompanyDanskeBankSettingsRequest,
    UpdateCompanyPaymentsRequest,
    UpdateDepartmentsRequest,
    UpdateEmployeeTypesRequest,
    UpdateIntectDepartmentsRequest,
    UpdateIntectEmploymentTemplateRequest,
    UpdateSalaryModesRequest,
    UpdateSalaryProviderRequest,
    UpdateSalaryTypesRequest,
    UpdateTimeRegistrationProviderRequest,
    UpdateUserBankAccountDetailsRequest,
    ZenegyExchangeCodeRequest,
    CompanyDetailsResponse,
    GetUsersDetailedRequest
} from '../models/payment';
import BaseAPI from './baseServiceV2';
import { AxiosInstance } from 'axios';

export interface UpcomingShiftsRepsonse {
    currentPeriodShifts: CurrentPeriodShifts;
}

export interface CurrentPeriodShifts {
    start: string;
    end: string;
    totalHours: number;
    totalAmount: number;
    shifts: Shift[];
}

export interface AvailableShifts {
    start: string;
    end: string;
    totalHours: number;
    totalAmount: number;
    shifts: AvailableShift[];
}

export interface AvailableShift extends Shift {
    hourlyRateFrom: string;
    amountV2: number;
    hourlyRate: number;
    groupName: string;
}

export interface Shift {
    id: string;
    status: string;
    date: string;
    startTime: Date | null;
    endTime: Date | null;
    break: number;
    duration: number;
    amount: number;
    availableForPayout: boolean;
    isShiftTypeExcluded: boolean;
}

export interface TransactionsResponse {
    transactions: Transaction[];
    total: number;
    filteredTotal: number;
}

export interface Transaction {
    transactionId: string;
    createdAt: string;
    performedAt?: string;
    amount: number;
    currency: string;
    userId: string;
    userName: string;
    userIsDeleted: boolean;
    transactionType: string;
    executantAdminEmail: string;
    executantAdminName: string;
    status: string;
    balance: number;
    approvalType: string;
    paymentSource: string;
}

export interface EmployeeTypeSettingsResponse {
    employeeTypes: EmployeeTypeSettings[];
}

export interface EmployeeTypeSettings {
    employeeTypeId: number;
    name: string;
    description: string;
    autoEnableUsers: boolean;
    employmentType: string;
}

export interface CompanyDisableVerificationEmailRequest {
    companyId: string;
}

export interface CompanyStatusRequest {
    companyId: string;
    isDisabled: boolean;
    verificationCode: string;
}

class PaymentsAPIServiceV2 extends BaseAPI {
    client!: AxiosInstance;

    static instance: PaymentsAPIServiceV2;

    private constructor() {
        super();
        (async () => {
            const PAYMENT_API_BASE_URL = (
                await MSALHolder.getInstance()
            ).getSpaConfiguration()?.backendPaymentsServiceUrl;
            this.client = this.getClient(PAYMENT_API_BASE_URL!);
        })();

        PaymentsAPIServiceV2.instance = this;
    }

    getUpcomingShifts = async (userId: string) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/shifts/upcoming?employeeId=${userId}`, {
            headers
        });
    };

    getAvailableShifts = async (userId: string) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(
            `/api/v2/shifts/available?employeeId=${userId}`,
            {
                headers
            }
        );
    };

    getUser = async (userId: string) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/users/get/${userId}`, { headers });
    };

    getTransactionsHistory = async (
        limit = 10,
        offset = 0,
        startDate?: any,
        endDate?: any
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/transactions/history`, {
            headers,
            params: {
                offset,
                limit,
                startDate,
                endDate
            }
        });
    };

    getUserTransactionHistory = async (
        userId: string,
        limit = 10,
        offset = 0
    ) => {
        const headers = await this.getAuthHeaders();

        return this.client.get(
            `/api/v2/employee/${userId}/transactions/history/`,
            {
                headers,
                params: {
                    offset,
                    limit
                }
            }
        );
    };

    getUsersWithZenegyDetails = async (
        request: GetZenegyUsersDetailedReqest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(
            `/api/v2/companies/current/zenegy-users?${this.buildGetQuery(
                request
            )}`,
            { headers }
        );
    };

    getCurrentImportJob = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/jobs/currentImport`, { headers });
    };

    getProvider = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/provider`, { headers });
    };

    aiiaRefreshPayment = async (transactionId: string) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(
            '/api/v2/aiia/payment?transactionId=' + transactionId,
            { headers }
        );
    };

    getUsersWithIntectDetails = async (
        request: GetIntectUsersDetailedReqest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(
            `/api/v2/companies/current/intect-users?${this.buildGetQuery(
                request
            )}`,
            { headers }
        );
    };

    getDownloadTransactions = async (startDate: any, endDate: any) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/transactions/history/download`, {
            headers,
            params: {
                startDate,
                endDate
            }
        });
    };

    getExportTransactionsPdf = async (startDate: any, endDate: any) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/transactions/history/pdf/generate`, {
            headers,
            params: {
                startDate,
                endDate
            }
        });
    };

    getExportReconciliationPdf = async (month: number, year: number) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/reconciliationReport/pdf/generate`, {
            headers,
            params: {
                month,
                year
            }
        });
    };

    getCurrentCompanyBalance = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/company/balance`, { headers });
    };

    getCurrentCompany = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get('/api/v2/company', { headers });
    };

    getPlandayEmployeeTypes = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get('/api/v2/planday/employeeTypes', { headers });
    };

    getReconciliation = async (month: number, year: number) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/reconciliationReport`, {
            headers,
            params: {
                month,
                year
            }
        });
    };

    createCompanyAccountTransaction = async (
        request: CreateCompanyAccountTransactionRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(
            `/api/v2/companyAccountTransaction/create`,
            request,
            { headers }
        );
    };

    updateUserBankAccountDetails = async (
        request: UpdateUserBankAccountDetailsRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(
            `/api/v2/users/updateBankDetails/${request.userId}`,

            request,
            { headers }
        );
    };

    updateCurrentCompany = async (request: UpdateCompanyPaymentsRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.put('/api/v2/company/update', request, { headers });
    };

    refreshImportParameters = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/import/refreshParameters', null, {
            headers
        });
    };

    plandaySetup = async (request: PlandaySetupRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/planday/setup', request, { headers });
    };

    getPendingTransactions = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get('/api/v2/transactions/pending', { headers });
    };

    zenegyExchangeToken = async (request: ZenegyExchangeCodeRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/zenegy/exchange', request, {
            headers
        });
    };
    cancelJob = async (jobId: string) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(
            `/api/v2/jobs/cancel/${jobId}`,
            {},
            { headers }
        );
    };

    updateSalaryProvider = async (request: UpdateSalaryProviderRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/salaryProvider/update', request, {
            headers
        });
    };

    updateTimeRegistrationProvider = async (
        request: UpdateTimeRegistrationProviderRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(
            '/api/v2/timeRegistrationProvider/update',
            request,
            { headers }
        );
    };

    downloadTransactions = async (startDate: any, endDate: any) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(
            `/api/v2/transactions/history/download?startDate=${startDate}&endDate=${endDate}`,
            { headers }
        );
    };

    getAiiaRegenerateAuthorizeUrl = async (
        request: GetAiiaRetryAuthorizeUrlRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(
            '/api/v2/aiia/regenerateAuthorizeUrl',
            request,
            {
                headers
            }
        );
    };

    getAiiaRetryAuthorizeUrl = async (
        request: GetAiiaRetryAuthorizeUrlRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/aiia/retryAuthorize', request, {
            headers
        });
    };

    getZenegyDepartments = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/departments`, { headers });
    };

    getIntectDepartments = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/intect/departments`, { headers });
    };

    getIntectEmploymentTemplates = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/intect/employmentTemplates`, {
            headers
        });
    };

    getUserBalance = async (userId: string) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/balance?employeeId=${userId}`, {
            headers
        });
    };

    getUserShifts = async (userId: string) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/shifts?employeeId=${userId}`, {
            headers
        });
    };

    getCompanyAccounts = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get('/api/companyAccounts', { headers });
    };

    getIntegrationStatus = async (provider: IntegrationProvider) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/integrationStatus/${provider}`, {
            headers
        });
    };

    sendDeleteCompanyVerificationEmail = async (
        request: SendDeleteCompanyVerificationEmailRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(
            '/api/company/sendDeleteCompanyVerificationEmail',
            request,
            { headers }
        );
    };

    deleteCompany = async (request: DeleteCompanyRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/company/delete', request, { headers });
    };

    getAiiaConnectUrl = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/aiia/connect', null, { headers });
    };

    aiiaExchangeToken = async (request: AiiaExchangeCodeRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/aiia/exchange', request, { headers });
    };

    aiiaGetAuthorizationPayments = async (authorizationId: string) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(
            '/api/aiia/authorization?authorizationId=' + authorizationId,
            { headers }
        );
    };

    aiiaRegenerateAuthorizeUrl = async (
        request: GetAiiaRetryAuthorizeUrlRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/aiia/regenerateAuthorizeUrl', request, {
            headers
        });
    };

    getCompanyDanskeBankSettings = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/company/danskeBankSettings`, {
            headers
        });
    };

    updateCompanyDanskeBankSettings = async (
        request: UpdateCompanyDanskeBankSettingsRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.put(
            `/api/v2/company/updateDanskeBankSettings`,
            request,
            { headers }
        );
    };

    getZenegyConnectUrl = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/zenegy/connect', null, { headers });
    };

    intectAuthorize = async (request: IntectAuthorizeRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/intect/authorize', request, {
            headers
        });
    };

    approveTransactions = async (request: ApproveTransactionsRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/transactions/approve', request, {
            headers
        });
    };

    getAiiaAuthorizeUrl = async (request: GetAiiaAuthorizeUrlRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/aiia/authorize', request, { headers });
    };

    rejectTransactions = async (request: RejectTransactionsRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/transactions/reject', request, {
            headers
        });
    };

    importUsers = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/users/import', null, { headers });
    };

    getJobStatus = async (jobId: string) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/jobs?jobId=${jobId}`, { headers });
    };

    getTimezones = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/timezones`, { headers });
    };

    updateZenegyDepartments = async (request: UpdateDepartmentsRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/departments/update', request, {
            headers
        });
    };

    getZenegyEmployeeTypes = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/employeeTypes`, { headers });
    };

    updateZenegyEmployeeTypes = async (request: UpdateEmployeeTypesRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/employeeTypes/update', request, {
            headers
        });
    };

    getZenegySalaryModes = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/salaryModes`, { headers });
    };

    updateZenegySalaryModes = async (request: UpdateSalaryModesRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/salaryModes/update', request, {
            headers
        });
    };

    getZenegySalaryTypes = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/v2/salaryTypes`, { headers });
    };

    updateZenegySalaryTypes = async (request: UpdateSalaryTypesRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/salaryTypes/update', request, {
            headers
        });
    };

    updateIntectDepartments = async (
        request: UpdateIntectDepartmentsRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/v2/intect/departments/update', request, {
            headers
        });
    };

    updateIntectEmploymentTemplates = async (
        request: UpdateIntectEmploymentTemplateRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(
            '/api/v2/intect/employmentTemplates/update',
            request,
            { headers }
        );
    };

    getUserCompanies = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get('/api/users/current/companies', {
            headers
        });
    };

    getAdminCompanies = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get('/api/v2/companies', {
            headers
        });
    };

    getCompanyDetails = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get('/api/companyDetails', { headers });
    };

    updateCompanyDetails = async (request: CompanyDetailsResponse) => {
        const headers = await this.getAuthHeaders();
        return this.client.put('/api/updateCompanyDetails', request, {
            headers
        });
    };

    getCompanyUsers = async (limit = 10, offset = 0) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/company/settings/users`, {
            headers,
            params: {
                offset,
                limit
            }
        });
    };

    getCompanyByUserId = async (userId: string) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/user/companies/${userId}`, { headers });
    };

    failTransactionInSalarySystem = async (
        request: FailTransactionsRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(`/api/transactions/fail`, request, { headers });
    };

    succeedTransactionInSalarySystem = async (
        request: SucceedTransactionsRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(`/api/transactions/succeed`, request, {
            headers
        });
    };

    getEmployeeTypes = async () => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/employeeTypes`, {
            headers
        });
    };

    postEmployeeTypesAutoEnable = async (data: any) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(`/api/employeeTypes/autoenable`, data, {
            headers
        });
    };

    postEmployeeTypesUpdate = async (data: any) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(`/api/employmentTypes/update`, data, {
            headers
        });
    };

    sendDisableCompanyVerificationEmail = async (
        data: CompanyDisableVerificationEmailRequest
    ) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(
            `/api/company/sendDisableCompanyVerificationEmail`,
            data,
            {
                headers
            }
        );
    };

    updateCompanyStatus = async (data: CompanyStatusRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post(`/api/company/status`, data, {
            headers
        });
    };

    searchByUsername = async (request: GetUsersDetailedRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.get(`/api/users?${this.buildGetQuery(request)}`, {
            headers
        });
    };

    uploadTaxCard = async (file: File) => {
        const headers = await this.getAuthHeaders();
        const formData = new FormData();
        formData.append('aa', file);

        return this.client.post('/api/v2/tax-cards/upload', formData, {
            headers
        });
    };

    static getInstance = () => {
        if (this.instance) {
            return this.instance;
        } else {
            this.instance = new PaymentsAPIServiceV2();
            return this.instance;
        }
    };
}

export default PaymentsAPIServiceV2;
