import { ApprovalType, PaymentSource } from './company';

export enum TransactionStatus {
    PendingApproval = 'PendingApproval',
    ActionInProgress = 'ActionInProgress',
    AwaitingBank = 'AwaitingBank',
    AwaitingDeposit = 'AwaitingDeposit',
    Authorized = 'Authorized',
    Succeeded = 'Succeeded',
    Failed = 'Failed',
    Rejected = 'Rejected',
    Expired = 'Expired'
}

export type TransactionUser = {
    userId: string;
    userName: string;
    email: string;
    balance: number;
    withdrawed: number;
    percentageWithdrawed: number;
};

export type Transaction = {
    transactionId: string;
    createdAt: Date;
    amount: number;
    balance: number;
    currency: string;
    userId: string | null;
    userIsDeleted: boolean;
    transactionType: string;
    userName: string;
    executantAdminEmail: string;
    executantAdminName: string;
    status: TransactionStatus;
    approvalType: ApprovalType;
    paymentSource: PaymentSource;
    performedAt: Date;
};

export type TransactionDetailed = {
    transactionId: string;
    createdAt: Date;
    amount: number;
    currency: string;
    user: TransactionUser;
    status: TransactionStatus;
};

export type TransactionsResponse = {
    transactions: Array<Transaction>;
    total: number;
};

export type TransactionsDetailedResponse = {
    transactions: Array<TransactionDetailed>;
    total: number;
};
