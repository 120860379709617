import styled from '@emotion/styled';
import { useTheme, Radio as MuiRadio, RadioProps } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export const Icon = styled.div`
    width: 16px;
    height: 16px;
    border: 2px solid #eaecf0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const IconCheckedOuter = styled(Icon)`
    //border: 1px solid #1570ef;
    //background: #1570ef;
`;

const CustomIconCheckedOuter = styled(Icon)`
    position: relative;
`;
export interface IconCheckedProps extends RadioProps {
    checkedColor?: string;
    customRadio?: boolean;
}

const IconChecked = ({ checkedColor }: IconCheckedProps) => {
    const theme = useTheme();
    return (
        <IconCheckedOuter
            style={{
                backgroundColor: checkedColor || theme.palette.primary.main,
                borderColor: checkedColor || theme.palette.primary.main
            }}
        >
            <CheckIcon
                style={{
                    color: 'white',
                    width: '10px',
                    height: '14px',
                    strokeWidth: '2px',
                    stroke: 'white'
                }}
            />
        </IconCheckedOuter>
    );
};

const CustomIconChecked = ({ checkedColor }: IconCheckedProps) => {
    const theme = useTheme();
    return (
        <CustomIconCheckedOuter
            style={{
                borderColor: checkedColor || theme.palette.text.success,
                backgroundColor: checkedColor || theme.palette.successBackground
            }}
        >
            <FiberManualRecordIcon
                style={{
                    color: theme.palette.text.success,
                    width: '11px',
                    height: '11px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            />
        </CustomIconCheckedOuter>
    );
};

export const RadioButton = (props: IconCheckedProps) => {
    return (
        <MuiRadio
            icon={<Icon />}
            checkedIcon={
                props?.customRadio ? <CustomIconChecked /> : <IconChecked />
            }
            {...props}
        />
    );
};
