import { MSALHolder } from '../lib/msal';
import {
    ChangeUsersStatusRequest,
    CreateCompanyRequest,
    CreateUserRequest,
    UpdateCompanyAuthSettingsRequest,
    UpdateCompanyRequest,
    UpdateConsentsRequest,
    UpdateUserRequest,
    UpdateUserRoleRequest,
    UploadCompanyLogoRequest
} from '../models/company';
import { SendAllEmailsRequest } from '../models/notification';
import BaseAPI from './baseServiceV2';
import { AxiosInstance } from 'axios';

class NotificationAPIServiceV2 extends BaseAPI {
    client!: AxiosInstance;

    static instance: NotificationAPIServiceV2;

    private constructor() {
        super();
        (async () => {
            const USER_API_BASE_URL = (
                await MSALHolder.getInstance()
            ).getSpaConfiguration()?.backendNotificationsServiceUrl;
            this.client = this.getClient(USER_API_BASE_URL!);
        })();

        NotificationAPIServiceV2.instance = this;
    }

    sendAllEmails = async (request: SendAllEmailsRequest) => {
        const headers = await this.getAuthHeaders();
        return this.client.post('/api/emails/send-all', request, { headers });
    };

    static getInstance = () => {
        if (this.instance) {
            return this.instance;
        } else {
            this.instance = new NotificationAPIServiceV2();
            return this.instance;
        }
    };
}

export default NotificationAPIServiceV2;
