import { SvgIcon, SvgIconProps } from '@mui/material';

export const EmployeesIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 16">
            <rect width="16" height="16" rx="3" />
            <g clip-path="url(#clip0_2690_22811)">
                <path
                    d="M10.0832 11.75V10.9167C10.0832 10.4746 9.90758 10.0507 9.59501 9.73816C9.28245 9.42559 8.85853 9.25 8.4165 9.25H5.08317C4.64114 9.25 4.21722 9.42559 3.90466 9.73816C3.5921 10.0507 3.4165 10.4746 3.4165 10.9167V11.75M12.5832 11.75V10.9167C12.5829 10.5474 12.46 10.1887 12.2337 9.8968C12.0075 9.60494 11.6907 9.39649 11.3332 9.30417M9.6665 4.30417C10.025 4.39596 10.3428 4.60446 10.5697 4.8968C10.7966 5.18913 10.9198 5.54868 10.9198 5.91875C10.9198 6.28882 10.7966 6.64837 10.5697 6.9407C10.3428 7.23304 10.025 7.44154 9.6665 7.53333M8.4165 5.91667C8.4165 6.83714 7.67031 7.58333 6.74984 7.58333C5.82936 7.58333 5.08317 6.83714 5.08317 5.91667C5.08317 4.99619 5.82936 4.25 6.74984 4.25C7.67031 4.25 8.4165 4.99619 8.4165 5.91667Z"
                    stroke="#F5FAFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2690_22811">
                    <rect
                        x="3"
                        y="3"
                        width="10"
                        height="10"
                        rx="2"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
