import { useState, useEffect, useCallback } from 'react';
import { useErrorHandling } from '../../utils/errorHandling';
import { useNotificationsSnackbar } from '../snackbar/NotificationsSnackbarContext';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { TermsVersionsResponse } from '../../models/payment';
import { FirstTimeConsent } from './FirstTimeConsent';
import { UpdateConsentsRequest } from '../../models/company';
import { UpdatedConsent } from './UpdatedConsent';
import UsersAPIServiceV2 from '../../services/UserServiceV2';

export const Consents = () => {
    const [termsVersions, setTermsVersions] = useState<TermsVersionsResponse>();
    const { backendErrorHandler } = useErrorHandling();
    const { setAlert } = useNotificationsSnackbar();
    const { setLoading } = useLoader();

    const onAccept = (request: UpdateConsentsRequest) => {
        setLoading(true);
        UsersAPIServiceV2.getInstance()
            .updateConsents(request)
            .then(() => {
                if (termsVersions?.hasAcceptedTerms === false) {
                    setAlert('Welcome!', 'success');
                }
                window.location.reload();
            })
            .catch(backendErrorHandler('Could not update consents: '))
            .finally(() => setLoading(false));
    };

    const onPostpone = () => {
        setLoading(true);
        UsersAPIServiceV2.getInstance()
            .postponeConsents()
            .then(() => {
                if (termsVersions?.hasAcceptedTerms === false) {
                    setAlert('Welcome!', 'success');
                }
                window.location.reload();
            })
            .catch(backendErrorHandler('Could not postpone consents: '))
            .finally(() => setLoading(false));
    };

    const loadTerms = useCallback(() => {
        setLoading(true);
        UsersAPIServiceV2.getInstance()
            .getTermsVersions()
            .then((response: any) => {
                setTermsVersions(response.data);
            })
            .catch(
                backendErrorHandler('Could not get current terms versions: ')
            )
            .finally(() => {
                setLoading(false);
            });
    }, [backendErrorHandler, setLoading]);

    useEffect(() => {
        loadTerms();
    }, [loadTerms]);

    return (
        <>
            {termsVersions?.hasAcceptedTerms === true && (
                <UpdatedConsent
                    updateConsents={onAccept}
                    postponeConsents={onPostpone}
                    termsVersions={termsVersions}
                />
            )}
            {termsVersions?.hasAcceptedTerms === false && (
                <FirstTimeConsent
                    updateConsents={onAccept}
                    postponeConsents={onPostpone}
                    termsVersions={termsVersions}
                />
            )}
        </>
    );
};
