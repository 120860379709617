import { Alert, Box, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';

import { useErrorHandling } from '../../utils/errorHandling';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export const AiiaConnect = () => {
    const [connectUrl, setConnectUrl] = useState<string>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const { setAlert } = useNotificationsSnackbar();
    const { backendErrorHandler } = useErrorHandling();

    useEffect(() => {
        if (!connectUrl && !errorMessage) {
            PaymentsAPIServiceV2.getInstance()
                .getAiiaConnectUrl()
                .then((response: any) => {
                    setConnectUrl(response.data.connectUrl);
                })
                .catch(
                    backendErrorHandler(
                        'Could not get AiiA connect URL: ',
                        (e: Error) => {
                            setErrorMessage(
                                'Could not get AiiA connect URL: ' + e.message
                            );
                        }
                    )
                );
        }
    }, [setAlert, connectUrl, errorMessage, backendErrorHandler]);

    return (
        <Box>
            {connectUrl && (
                <Button variant="contained">
                    <a
                        href={connectUrl}
                        style={{ color: 'white', textDecoration: 'none' }}
                    >
                        Connect to AiiA
                    </a>
                </Button>
            )}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </Box>
    );
};
