import { Dialog, DialogContent, DialogTitle, FormGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { TermsVersionsResponse } from '../../models/payment';
import { UpdateConsentsRequest } from '../../models/company';
import { useUser } from '../../contexts/user/UserContext';
import { ConsentFormActions } from './ConsentFormActions';
import { ConsentCheckbox } from './ConsentCheckbox';
import { ExitImpersonationAlert } from '../ExitImpersonationAlert';
import { StyledDialog, StyledDynamicContentBox } from './StyledDialog';
import { SemiBold, SMRegular } from '../../typography/Typography';
import { createElement } from 'react';
import { sanitize } from 'dompurify';

export type UpdatedConsentProps = {
    termsVersions: TermsVersionsResponse;
    updateConsents: (request: UpdateConsentsRequest) => void;
    postponeConsents: () => void;
};

export const UpdatedConsent = (props: UpdatedConsentProps) => {
    const { user } = useUser();
    const [commercialConsent, setCommercialConsent] = useState(false);
    const [dataConsent, setDataConsent] = useState(false);
    const [newsletterConsent, setNewsletterConsent] = useState(false);
    const commercialTerms = props.termsVersions.termsVersions.find(
        (_) => _.consentType === 'Commercial'
    );
    const dataTerms = props.termsVersions.termsVersions.find(
        (_) => _.consentType === 'Data'
    );

    return (
        <StyledDialog
            open={true}
            fullWidth={true}
            maxWidth="sm"
            PaperProps={{
                style: {
                    borderRadius: '12px',
                    boxShadow:
                        '0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814',
                    padding: '28px 24px 16px 24px'
                }
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '16px'
                }}
            >
                <img
                    style={{
                        marginBottom: '16px'
                    }}
                    src="/consent_top_logo.svg"
                    alt="InstaPaid Logo"
                />
                <SemiBold>We have updated our terms and conditions</SemiBold>
            </div>

            <StyledDynamicContentBox>
                <SMRegular>
                    {dataTerms?.comment && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitize(dataTerms.comment)
                            }}
                        ></div>
                    )}
                    {commercialTerms?.comment && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitize(commercialTerms.comment)
                            }}
                        ></div>
                    )}
                </SMRegular>
            </StyledDynamicContentBox>
            <DialogContent sx={{ color: 'black', padding: '20px 0px' }}>
                <FormGroup>
                    {commercialTerms?.isAccepted === false &&
                    dataTerms?.isAccepted === false ? (
                        <>
                            <ConsentCheckbox
                                checked={commercialConsent && dataConsent}
                                setChecked={(checked) => {
                                    setCommercialConsent(checked);
                                    setDataConsent(checked);
                                }}
                                label={
                                    <>
                                        <span>
                                            I have read and accepted the{' '}
                                        </span>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={commercialTerms?.url}
                                        >
                                            license agreement
                                        </a>
                                        <span> and the </span>
                                        <a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={dataTerms?.url}
                                        >
                                            data processing agreement
                                        </a>
                                        .
                                    </>
                                }
                            />
                        </>
                    ) : (
                        <>
                            {commercialTerms?.isAccepted === false && (
                                <>
                                    <ConsentCheckbox
                                        checked={commercialConsent}
                                        setChecked={(checked) =>
                                            setCommercialConsent(checked)
                                        }
                                        label={
                                            <>
                                                <span>
                                                    I have read and accepted the{' '}
                                                </span>
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={commercialTerms?.url}
                                                >
                                                    license agreement
                                                </a>
                                                .
                                            </>
                                        }
                                    />
                                </>
                            )}

                            {dataTerms?.isAccepted === false && (
                                <>
                                    <ConsentCheckbox
                                        checked={dataConsent}
                                        setChecked={(checked) =>
                                            setDataConsent(checked)
                                        }
                                        label={
                                            <>
                                                <span>
                                                    I have read and accepted the{' '}
                                                </span>
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={dataTerms?.url}
                                                >
                                                    data processing agreement
                                                </a>
                                                .
                                            </>
                                        }
                                    />
                                </>
                            )}
                        </>
                    )}

                    {props.termsVersions.hasAcceptedNewsletterAndUpdates ===
                        false && (
                        <ConsentCheckbox
                            checked={newsletterConsent}
                            setChecked={(checked) =>
                                setNewsletterConsent(checked)
                            }
                            label="I hereby give my consent to receive newsletters and information from InstaPaid via email or through the InstaPaid application regarding InstaPaid's product range, such as updates and new features in the InstaPaid application. InstaPaid will process my information, including name, contact details, and company, for this purpose."
                        />
                    )}
                </FormGroup>
            </DialogContent>
            <ConsentFormActions
                commercialConsent={commercialConsent}
                dataConsent={dataConsent}
                newsletterConsent={newsletterConsent}
                postponeConsents={props.postponeConsents}
                updateConsents={props.updateConsents}
                termsVersions={props.termsVersions}
            />
        </StyledDialog>
    );
};
