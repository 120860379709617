import { useState } from 'react';
import { Button, Typography, Box } from '@mui/material';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { AddCompanyDialog } from './AddCompanyDialog';
import { CreateCompanyRequest } from '../../models/company';
import { Validator, useErrorHandling } from '../../utils/errorHandling';
import UsersAPIServiceV2 from '../../services/UserServiceV2';
import { PrimaryButton } from '../../components/shared/Button';

export type AddCompanyFormProps = {
    onAddSuccess: () => void;
};

export const AddCompanyForm = (props: AddCompanyFormProps) => {
    const { setAlert } = useNotificationsSnackbar();
    const { setLoading } = useLoader();

    const { backendErrorHandler } = useErrorHandling();
    const [open, setOpen] = useState(false);
    const [validator, setValidator] = useState(new Validator());

    const onClickOpen = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onAdd = async (
        request: CreateCompanyRequest,
        onSuccess: () => void
    ): Promise<void> => {
        setLoading(true);
        UsersAPIServiceV2.getInstance()
            .createCompany(request)
            .then(() => {
                setAlert('New company added successfully.', 'success');
                setOpen(false);
                setValidator(validator.clearErrors());
                onSuccess();
                props.onAddSuccess();
            })
            .catch(
                backendErrorHandler(undefined, (e, errorDetails) => {
                    setValidator(new Validator(errorDetails?.errors));
                })
            )
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {/* <Typography variant="h5">Manage companies</Typography>
            <Box>Here you can add new companies and initial admin users.</Box> */}
            <PrimaryButton
                size="small"
                variant="contained"
                onClick={onClickOpen}
            >
                Add company
            </PrimaryButton>
            <AddCompanyDialog
                validator={validator}
                setValidator={setValidator}
                open={open}
                onClose={onClose}
                onAdd={onAdd}
            />
        </>
    );
};
