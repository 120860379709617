import { Button, Card, Grid } from '@mui/material';
import {
    Caption,
    Heading,
    SubHeading,
    TEXT_SIZE
} from '../../../typography/Typography';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../contexts/user/UserContext';
import { useFormatting } from '../../../utils/formatting';
import { cardStyles } from './CardStyles';
import { OutlinedSecondaryButton } from '../../../components/shared/Button';

export interface BalanceSummaryProps {
    taxRate: number;
    taxDeduction: number;
    onShowAllDetails?: () => void;
}

export const BalanceSummary = ({
    taxRate,
    taxDeduction,
    onShowAllDetails
}: BalanceSummaryProps) => {
    const { getBalanceLabel, toDecimal } = useFormatting();
    const { t } = useTranslation();
    const { company } = useContext(UserContext);

    return (
        <Card style={cardStyles}>
            <Heading fontSize={TEXT_SIZE.SMALL}>{t('tax_card')}</Heading>

            <Grid
                md={12}
                mb={1}
                mt={1}
                container
                justifyContent={'space-around'}
            >
                <Grid item direction={'column'} alignItems={'center'}>
                    <SubHeading>
                        {getBalanceLabel(taxDeduction, company.currency)}
                    </SubHeading>
                    <Caption>{t('tax_deduction')}</Caption>
                </Grid>

                <Grid item direction={'column'} alignItems={'center'}>
                    <SubHeading>{toDecimal(taxRate * 100, 2)}%</SubHeading>
                    <Caption> {t('tax_rate')}</Caption>
                </Grid>
            </Grid>
            <Grid md={12}>
                <OutlinedSecondaryButton onClick={onShowAllDetails}>
                    {t('balance_summary')}
                </OutlinedSecondaryButton>
            </Grid>
        </Card>
    );
};
