import { Box, Typography } from '@mui/material';
import { CompanyPaymentDetailsResponse } from '../../models/payment';
import { AiiaConnect } from './AiiaConnect';

export type AiiaConnectStatusProps = {
    companyPaymentDetails: CompanyPaymentDetailsResponse;
};

export const AiiaConnectStatus = (props: AiiaConnectStatusProps) => {
    return (
        <>
            <Typography variant="h5">AiiA settings</Typography>
            {props.companyPaymentDetails?.aiiaAccountId && (
                <>
                    <Box>Your company is connected to AiiA account.</Box>
                    <Box>
                        Your bank allows batch payments of up to{' '}
                        {
                            props.companyPaymentDetails
                                .aiiaPaymentsAllowedPerAuthorization
                        }{' '}
                        payment(-s).
                    </Box>
                    <Box>
                        Click the button below to go through the connect flow
                        again.
                    </Box>
                </>
            )}
            {!props.companyPaymentDetails?.aiiaAccountId && (
                <>
                    <Box>You have not connected an Aiia account yet.</Box>
                    <Box>Click the button below to connect.</Box>
                </>
            )}
            <Box sx={{ marginTop: '1rem' }}>
                <AiiaConnect />
            </Box>
        </>
    );
};
