import { Card } from '@mui/material';
import {
    Caption,
    FONT_WEIGHT,
    Heading,
    LINE_HEIGHT,
    TEXT_SIZE
} from '../../../typography/Typography';
import { useContext } from 'react';
import { useFormatting } from '../../../utils/formatting';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../contexts/user/UserContext';
import Chip from '../../../components/shared/Chip';
import { cardStyles } from './CardStyles';

export interface AvailableBalanceProps {
    balance: number;
    earnings: number;
    allowancePercentage: number;
}

export const AvailableBalance = ({
    balance,
    earnings,
    allowancePercentage
}: AvailableBalanceProps) => {
    const { getBalanceLabel } = useFormatting();
    const { t } = useTranslation();
    const { company } = useContext(UserContext);

    return (
        <Card style={cardStyles}>
            <Heading fontSize={TEXT_SIZE.SMALL}>Available balance</Heading>

            <Heading
                fontSize={TEXT_SIZE.LARGE}
                fontWeight={FONT_WEIGHT.BOLD}
                lineHeight={LINE_HEIGHT.LARGE}
            >
                {getBalanceLabel(balance, company.currency)}{' '}
            </Heading>
            <Caption>
                Current earnings {getBalanceLabel(earnings, company.currency)}
            </Caption>

            <Chip
                label={
                    <>
                        <b>{allowancePercentage}%</b> {t('withdrawal_limit')}
                    </>
                }
            />
        </Card>
    );
};
