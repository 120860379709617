import {
    Box,
    TextField,
    TextFieldProps,
    Button,
    FormControl
} from '@mui/material';
import { useState } from 'react';

import { useErrorHandling } from '../../utils/errorHandling';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useLoader } from '../../contexts/loader/LoaderContext';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export type PlandayConnectFormProps = {
    onSaveSuccess: () => void;
};

export const PlandayConnectForm = (props: PlandayConnectFormProps) => {
    const [plandayClientId, setPlandayClientId] = useState<string>('');
    const [plandayRefreshToken, setPlandayRefreshToken] = useState<string>('');
    const { backendErrorHandler } = useErrorHandling();
    const { setAlert } = useNotificationsSnackbar();
    const { setLoading } = useLoader();
    const commonProps: TextFieldProps = {
        margin: 'dense',
        variant: 'standard'
    };

    const onClick = () => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .plandaySetup({
                clientId: plandayClientId,
                refreshToken: plandayRefreshToken
            })
            .then(() => {
                setAlert('Connected successfully', 'success');
                props.onSaveSuccess();
            })
            .catch(backendErrorHandler('Could not connect with Planday: '))
            .finally(() => setLoading(false));
    };

    return (
        <Box>
            <FormControl>
                <TextField
                    {...commonProps}
                    label="Client id"
                    value={plandayClientId}
                    helperText={'Your client id in Planday salary system'}
                    onChange={(e) => {
                        setPlandayClientId(e.currentTarget.value);
                    }}
                />

                <TextField
                    {...commonProps}
                    label="Refresh token"
                    value={plandayRefreshToken}
                    helperText={'Refresh token used by Planday salary system'}
                    onChange={(e) => {
                        setPlandayRefreshToken(e.currentTarget.value);
                    }}
                />

                <Button variant="contained" onClick={onClick}>
                    Connect to Planday
                </Button>
            </FormControl>
        </Box>
    );
};
