import { createContext, useState, useContext, useCallback } from 'react';
import { AlertColor } from '@mui/material';

const defaultAlertTimeout = 5000;

type AlertProps = {
    children: JSX.Element;
};

type State = {
    text: string;
    type: AlertColor;
};
const initialState: State = {
    text: '',
    type: 'success'
};

export const NotificationsSnackbarContext = createContext({
    ...initialState,
    setAlert: (text: string, type: AlertColor) => {}
});

export const NotificationsProvider = (props: AlertProps) => {
    const [text, setText] = useState('');
    const [type, setType] = useState<AlertColor>('success');

    const setAlert = useCallback((text: string, type: AlertColor) => {
        setText(text);
        setType(type);

        setTimeout(() => {
            setText('');
            setType('success');
        }, defaultAlertTimeout);
    }, []);

    return (
        <NotificationsSnackbarContext.Provider value={{ text, type, setAlert }}>
            {props.children}
        </NotificationsSnackbarContext.Provider>
    );
};

export const useNotificationsSnackbar = () =>
    useContext(NotificationsSnackbarContext);
