import { IntegrationConnectionStatus } from '../../models/payment';
import { Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export type PayrollConnectionStatusHeaderProps = {
    status?: IntegrationConnectionStatus;
    providerName: string;
    externalCompanyId: string | undefined;
};

export const PayrollConnectionStatusHeader = (
    props: PayrollConnectionStatusHeaderProps
) => {
    const getTitle = () => {
        switch (props.status) {
            case 'Connected':
                return `You are connected to ${props.providerName} company ${props.externalCompanyId}. Click here to re-authorize.`;
            case 'NotConnected':
                return `Authorize with ${props.providerName}`;
            case 'ConnectionError':
                return `Connection to ${props.providerName} company ${props.externalCompanyId} could not be established. Click here to re-authorize.`;
            default:
                return '';
        }
    };

    return (
        <>
            {props.status === 'ConnectionError' && (
                <WarningIcon
                    color="error"
                    sx={{ marginRight: '5px' }}
                ></WarningIcon>
            )}
            {props.status === 'Connected' && (
                <CheckCircleIcon
                    color="success"
                    sx={{ marginRight: '5px' }}
                ></CheckCircleIcon>
            )}
            <Typography>{getTitle()}</Typography>
        </>
    );
};
