import WarningIcon from '@mui/icons-material/Warning';

export type UserTimeRegistrationRecordDetailsProps = {
    timeRegistrationProviderId: string | null;
};

export const UserTimeRegistrationRecordDetails = (
    props: UserTimeRegistrationRecordDetailsProps
) => {
    return (
        <>
            {props.timeRegistrationProviderId !== null ? (
                props.timeRegistrationProviderId
            ) : (
                <WarningIcon
                    sx={{ verticalAlign: 'bottom', margin: '0 0.3rem' }}
                    fontSize="small"
                    titleAccess="This employee does not have a record in time registration provider system"
                />
            )}
        </>
    );
};
