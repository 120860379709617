import { useCallback, useEffect, useState } from 'react';
import { ContentWrapper } from '../../components/ContentWrapper';
import { AiiaConnectStatus } from '../aiia/AiiaConnectStatus';
import { CompanyPaymentDetailsResponse } from '../../models/payment';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import { DanskeBankConnectStatus } from '../danskeBank/DanskeBankConnectStatus';
import { LumpSumStatus } from '../danskeBank/LumpSumStatus';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export const BankPage = () => {
    const [companyPaymentDetails, setCompanyPaymentDetails] =
        useState<CompanyPaymentDetailsResponse>();
    const { setLoading } = useLoader();
    // const { getCurrentCompany } = usePaymentService();
    const { backendErrorHandler } = useErrorHandling();

    const fetchCompany = useCallback(() => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getCurrentCompany()
            .then((response: any) => {
                setCompanyPaymentDetails(response.data);
            })
            .catch(backendErrorHandler())
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, backendErrorHandler]);

    useEffect(() => {
        fetchCompany();
    }, [fetchCompany]);

    return (
        <ContentWrapper>
            <>
                {companyPaymentDetails?.paymentProvider === 'Aiia' && (
                    <AiiaConnectStatus
                        companyPaymentDetails={companyPaymentDetails}
                    />
                )}
                {companyPaymentDetails?.paymentProvider === 'DanskeBank' && (
                    <DanskeBankConnectStatus
                        companyPaymentDetails={companyPaymentDetails}
                    />
                )}
                {companyPaymentDetails?.paymentSource ===
                    'InstaPaidAccount' && (
                    <LumpSumStatus
                        companyPaymentDetails={companyPaymentDetails}
                    />
                )}
            </>
        </ContentWrapper>
    );
};
