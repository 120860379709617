import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
    ConfigurationProvider,
    useConfiguration
} from './contexts/configuration/ConfigurationContext';
import {
    ReactPlugin,
    AppInsightsContext
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import './translations/i18n';

const AppWithMsal = () => {
    const { msalInstance, spaConfiguration } = useConfiguration();

    var reactPlugin = new ReactPlugin();
    var appInsights = new ApplicationInsights({
        config: {
            connectionString: spaConfiguration.appInsightsConnectionString,
            enableCorsCorrelation: true,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            enableAutoRouteTracking: true,
            extensions: [reactPlugin]
        }
    });
    appInsights.loadAppInsights();

    return (
        <>
            {msalInstance && (
                <AppInsightsContext.Provider value={reactPlugin}>
                    <App pca={msalInstance} />
                </AppInsightsContext.Provider>
            )}
        </>
    );
};

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ConfigurationProvider>
            <AppWithMsal />
        </ConfigurationProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
