import {
    Grid,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Button,
    Container
} from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState, useCallback } from 'react';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useLoader } from '../../contexts/loader/LoaderContext';
import {
    IntectEmploymentTemplatesResponse,
    IntectDepartmentsResponse
} from '../../models/payment';
import { useErrorHandling } from '../../utils/errorHandling';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { ImportSettingsProps } from '../users/import/ImportUsers';
import { ImportFiltersHeader } from '../users/import/ImportFiltersHeader';
import { useAppDispatch, usePayments } from '../../lib/hooks';
import { FETCH_STATE } from '../../lib/slices/types';
import {
    getIntectDepartments,
    getIntectEmploymentTemplates
} from '../../lib/slices/paymentsSlice';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export const IntectImportSettings = (props: ImportSettingsProps) => {
    const { setLoading } = useLoader();
    const { setAlert } = useNotificationsSnackbar();

    const { backendErrorHandler } = useErrorHandling();
    const [departments, setDepartments] = useState<IntectDepartmentsResponse>();
    const [employmentTemplates, setEmploymentTemplates] =
        useState<IntectEmploymentTemplatesResponse>();
    const [
        confirmSettingsChangeDialogOpen,
        setConfirmSettingsChangeDialogOpen
    ] = useState(false);
    const [settingsDeselected, setSettingsDeselected] = useState(false);

    const { planDayEmployeeType, intectDepartments, intectEmployeeTemplates } =
        usePayments();
    const dispatch = useAppDispatch();

    useEffect(() => {
        setLoading(intectDepartments.status == FETCH_STATE.LOADING);
        if (intectDepartments) {
            setDepartments(intectDepartments.intectDepartments);
        }
        if (planDayEmployeeType.error) {
            backendErrorHandler('Error fetching departments: ');
        }
    }, [intectDepartments]);

    useEffect(() => {
        setLoading(intectEmployeeTemplates.status == FETCH_STATE.LOADING);
        if (intectEmployeeTemplates) {
            setEmploymentTemplates(
                intectEmployeeTemplates?.intectEmployeeTemplates
            );
        }
        if (intectEmployeeTemplates.error) {
            backendErrorHandler('Error fetching employment templates: ');
        }
    }, [intectEmployeeTemplates]);

    const loadSettings = useCallback(() => {
        dispatch(getIntectDepartments());
        dispatch(getIntectEmploymentTemplates());
    }, []);

    useEffect(() => {
        loadSettings();
    }, [loadSettings]);

    const handleCheckboxClick = <T extends string>(
        e: React.MouseEvent<HTMLLabelElement, MouseEvent>,
        clickedItem: CheckboxItem<T>,
        items: CheckboxItem<T>[] | undefined,
        setter: (items: CheckboxItem<T>[]) => void
    ) => {
        e.preventDefault();
        if (!items) return;
        props.setSettingsTouched(true);

        const currentIndex = items.indexOf(clickedItem);
        const newItems = items.map((item) => ({ ...item }));

        newItems[currentIndex].isUsersSyncEnabled =
            !items[currentIndex].isUsersSyncEnabled;
        if (newItems[currentIndex].isUsersSyncEnabled === false) {
            // if the item has been deselected, update the state to render the confirmation dialog before user saves the settings
            setSettingsDeselected(true);
        }

        setter(newItems);
    };

    const saveSettings = () => {
        if (!employmentTemplates || !departments) return;

        setConfirmSettingsChangeDialogOpen(false);
        setLoading(true);
        const departmentsPromise =
            PaymentsAPIServiceV2.getInstance().updateIntectDepartments(
                departments
            );
        const employmentTemplatesPromise =
            PaymentsAPIServiceV2.getInstance().updateIntectEmploymentTemplates(
                employmentTemplates
            );

        Promise.all([employmentTemplatesPromise, departmentsPromise])
            .then(() => {
                props.setSettingsTouched(false);
                setSettingsDeselected(false);
                setAlert('Settings saved successfully.', 'success');
            })
            .catch(backendErrorHandler('Could not update settings: '))
            .finally(() => {
                setLoading(false);
            });
    };

    const onSaveClick = () => {
        if (settingsDeselected) {
            // if any settings have been deselected, show confirmation dialog
            setConfirmSettingsChangeDialogOpen(true);
        } else {
            saveSettings();
        }
    };

    const onCancelClick = () => {
        loadSettings();
        props.setSettingsTouched(false);
    };

    type CheckboxItem<T> = {
        id: string;
        name: T;
        isUsersSyncEnabled: boolean;
    };

    const renderCheckboxes = <T extends string>(
        title: string,
        items: CheckboxItem<T>[],
        setter: (items: CheckboxItem<T>[]) => void
    ) => {
        return (
            <Grid item xs={12} sm={6} md={4}>
                <b>{title}</b>
                <br />
                {items && (
                    <FormGroup>
                        {items.map((item) => (
                            <FormControlLabel
                                onClick={(e) =>
                                    handleCheckboxClick(e, item, items, setter)
                                }
                                key={item.name}
                                control={
                                    <Checkbox
                                        size="small"
                                        sx={{ padding: '0.3rem 0.5rem' }}
                                        checked={item.isUsersSyncEnabled}
                                    />
                                }
                                label={item.name}
                            />
                        ))}
                    </FormGroup>
                )}
            </Grid>
        );
    };

    return (
        <Container sx={{ marginBottom: 3 }}>
            <ImportFiltersHeader onRefreshSuccess={loadSettings} />
            Choose employees which should be imported from Intect. Only
            categories with active users are shown below. Click the refresh icon
            to update the lists. After initial setup of the connection to
            Intect, we will run the import daily for you using the same settings
            to make sure the employee data stays up to date.
            <Grid marginTop="0.2rem" marginBottom="1rem" container spacing={1}>
                {departments &&
                    renderCheckboxes(
                        'Department',
                        departments?.departments,
                        (items: CheckboxItem<string>[]) => {
                            setDepartments({ departments: items });
                        }
                    )}
                {employmentTemplates &&
                    renderCheckboxes(
                        'Employment template',
                        employmentTemplates?.employmentTemplates,
                        (items: CheckboxItem<string>[]) => {
                            setEmploymentTemplates({
                                employmentTemplates: items
                            });
                        }
                    )}
            </Grid>
            <ConfirmDialog
                title="Are you sure?"
                content="Disabling import categories may result in deletion of user profiles."
                dialogOpen={confirmSettingsChangeDialogOpen}
                handleContinue={() => {
                    saveSettings();
                }}
                handleCancel={() => setConfirmSettingsChangeDialogOpen(false)}
            />
            <Stack direction="row" spacing={2}>
                <Button
                    color="error"
                    variant="outlined"
                    onClick={onCancelClick}
                >
                    Cancel
                </Button>
                <Button
                    color="success"
                    variant="outlined"
                    onClick={onSaveClick}
                >
                    Save settings
                </Button>
            </Stack>
        </Container>
    );
};
