import { Box } from '@mui/material';
import { useUser } from '../../contexts/user/UserContext';

export type SidebarHeaderProps = {
    open: boolean;
};

export const SidebarHeader = (props: SidebarHeaderProps) => {
    const { company } = useUser();

    return (
        <>
            {props.open ? (
                <Box
                    sx={{
                        marginLeft: '1rem',
                        width: '100%',
                        justifyContent: 'center',
                        display: 'flex'
                    }}
                >
                    {company?.theme?.logoName ? (
                        <img
                            style={{ width: '80px' }}
                            src={company?.theme.logoName}
                            alt={company.name}
                        />
                    ) : (
                        <h3> {company?.name} </h3>
                    )}
                </Box>
            ) : (
                <></>
            )}
        </>
    );
};
