import { useCallback, useEffect, useState } from 'react';
import {
    CompanyPaymentDetailsResponse,
    IntegrationStatusResponse
} from '../../models/payment';
import {
    Typography,
    Box,
    Container,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PlandayConnectForm } from './PlandayConnectForm';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import { PayrollConnectionStatusHeader } from '../payroll/PayrollConnectionStatusHeader';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export type PlandayConnectStatusProps = {
    company: CompanyPaymentDetailsResponse;
    onSaveSuccess: () => void;
};

export const PlandayConnectStatus = (props: PlandayConnectStatusProps) => {
    const { setLoading } = useLoader();

    const { backendErrorHandler } = useErrorHandling();
    const [integrationStatus, setIntegrationStatus] =
        useState<IntegrationStatusResponse>();
    const [open, setOpen] = useState(props.company.plandayCompanyId === null);

    const fetchIntegrationStatus = useCallback(() => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getIntegrationStatus('planday')
            .then((response: any) => {
                setIntegrationStatus(response.data);
            })
            .catch(backendErrorHandler())
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, backendErrorHandler]);

    useEffect(() => {
        fetchIntegrationStatus();
    }, [fetchIntegrationStatus]);

    return (
        <>
            <Typography variant="h5" marginBottom={1}>
                Planday settings
            </Typography>

            <Accordion
                expanded={open}
                onChange={(event: React.SyntheticEvent, newExpanded: boolean) =>
                    setOpen(newExpanded)
                }
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <PayrollConnectionStatusHeader
                        externalCompanyId={props.company.plandayCompanyId}
                        providerName="Planday"
                        status={integrationStatus?.status}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <Container>
                        {props.company?.plandayCompanyId && (
                            <>
                                {integrationStatus?.status === 'Connected' && (
                                    <>
                                        <Box>
                                            Your company is connected to Planday
                                            client{' '}
                                            <strong>
                                                {props.company.plandayCompanyId}
                                            </strong>
                                            .
                                        </Box>
                                        <Box>
                                            Fill in the fields below to connect
                                            again.
                                        </Box>

                                        <PlandayConnectForm
                                            onSaveSuccess={() => {
                                                props.onSaveSuccess();
                                                fetchIntegrationStatus();
                                            }}
                                        />
                                    </>
                                )}
                                {integrationStatus?.status ===
                                    'ConnectionError' && (
                                    <>
                                        <Box>
                                            Connection to Planday company{' '}
                                            <strong>
                                                {props.company.plandayCompanyId}
                                            </strong>{' '}
                                            could not be established.
                                        </Box>
                                        <Box>
                                            Fill in the fields below to connect
                                            again.
                                        </Box>

                                        <PlandayConnectForm
                                            onSaveSuccess={() => {
                                                props.onSaveSuccess();
                                                fetchIntegrationStatus();
                                            }}
                                        />
                                    </>
                                )}
                                {integrationStatus?.status ===
                                    'NotConnected' && (
                                    <>
                                        <Box>
                                            You have not connected a Planday
                                            account yet.
                                        </Box>
                                        <Box>
                                            Fill in the fields below to connect.
                                        </Box>

                                        <PlandayConnectForm
                                            onSaveSuccess={() => {
                                                props.onSaveSuccess();
                                                fetchIntegrationStatus();
                                            }}
                                        />
                                    </>
                                )}
                            </>
                        )}
                        {!props.company?.plandayCompanyId && (
                            <>
                                <Box>
                                    You have not connected a Planday account
                                    yet.
                                </Box>
                                <Box>Fill in the fields below to connect.</Box>

                                <PlandayConnectForm
                                    onSaveSuccess={() => {
                                        props.onSaveSuccess();
                                        fetchIntegrationStatus();
                                    }}
                                />
                            </>
                        )}
                    </Container>
                </AccordionDetails>
            </Accordion>
        </>
    );
};
