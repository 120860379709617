import { useEffect, useState, useCallback } from 'react';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import PaymentsAPIServiceV2, { Shift } from '../../services/PaymentsServiceV2';
import { useAppDispatch } from '../../lib/hooks';

export const useShifts = (userId: string, employeeType?: string) => {
    const { setLoading } = useLoader();
    const [shifts, setShifts] = useState<Shift[]>([]);
    const [shiftsError, setShiftsError] = useState<string>();

    const { backendErrorHandler } = useErrorHandling();
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const getShifts = useCallback(() => {
        setIsLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getUserShifts(userId)
            .then((response: any) => {
                setShifts(response.data.shifts);
            })
            .catch(
                backendErrorHandler(undefined, (e, errorDetails) => {
                    setShiftsError(
                        'Error fetching shifts. ' + errorDetails?.detail
                    );
                })
            )
            .finally(() => {
                setIsLoading(false);
            });
    }, [backendErrorHandler, setLoading]);

    useEffect(() => {
        if (employeeType === 'Hourly') {
            getShifts();
        }
    }, []);

    return {
        shifts,
        shiftsError,
        isLoading
    } as const;
};
