import { SvgIcon, SvgIconProps } from '@mui/material';

export const SubscriptionIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 16" width="16" height="16">
            <rect width="16" height="16" rx="3" />
            <g clip-path="url(#clip0_2690_317)">
                <path
                    d="M9.6665 11.75V10.9167C9.6665 10.4746 9.49091 10.0507 9.17835 9.73816C8.86579 9.42559 8.44186 9.25 7.99984 9.25H5.08317C4.64114 9.25 4.21722 9.42559 3.90466 9.73816C3.5921 10.0507 3.4165 10.4746 3.4165 10.9167V11.75M10.0832 7.58333L10.9165 8.41667L12.5832 6.75M8.20817 5.91667C8.20817 6.83714 7.46198 7.58333 6.5415 7.58333C5.62103 7.58333 4.87484 6.83714 4.87484 5.91667C4.87484 4.99619 5.62103 4.25 6.5415 4.25C7.46198 4.25 8.20817 4.99619 8.20817 5.91667Z"
                    stroke="#F5FAFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2690_317">
                    <rect
                        x="3"
                        y="3"
                        width="10"
                        height="10"
                        rx="2"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
