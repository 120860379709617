import { SvgIconProps } from '@mui/material';

export const PlayIcon = (props: SvgIconProps) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.3335 2L12.6668 8L3.3335 14V2Z"
                stroke="#17B76A"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};
