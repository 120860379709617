import { SvgIcon, SvgIconProps } from '@mui/material';

export const LogoutIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 16">
            <rect width="15" height="15" rx="3" />
            <g clip-path="url(#clip0_2690_1046)">
                <path
                    d="M6.75 11.75H5.08333C4.86232 11.75 4.65036 11.6622 4.49408 11.5059C4.3378 11.3496 4.25 11.1377 4.25 10.9167V5.08333C4.25 4.86232 4.3378 4.65036 4.49408 4.49408C4.65036 4.3378 4.86232 4.25 5.08333 4.25H6.75M9.66667 10.0833L11.75 8M11.75 8L9.66667 5.91667M11.75 8H6.75"
                    stroke="#F5FAFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2690_1046">
                    <rect
                        x="3"
                        y="3"
                        width="10"
                        height="10"
                        rx="2"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
