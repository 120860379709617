import { useState, useEffect, useCallback } from 'react';
import { ContentWrapper } from '../../components/ContentWrapper';
import { CompanyPaymentDetailsResponse } from '../../models/payment';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import { Alert } from '@mui/material';
import { TimeRegistrationProviderSelect } from './TimeRegistrationProviderSelect';
import { PlandayConnectStatus } from '../planday/PlandayConnectStatus';
import { ZenegyConnectStatus } from '../zenegy/ZenegyConnectStatus';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export const TimeRegistrationPage = () => {
    const [company, setCompany] = useState<CompanyPaymentDetailsResponse>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const { setLoading } = useLoader();
    const { backendErrorHandler } = useErrorHandling();

    const loadCompany = useCallback(() => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getCurrentCompany()
            .then((response: any) => {
                setCompany(response.data);
            })
            .catch(
                backendErrorHandler(
                    'Could not get company details: ',
                    (e: Error) => {
                        setErrorMessage(
                            'Could not get company details: ' + e.message
                        );
                    }
                )
            )
            .finally(() => {
                setLoading(false);
            });
    }, [backendErrorHandler, setLoading]);

    useEffect(() => {
        loadCompany();
    }, [loadCompany]);

    return (
        <ContentWrapper>
            <>
                {company?.usersCount === 1 && (
                    <TimeRegistrationProviderSelect
                        selectedTimeRegistrationProvider={
                            company.timeRegistrationProvider
                        }
                        onSaveSuccess={loadCompany}
                    />
                )}
                {company && company.timeRegistrationProvider === 'Planday' && (
                    <PlandayConnectStatus
                        company={company}
                        onSaveSuccess={loadCompany}
                    />
                )}
                {company && company.timeRegistrationProvider === 'Zenegy' && (
                    <ZenegyConnectStatus
                        company={company}
                        onSaveSuccess={loadCompany}
                        showImport={false}
                    />
                )}
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                <Alert sx={{ marginTop: '1rem' }} severity="info">
                    We will import data about your employees' hour registrations
                    from the time registration provider, but not the personal
                    data (like name, phone number, bank account details etc) -
                    those will be imported from the salary provider system.
                </Alert>
            </>
        </ContentWrapper>
    );
};
