import { useMemo } from "react";
import { BalanceDetails, ShiftStatusWithdrawAllowed } from "./types";

//TODO: add code to use Mobile/web strategy for logger
export const useBalanceV2 = (balanceDetails?: BalanceDetails, logger?: any) => {
  const awaitingAndAvailableHours = useMemo(() => {
    switch (balanceDetails?.shiftStatusWithdrawAllowed) {
      case ShiftStatusWithdrawAllowed.Registered:
        return {
          awaitingHours: 0,
          availableHours:
            balanceDetails?.hoursSummary.approved +
            balanceDetails?.hoursSummary.punchclockFinished +
            balanceDetails?.hoursSummary.punchclockStarted +
            balanceDetails?.hoursSummary.registered,
        };
      case ShiftStatusWithdrawAllowed.PunchclockFinished:
        return {
          awaitingHours:
            balanceDetails?.hoursSummary.registered +
            balanceDetails?.hoursSummary.punchclockStarted,
          availableHours:
            balanceDetails?.hoursSummary.punchclockFinished +
            balanceDetails?.hoursSummary.approved,
        };
      case ShiftStatusWithdrawAllowed.Approved:
        return {
          awaitingHours:
            balanceDetails?.hoursSummary.registered +
            balanceDetails?.hoursSummary.punchclockStarted +
            balanceDetails?.hoursSummary.punchclockFinished,
          availableHours: balanceDetails?.hoursSummary.approved,
        };
      default:
        if (balanceDetails?.shiftStatusWithdrawAllowed) {
          logger?.e(
            new Error("Unhandled shiftStatusWithdrawAllowed"),
            balanceDetails?.shiftStatusWithdrawAllowed
          );
        }
        return { awaitingHours: 0, availableHours: 0 };
    }
  }, [
    balanceDetails?.hoursSummary,
    balanceDetails?.shiftStatusWithdrawAllowed,
  ]);

  return {
    awaitingAndAvailableHours,
  } as const;
};
