import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CurrentPeriodShifts } from '../../services/PaymentsServiceV2';
import { BaseAPIState, FETCH_STATE, InstaPaidError } from './types';
import UsersAPIServiceV2 from '../../services/UserServiceV2';
import {
    Company,
    CompanyWithUsers,
    LanguagesResponse,
    UploadCompanyLogoRequest,
    UpdateCompanyAuthSettingsRequest,
    ResetUsersResponse,
    CurrentUser,
    CreateUsersInMultiCompaniesRequest
} from '../../models/company';

const USERS_SLICE_NAME = 'users';

export interface UpcomingShiftsState {
    currentPeriodShifts?: CurrentPeriodShifts;
    isLoading: boolean;
    error: any;
}

export interface UserData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    role: string;
}

export interface UserState extends BaseAPIState {
    data?: UserData;
}

export interface CompanyWithUsersState extends BaseAPIState {
    data?: CompanyWithUsers;
}

export interface UsersCurrentCompanyState extends BaseAPIState {
    company?: Company;
}

export interface SupportedLanguageState extends BaseAPIState {
    data?: LanguagesResponse;
}

export interface UploadCompanyLogoState {
    list: UploadCompanyLogoRequest[];
    status: FETCH_STATE;
    error?: InstaPaidError | undefined;
}

export interface UpdateCurrentAuthSettingsDataState extends BaseAPIState {
    data?: ResetUsersResponse;
}

export interface CurrentUserState extends BaseAPIState {
    data?: CurrentUser;
}

export interface UsersState {
    sendActivationMail: BaseAPIState;
    userData: UserState;
    companyWithUsers: CompanyWithUsersState;
    currentUserCompany: UsersCurrentCompanyState;
    deleteUser: BaseAPIState;
    supportedLanguages: SupportedLanguageState;
    uploadCompanyLogoData: UploadCompanyLogoState;
    updateCurrentAuth: UpdateCurrentAuthSettingsDataState;
    currentUser: CurrentUserState;
    userInMultiCompanies: BaseAPIState;
}

const initialState: UsersState = {
    sendActivationMail: {
        isLoading: false,
        status: FETCH_STATE.IDLE
    },
    userData: {
        isLoading: false,
        status: FETCH_STATE.IDLE
    },
    companyWithUsers: {
        isLoading: false,
        status: FETCH_STATE.IDLE,
        data: undefined
    },
    currentUserCompany: {
        isLoading: false,
        status: FETCH_STATE.IDLE
    },
    deleteUser: {
        status: FETCH_STATE.IDLE
    },
    supportedLanguages: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE,
        data: undefined
    },
    uploadCompanyLogoData: {
        list: [],
        status: FETCH_STATE.IDLE
    },
    updateCurrentAuth: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE,
        data: undefined
    },
    currentUser: {
        isLoading: false,
        error: null,
        status: FETCH_STATE.IDLE,
        data: undefined
    },
    userInMultiCompanies: {
        isLoading: false,
        status: FETCH_STATE.IDLE
    }
};

export const sendActivationEmail = createAsyncThunk(
    `${USERS_SLICE_NAME}/sendActivationEmail`,
    async (userId: string) => {
        const response =
            await UsersAPIServiceV2.getInstance().sendActivationEmail(userId);
        return response.data;
    }
);

export const getUserData = createAsyncThunk(
    `${USERS_SLICE_NAME}/getUserData`,
    async (userId: string) => {
        const response = await UsersAPIServiceV2.getInstance().getUser(userId);
        return response.data;
    }
);

export const getCurrentCompanyWithUsers = createAsyncThunk(
    `${USERS_SLICE_NAME}/getCurrentCompanyWithUsers`,
    async () => {
        const response =
            await UsersAPIServiceV2.getInstance().getCurrentCompanyWithUsers();
        return response.data;
    }
);

export const getUserCurrentCompany = createAsyncThunk(
    `${USERS_SLICE_NAME}/getCurrentCompany`,
    async () => {
        const response =
            await UsersAPIServiceV2.getInstance().getCurrentCompany();
        return response.data;
    }
);

export const deleteUser = createAsyncThunk(
    `${USERS_SLICE_NAME}/deleteUser`,
    async (userId: string) => {
        const response = await UsersAPIServiceV2.getInstance().deleteUser(
            userId
        );
        return response.data;
    }
);

export const getSupportedLanguageList = createAsyncThunk(
    `${USERS_SLICE_NAME}/getSupportedLanguages`,
    async () => {
        const response =
            await UsersAPIServiceV2.getInstance().getSupportedLanguages();
        return response.data;
    }
);

export const uploadCompanyLogo = createAsyncThunk(
    `${USERS_SLICE_NAME}/uploadCompanyLogo`,
    async (request: UploadCompanyLogoRequest) => {
        const response =
            await UsersAPIServiceV2.getInstance().uploadCompanyLogo(request);
        return response.data;
    }
);

export const updateCurrentAuthSettingsData = createAsyncThunk(
    `${USERS_SLICE_NAME}/updateCurrentAuthSettings`,
    async (updateData: UpdateCompanyAuthSettingsRequest) => {
        const response =
            await UsersAPIServiceV2.getInstance().updateCurrentAuthSettings(
                updateData
            );
        return response.data;
    }
);

export const getCurrentUserData = createAsyncThunk(
    `${USERS_SLICE_NAME}/getCurrentUser`,
    async () => {
        const response = await UsersAPIServiceV2.getInstance().getCurrentUser();
        return response.data;
    }
);

export const createUserInCompanies = createAsyncThunk(
    `${USERS_SLICE_NAME}/createUserInMultiCompanies`,
    async (request: CreateUsersInMultiCompaniesRequest) => {
        const response =
            await UsersAPIServiceV2.getInstance().createUserInMultiCompanies(
                request
            );
        return response.data;
    }
);

const userSlice = createSlice({
    name: USERS_SLICE_NAME,
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(sendActivationEmail.pending, (state) => {
            state.sendActivationMail.error = null;
            state.sendActivationMail.status = FETCH_STATE.LOADING;
        });
        builder.addCase(sendActivationEmail.fulfilled, (state) => {
            state.sendActivationMail.error = null;
            state.sendActivationMail.status = FETCH_STATE.SUCCEEDED;
        });
        builder
            .addCase(sendActivationEmail.rejected, (state, action) => {
                state.sendActivationMail.error = action.error;
                state.sendActivationMail.status = FETCH_STATE.FAILED;
            })
            .addCase(getUserData.pending, (state) => {
                state.userData.error = null;
                state.userData.status = FETCH_STATE.LOADING;
            })
            .addCase(getUserData.fulfilled, (state, action) => {
                state.userData.error = null;
                state.userData.status = FETCH_STATE.SUCCEEDED;
                state.userData.data = action.payload;
            })
            .addCase(getUserData.rejected, (state, action) => {
                state.userData.error = action.error;
                state.userData.status = FETCH_STATE.FAILED;
            })
            .addCase(getCurrentCompanyWithUsers.pending, (state) => {
                state.companyWithUsers.error = null;
                state.companyWithUsers.status = FETCH_STATE.LOADING;
            })
            .addCase(getCurrentCompanyWithUsers.fulfilled, (state, action) => {
                state.companyWithUsers.error = null;
                state.companyWithUsers.status = FETCH_STATE.SUCCEEDED;
                state.companyWithUsers.data = action.payload;
            })
            .addCase(getCurrentCompanyWithUsers.rejected, (state, action) => {
                state.companyWithUsers.error = action.error;
                state.companyWithUsers.status = FETCH_STATE.FAILED;
            })
            .addCase(getUserCurrentCompany.pending, (state) => {
                state.currentUserCompany.error = null;
                state.currentUserCompany.status = FETCH_STATE.LOADING;
            })
            .addCase(getUserCurrentCompany.fulfilled, (state, action) => {
                state.currentUserCompany.error = null;
                state.currentUserCompany.status = FETCH_STATE.SUCCEEDED;
                state.currentUserCompany.company = action.payload;
            })
            .addCase(getUserCurrentCompany.rejected, (state, action) => {
                state.currentUserCompany.error = action.error;
                state.currentUserCompany.status = FETCH_STATE.FAILED;
            })
            .addCase(deleteUser.pending, (state) => {
                state.deleteUser.error = null;
                state.deleteUser.status = FETCH_STATE.LOADING;
            })
            .addCase(deleteUser.fulfilled, (state) => {
                state.deleteUser.error = null;
                state.deleteUser.status = FETCH_STATE.SUCCEEDED;
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.deleteUser.error = action.error;
                state.deleteUser.status = FETCH_STATE.FAILED;
            })
            .addCase(getSupportedLanguageList.pending, (state) => {
                state.supportedLanguages.error = null;
                state.supportedLanguages.status = FETCH_STATE.LOADING;
            })
            .addCase(getSupportedLanguageList.fulfilled, (state, action) => {
                state.supportedLanguages.error = null;
                state.supportedLanguages.status = FETCH_STATE.SUCCEEDED;
                state.supportedLanguages.data = action.payload;
            })
            .addCase(getSupportedLanguageList.rejected, (state, action) => {
                state.supportedLanguages.error = action.error;
                state.supportedLanguages.status = FETCH_STATE.FAILED;
            })
            .addCase(uploadCompanyLogo.pending, (state) => {
                state.uploadCompanyLogoData.status = FETCH_STATE.LOADING;
            })
            .addCase(uploadCompanyLogo.fulfilled, (state, action) => {
                state.uploadCompanyLogoData.status = FETCH_STATE.SUCCEEDED;
                state.uploadCompanyLogoData.list = action.payload;
            })
            .addCase(uploadCompanyLogo.rejected, (state, action) => {
                state.uploadCompanyLogoData.error = action.error;
                state.uploadCompanyLogoData.status = FETCH_STATE.FAILED;
            })
            .addCase(updateCurrentAuthSettingsData.pending, (state) => {
                state.updateCurrentAuth.error = null;
                state.updateCurrentAuth.status = FETCH_STATE.LOADING;
            })
            .addCase(
                updateCurrentAuthSettingsData.fulfilled,
                (state, action) => {
                    state.updateCurrentAuth.error = null;
                    state.updateCurrentAuth.status = FETCH_STATE.SUCCEEDED;
                    state.updateCurrentAuth.data = action.payload;
                }
            )
            .addCase(
                updateCurrentAuthSettingsData.rejected,
                (state, action) => {
                    state.updateCurrentAuth.error = action.error;
                    state.updateCurrentAuth.status = FETCH_STATE.FAILED;
                }
            )
            .addCase(getCurrentUserData.pending, (state) => {
                state.currentUser.error = null;
                state.currentUser.status = FETCH_STATE.LOADING;
            })
            .addCase(getCurrentUserData.fulfilled, (state, action) => {
                state.currentUser.error = null;
                state.currentUser.status = FETCH_STATE.SUCCEEDED;
                state.currentUser.data = action.payload;
            })
            .addCase(getCurrentUserData.rejected, (state, action) => {
                state.currentUser.error = action.error;
                state.currentUser.status = FETCH_STATE.FAILED;
            })
            .addCase(createUserInCompanies.pending, (state) => {
                state.userInMultiCompanies.status = FETCH_STATE.LOADING;
                state.userInMultiCompanies.error = null;
            })
            .addCase(createUserInCompanies.fulfilled, (state) => {
                state.userInMultiCompanies.status = FETCH_STATE.SUCCEEDED;
                state.userInMultiCompanies.error = null;
            })
            .addCase(createUserInCompanies.rejected, (state, action) => {
                state.userInMultiCompanies.status = FETCH_STATE.FAILED;
                state.userInMultiCompanies.error = action.error.message;
            });
    }
});

export default userSlice.reducer;
