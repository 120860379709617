import {
    Box,
    TextField,
    TextFieldProps,
    Button,
    FormControl
} from '@mui/material';
import { useState } from 'react';
import { useErrorHandling } from '../../utils/errorHandling';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useLoader } from '../../contexts/loader/LoaderContext';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export type IntectConnectFormProps = {
    onSaveSuccess: () => void;
};

export const IntectConnectForm = (props: IntectConnectFormProps) => {
    const [intectUsername, setIntectUsername] = useState<string>('');
    const [intectPassword, setIntectPassword] = useState<string>('');
    const { backendErrorHandler } = useErrorHandling();

    const { setAlert } = useNotificationsSnackbar();
    const { setLoading } = useLoader();
    const commonProps: TextFieldProps = {
        margin: 'dense',
        variant: 'standard'
    };

    const onClick = () => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .intectAuthorize({
                username: intectUsername,
                password: intectPassword
            })
            .then(() => {
                setAlert('Authorized successfully', 'success');
                props.onSaveSuccess();
            })
            .catch(backendErrorHandler('Could not authorize with Intect: '))
            .finally(() => setLoading(false));
    };

    return (
        <Box>
            <FormControl>
                <TextField
                    {...commonProps}
                    label="Username"
                    value={intectUsername}
                    helperText={'Your username in Intect salary system'}
                    onChange={(e) => {
                        setIntectUsername(e.currentTarget.value);
                    }}
                />

                <TextField
                    {...commonProps}
                    label="Password"
                    value={intectPassword}
                    type="password"
                    helperText={'Your password in Intect salary system'}
                    onChange={(e) => {
                        setIntectPassword(e.currentTarget.value);
                    }}
                />

                <Button variant="contained" onClick={onClick}>
                    Authorize with Intect
                </Button>
            </FormControl>
        </Box>
    );
};
