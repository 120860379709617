export const BlueCircle = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="50" height="50" rx="50" fill="#B2DDFF" />
        </svg>
    );
};
