import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { Box, Checkbox, Button } from '@mui/material';
import { TransactionDetailed } from '../../models/transaction';
import {
    formatDateTime,
    toDecimal,
    useFormatting
} from '../../utils/formatting';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../contexts/user/UserContext';

export type TransactionsListProps = {
    transactions: Array<TransactionDetailed>;
    checked: { transactionId: string; transactionStatus: string }[];
    handleToggle?: (transactionId: string, status: any) => () => void;
    handleAllToggle?: () => () => void;
};

const getDateLabel = (transaction: TransactionDetailed) => {
    return formatDateTime(transaction.createdAt);
};

const getPercentageWithdrawedBadge = (percentageWithdrawed: number) => {
    let color: 'success' | 'error' | 'warning';
    switch (true) {
        case percentageWithdrawed > 80:
            color = 'error';
            break;
        case percentageWithdrawed > 50:
            color = 'warning';
            break;
        default:
            color = 'success';
            break;
    }
    return (
        <Button
            fullWidth
            variant="contained"
            size="small"
            color={color}
            title={`The employee has withdrawn ${toDecimal(
                percentageWithdrawed
            )} of the earned salary (this includes pending withdrawals at the time of withdrawal creation)`}
        >
            {toDecimal(percentageWithdrawed)}%
        </Button>
    );
};

export const TransactionsApprovalTable = (props: TransactionsListProps) => {
    const { getBalanceLabel } = useFormatting();
    const { company } = useContext(UserContext);

    const isChecked = (transactionId: string) => {
        return props.checked.some(
            (item) => item.transactionId === transactionId
        );
    };

    return (
        <TableContainer>
            <Table
                sx={{ minWidth: 650, marginBottom: '1rem' }}
                aria-label="simple table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                            <Checkbox
                                onClick={
                                    props.handleAllToggle &&
                                    props.handleAllToggle()
                                }
                                sx={{ marginLeft: '10px', padding: 0 }}
                                edge="start"
                                checked={
                                    props.checked.length ===
                                    props.transactions.length
                                }
                                tabIndex={-1}
                                inputProps={{
                                    'aria-labelledby':
                                        'checkbox-list-secondary-label-all'
                                }}
                            />
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                            Available / withdrawn
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                            Status
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                            Date / time
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                            Amount
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={{ cursor: 'pointer' }}>
                    {props.transactions.map((transaction) => {
                        const labelId = `checkbox-list-secondary-label-${transaction.transactionId}`;
                        return (
                            <TableRow
                                key={transaction.transactionId}
                                onClick={
                                    props.handleToggle &&
                                    props.handleToggle(
                                        transaction.transactionId,
                                        transaction?.status
                                    )
                                }
                            >
                                <TableCell width="1%">
                                    <Checkbox
                                        sx={{ marginLeft: '10px', padding: 0 }}
                                        edge="start"
                                        checked={isChecked(
                                            transaction.transactionId
                                        )}
                                        tabIndex={-1}
                                        inputProps={{
                                            'aria-labelledby': labelId
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ fontWeight: 'bold' }}>
                                        {transaction.user.userName}
                                    </Box>
                                    {transaction.user.email}
                                </TableCell>
                                <TableCell title="These values include pending withdrawals at the time of withdrawal creation">
                                    <Box sx={{ fontWeight: 'bold' }}>
                                        {getBalanceLabel(
                                            transaction.user.balance,
                                            company.currency
                                        )}
                                    </Box>
                                    {getBalanceLabel(
                                        transaction.user.withdrawed,
                                        company.currency
                                    )}
                                </TableCell>
                                <TableCell>{transaction?.status}</TableCell>
                                <TableCell>
                                    {getDateLabel(transaction)}
                                </TableCell>
                                <TableCell>
                                    {getBalanceLabel(
                                        transaction.amount,
                                        transaction.currency
                                    )}
                                </TableCell>
                                <TableCell>
                                    <Link
                                        style={{
                                            textDecoration: 'none',
                                            color: 'black'
                                        }}
                                        to={{
                                            pathname: `/users/${transaction.user.userId}`
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            variant="contained"
                                        >
                                            Earning Details
                                        </Button>
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {getPercentageWithdrawedBadge(
                                        transaction.user.percentageWithdrawed
                                    )}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
