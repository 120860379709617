import { useTranslation } from 'react-i18next';
import {
    PageHeading,
    PageSubHeading,
    SMBold
} from '../../typography/Typography';
import { SearchGrid } from '../../components/SearchGrid';
import {
    Box,
    Divider,
    Stack,
    Tab,
    Table,
    TableBody,
    TableContainer,
    debounce
} from '@mui/material';
import {
    TableCell,
    TableFooter,
    TableFooterCell,
    TableRow
} from '../../components/shared/styles/Table';
import CardHeader from '../../components/shared/CardHeader';
import { ReconciliationTable } from './ReconciliationTable';
import { TableCard } from '../../components/shared/Card';
import { MonthPicker } from '../../components/shared/MonthPicker';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
    PrimaryButton,
    SecondaryButtonRegular
} from '../../components/shared/Button';
import { DATE_FORMAT } from '../../lib/constants';
import dayjs from 'dayjs';
import { useAppDispatch, usePayments } from '../../lib/hooks';
import {
    getReconciliationPdf,
    getReconciliationReport
} from '../../lib/slices/paymentsSlice';
import { useFormatting } from '../../utils/formatting';
import { UserContext } from '../../contexts/user/UserContext';
import * as XLSX from 'sheetjs-style';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { FETCH_STATE } from '../../lib/slices/types';
import { setLoader } from '../../lib/slices/globalLoaderSlice';
import { ContentWrapper } from '../../components/ContentWrapper';
import { Cancelable } from '@mui/utils/debounce';

export const ReconciliationPage = () => {
    const { t } = useTranslation();

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    const dateHeader = useMemo(() => {
        if (selectedDate) {
            return dayjs(selectedDate).format(DATE_FORMAT.MONTH_YEAR);
        }
    }, [selectedDate]);

    const monthName = useMemo(() => {
        if (selectedDate) {
            return dayjs(selectedDate).format(DATE_FORMAT.MONTH_NAME);
        }
    }, [selectedDate]);

    const yearName = useMemo(() => {
        if (selectedDate) {
            return dayjs(selectedDate).format(DATE_FORMAT.YEAR);
        }
    }, [selectedDate]);

    const dispatch = useAppDispatch();
    const { formatDate, getBalanceLabel } = useFormatting();
    const { reconciliationReportState, exportReconciliationPdf } =
        usePayments();
    const [pdfDownloadReady, setPdfDownloadReady] = useState(false);

    useEffect(() => {
        dispatch(
            getReconciliationReport({
                month: dayjs(selectedDate).month() + 1,
                year: dayjs(selectedDate).year()
            })
        );
    }, [selectedDate]);

    useEffect(() => {
        dispatch(
            setLoader(reconciliationReportState?.status === FETCH_STATE.LOADING)
        );
    }, [reconciliationReportState?.status]);

    const hourlyEmployeesStartToEndText =
        dayjs(
            new Date(
                reconciliationReportState?.reconciliationReport
                    ?.reconciliationSummary?.hourlyEmployeesSummary
                    ?.payrollPeriodStart ?? ''
            )
        ).format(DATE_FORMAT.DAY_MONTH) +
        ' - ' +
        dayjs(
            formatDate(
                new Date(
                    reconciliationReportState?.reconciliationReport
                        ?.reconciliationSummary?.hourlyEmployeesSummary
                        ?.payrollPeriodEnd ?? ''
                )
            )
        ).format(DATE_FORMAT.DAY_MONTH);

    const fulltimeEmployeesStartToEndText =
        dayjs(
            formatDate(
                new Date(
                    reconciliationReportState?.reconciliationReport
                        ?.reconciliationSummary?.fullTimeEmployeesSummary
                        ?.payrollPeriodStart ?? ''
                )
            )
        ).format(DATE_FORMAT.DAY_MONTH) +
        ' - ' +
        dayjs(
            formatDate(
                new Date(
                    reconciliationReportState?.reconciliationReport
                        ?.reconciliationSummary?.fullTimeEmployeesSummary
                        ?.payrollPeriodEnd ?? ''
                )
            )
        ).format(DATE_FORMAT.DAY_MONTH);

    const { company } = useContext(UserContext);

    const onPressDownload = () => {
        let hourlyEmployees: any = [];
        let fullTimeEmployees: any = [];

        const sheet = XLSX.utils.aoa_to_sheet([]);

        reconciliationReportState?.reconciliationReport?.hourlyEmployeesReconciliation.reconciliationTransactions.forEach(
            (transaction) => {
                hourlyEmployees.push([
                    t('hourly_employees'),
                    dayjs(transaction.date).format(DATE_FORMAT.DAY_MONTH_YEAR),
                    transaction.description,
                    transaction.status,
                    // transaction.approvedBy,
                    transaction.amount,
                    transaction.sum
                ]);
            }
        );

        reconciliationReportState?.reconciliationReport?.fullTimeEmployeesReconciliation.reconciliationTransactions.forEach(
            (transaction) => {
                fullTimeEmployees.push([
                    t('fulltime_employees'),
                    dayjs(transaction.date).format(DATE_FORMAT.DAY_MONTH_YEAR),
                    transaction.description,
                    transaction.status,
                    //transaction.approvedBy,
                    transaction.amount,
                    transaction.sum
                ]);
            }
        );

        const header = [
            t('employeeType'),
            [t('date')],
            [t('description')],
            [t('status')],
            // [t('approved_by')],
            [t('amount')],
            [t('sum')]
        ];

        XLSX.utils.sheet_add_aoa(sheet, [
            [monthName, yearName],
            [''],
            [t('hourly_employees'), hourlyEmployeesStartToEndText],
            header,
            ...hourlyEmployees,
            [
                t('total'),
                '',
                '',
                '',
                reconciliationReportState?.reconciliationReport
                    ?.reconciliationSummary?.hourlyEmployeesSummary?.total,
                ''
            ],
            [''],
            [''],
            [t('fulltime_employees'), fulltimeEmployeesStartToEndText],
            header,
            ...fullTimeEmployees,
            [
                t('total'),
                '',
                '',
                '',
                reconciliationReportState?.reconciliationReport
                    ?.reconciliationSummary?.fullTimeEmployeesSummary?.total,
                ''
            ],
            [''],
            [''],
            [
                t('total_for_month', { month: monthName }),
                '',
                '',
                '',
                reconciliationReportState?.reconciliationReport
                    ?.reconciliationSummary?.monthTotal,
                ''
            ]
        ]);

        const workbook = XLSX.utils.book_new();

        const boldStyle = { font: { bold: true } };

        const hourlyTotalOffset = hourlyEmployees.length + 5;
        const fulltimeHeaderOffset = hourlyTotalOffset + 4;
        const fulltimeTotalOffset =
            hourlyTotalOffset + fullTimeEmployees.length + 5;
        const totalOffset = fulltimeTotalOffset + 3;

        let headers = [
            'A4',
            'B4',
            'C4',
            'D4',
            'E4',
            'F4',
            'G4',

            'A' + fulltimeHeaderOffset,
            'B' + fulltimeHeaderOffset,
            'C' + fulltimeHeaderOffset,
            'D' + fulltimeHeaderOffset,
            'E' + fulltimeHeaderOffset,
            'F' + fulltimeHeaderOffset,
            'G' + fulltimeHeaderOffset,

            'A' + hourlyTotalOffset,
            'E' + hourlyTotalOffset,
            'A' + fulltimeTotalOffset,
            'E' + fulltimeTotalOffset,
            'A' + totalOffset,
            'E' + totalOffset
        ];

        headers.forEach((el) => {
            if (sheet[el]) sheet[el].s = boldStyle;
        });

        const wscols = [{ wch: 20 }, { wch: 12 }, { wch: 30 }, { wch: 20 }];

        // Set the column widths
        sheet['!cols'] = wscols;

        XLSX.utils.book_append_sheet(workbook, sheet, 'Report');

        XLSX.writeFile(
            workbook,
            `Reconciliation report ${dayjs(selectedDate).format(
                DATE_FORMAT.MONTH_YEAR
            )}.xlsx`
        );
    };

    const handleDownloadPdf = async () => {
        try {
            await dispatch(
                getReconciliationPdf({
                    month: dayjs(selectedDate).month() + 1,
                    year: dayjs(selectedDate).year()
                })
            );
            setPdfDownloadReady(true);
        } catch (error) {
            console.error('Error downloading reconciliation:', error);
        }
    };

    useEffect(() => {
        if (pdfDownloadReady && exportReconciliationPdf?.data?.fileUrl) {
            const anchor = document.createElement('a');
            anchor.href = exportReconciliationPdf?.data?.fileUrl;
            anchor.click();
            setPdfDownloadReady(false);
        }
    }, [exportReconciliationPdf, pdfDownloadReady]);

    return (
        <ContentWrapper>
            <div>
                <PageHeading>Reconciliation reports</PageHeading>
                <PageSubHeading>
                    Get an overview of the reconciliation reports within your
                    organization
                </PageSubHeading>

                <SearchGrid container justifyContent={'space-between'}>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        gap={1}
                        justifyContent={'space-between'}
                    >
                        <div>
                            <SMBold noWrap={true}>{t('select_month')}</SMBold>
                        </div>

                        <MonthPicker
                            label={'Select month'}
                            value={selectedDate}
                            onChange={setSelectedDate}
                        />
                    </Stack>
                </SearchGrid>

                <Box m={4}></Box>

                <TableCard>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        mr={2}
                    >
                        <CardHeader
                            label={t('total_for_month', { month: dateHeader })}
                        ></CardHeader>
                        <span style={{ display: 'flex', gap: '8px' }}>
                            <SecondaryButtonRegular
                                onClick={onPressDownload}
                                size="small"
                                sx={{ borderRadius: '5px' }}
                            >
                                {t('download_as_excel')}
                            </SecondaryButtonRegular>
                            <PrimaryButton
                                onClick={handleDownloadPdf}
                                size="small"
                            >
                                {t('download_as_pdf')}
                            </PrimaryButton>
                        </span>
                    </Stack>
                    <Divider style={{ width: '100%' }} />
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell width={'390px'}>
                                        {hourlyEmployeesStartToEndText}
                                    </TableCell>
                                    <TableCell align="left">
                                        {t(
                                            'total_hourly_employee_transactions'
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        {getBalanceLabel(
                                            reconciliationReportState
                                                ?.reconciliationReport
                                                ?.reconciliationSummary
                                                ?.hourlyEmployeesSummary?.total,
                                            company.currency
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {fulltimeEmployeesStartToEndText}
                                    </TableCell>
                                    <TableCell align="left">
                                        {t(
                                            'total_fulltime_employee_transactions'
                                        )}
                                    </TableCell>
                                    <TableCell align="right">
                                        {getBalanceLabel(
                                            reconciliationReportState
                                                ?.reconciliationReport
                                                ?.reconciliationSummary
                                                ?.fullTimeEmployeesSummary
                                                ?.total,
                                            company.currency
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>

                            <TableFooter>
                                {' '}
                                {/* make table footer */}
                                <TableRow>
                                    <TableFooterCell>
                                        <SMBold>
                                            {t('total_for_month', {
                                                month: monthName
                                            })}
                                        </SMBold>
                                    </TableFooterCell>
                                    <TableFooterCell></TableFooterCell>
                                    <TableFooterCell align="right">
                                        <SMBold>
                                            {getBalanceLabel(
                                                reconciliationReportState
                                                    .reconciliationReport
                                                    ?.reconciliationSummary
                                                    .monthTotal,
                                                company.currency
                                            )}
                                        </SMBold>
                                    </TableFooterCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </TableCard>

                <Box m={4}></Box>

                <ReconciliationTable
                    items={
                        reconciliationReportState?.reconciliationReport
                            ?.hourlyEmployeesReconciliation
                            .reconciliationTransactions ?? []
                    }
                    label={t('hourly_employees')}
                    chipText={hourlyEmployeesStartToEndText}
                    totalAmount={getBalanceLabel(
                        reconciliationReportState?.reconciliationReport
                            ?.hourlyEmployeesReconciliation.total,
                        company.currency
                    )}
                ></ReconciliationTable>

                <Box m={4}></Box>

                <ReconciliationTable
                    items={
                        reconciliationReportState?.reconciliationReport
                            ?.fullTimeEmployeesReconciliation
                            .reconciliationTransactions ?? []
                    }
                    totalAmount={getBalanceLabel(
                        reconciliationReportState?.reconciliationReport
                            ?.fullTimeEmployeesReconciliation.total,
                        company.currency
                    )}
                    chipText={fulltimeEmployeesStartToEndText}
                    label={t('fulltime_employees')}
                ></ReconciliationTable>
            </div>
        </ContentWrapper>
    );
};
