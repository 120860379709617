import { DialogTitle, DialogActions, useTheme } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLoader } from '../../../contexts/loader/LoaderContext';
import {
    PaymentsUser,
    UpdateUserBankAccountDetailsRequest
} from '../../../models/payment';
import { Validator, useErrorHandling } from '../../../utils/errorHandling';
import PaymentsAPIServiceV2 from '../../../services/PaymentsServiceV2';
import {
    StyledDialog,
    StyledDialogContainer,
    StyledDialogTitle
} from '../../../components/consent/StyledDialog';
import { SemiBold, SMBold, SMRegular } from '../../../typography/Typography';
import { useTranslation } from 'react-i18next';
import { InputText } from '../../../components/shared/InputText';
import {
    OutlinedSecondaryButton,
    PrimaryButton
} from '../../../components/shared/Button';

type UpdateBankAccountDialogProps = {
    userId?: string;
    open: boolean;
    validator: Validator;
    setValidator: React.Dispatch<React.SetStateAction<Validator>>;
    onClose: () => void;
    onSubmit: (request: UpdateUserBankAccountDetailsRequest) => Promise<void>;
};

export const UpdateBankAccountDialog = (
    props: UpdateBankAccountDialogProps
) => {
    const [bankCode, setBankCode] = useState<string>('');
    const [bankAccountNumber, setBankAccountNumber] = useState<string>('');

    const [isNemKonto, setIsNemKonto] = useState<boolean>(false);
    const [user, setUser] = useState<PaymentsUser>();
    const { setLoading } = useLoader();

    const { backendErrorHandler } = useErrorHandling();

    const onSubmit = () => {
        if (!props.userId) return;

        props.onSubmit({
            bankCode: bankCode,
            bankAccountNumber: bankAccountNumber,
            userId: props.userId
        });
    };

    useEffect(() => {
        if (!props.userId) return;

        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getUser(props.userId)
            .then((response: any) => {
                let responseUser = response.data as PaymentsUser;
                setUser(responseUser);
                setBankCode(responseUser.bankCode);
                setBankAccountNumber(responseUser.bankAccountNumber);
                setIsNemKonto(responseUser.isNemkonto);
            })
            .catch(backendErrorHandler('Error fetching users: '))
            .finally(() => {
                setLoading(false);
            });
    }, [props.userId, backendErrorHandler, setLoading]);

    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <StyledDialog open={props.open} onClose={props.onClose}>
            <StyledDialogContainer style={{ width: '400px' }}>
                <StyledDialogTitle>
                    <div className="col align-center">
                        <SMBold fontSize={'14px'}>
                            {t('edit_bank_details')}
                        </SMBold>

                        <p style={{ marginBottom: 0 }} className="text-center">
                            <SemiBold
                                className="inline"
                                color={theme.palette.text.disabled}
                            >
                                {user?.email}
                            </SemiBold>
                        </p>
                    </div>
                </StyledDialogTitle>
                {!isNemKonto && (
                    <>
                        {' '}
                        <div>
                            <InputText
                                value={bankCode}
                                name="bank_code"
                                label="Bank code"
                                onChange={(val) => {
                                    setBankCode(val.target.value);
                                    props.setValidator(
                                        props.validator.clearError('bankCode')
                                    );
                                }}
                                spacing="10px 0"
                            />

                            <InputText
                                value={bankAccountNumber}
                                name="bank_account_number"
                                label="Bank account number"
                                onChange={(val) => {
                                    setBankAccountNumber(val.target.value);
                                    props.setValidator(
                                        props.validator.clearError(
                                            'bankAccountNumber'
                                        )
                                    );
                                }}
                                spacing="10px 0"
                            />

                            <SMRegular
                                color={theme.palette.text.disabled}
                                textAlign={'center'}
                                marginY={2}
                            >
                                If you update the bank account details manually,
                                they will no longer be updated during import
                                from salary provider.
                            </SMRegular>
                        </div>
                        <DialogActions
                            sx={{
                                justifyContent: 'center',
                                gap: 1,
                                paddingX: 0,
                                paddingTop: '12px'
                            }}
                        >
                            <OutlinedSecondaryButton
                                fullWidth
                                color="secondary"
                                style={{
                                    height: '44px',
                                    borderRadius: '8px'
                                }}
                                onClick={props.onClose}
                            >
                                <SMBold textTransform={'none'}>
                                    {t('Cancel')}
                                </SMBold>
                            </OutlinedSecondaryButton>

                            <PrimaryButton
                                fullWidth
                                size="large"
                                style={{ height: '44px' }}
                                onClick={onSubmit}
                            >
                                Save
                            </PrimaryButton>
                        </DialogActions>{' '}
                    </>
                )}

                {isNemKonto && (
                    <>
                        <div>
                            <InputText
                                value={'NemKonto'}
                                name="bank_account_number"
                                label="Account details"
                                disabled
                                spacing="10px 0"
                            />
                        </div>
                        <DialogActions
                            sx={{
                                justifyContent: 'center',
                                gap: 1,
                                paddingX: 0,
                                paddingTop: '12px'
                            }}
                        >
                            <PrimaryButton
                                fullWidth
                                size="large"
                                style={{ height: '44px' }}
                                onClick={props.onClose}
                            >
                                Close
                            </PrimaryButton>
                        </DialogActions>{' '}
                    </>
                )}
            </StyledDialogContainer>
        </StyledDialog>
    );
};
