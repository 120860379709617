import { useEffect, useState, useCallback } from 'react';
import { useErrorHandling } from '../../utils/errorHandling';
import { BalanceDetails } from '@instapaid/shared-logic';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useTranslation } from 'react-i18next';

export interface Balance {
    balance: number;
    details: BalanceDetails;
    error: string | null;
    missingTaxCard: boolean;
}

export const useBalance = (userId: string) => {
    const [balance, setBalance] = useState<Balance>();

    const { backendErrorHandler } = useErrorHandling();
    const { setAlert } = useNotificationsSnackbar();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const getBalance = useCallback(() => {
        setIsLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getUserBalance(userId)
            .then((response: any) => {
                setBalance(response.data);
                if (response.data.missingTaxCard) {
                    setAlert(t('user_missing_tax_card'), 'error');
                }
            })
            .catch(backendErrorHandler('Error fetching balance: '))
            .finally(() => {
                setIsLoading(false);
            });
    }, [backendErrorHandler]);

    useEffect(() => {
        getBalance();
    }, []);

    return {
        balance,
        isLoading
    } as const;
};
