import { ContentWrapper } from '../components/ContentWrapper';
import { Typography } from '@mui/material';
import { useUser } from '../contexts/user/UserContext';
import { getImpersonatedUserId } from '../services/impersonationService';
import { useEffect } from 'react';
import PaymentsAPIServiceV2 from '../services/PaymentsServiceV2';

export function Home() {
    const { company, user } = useUser();
    const isImpersonating = getImpersonatedUserId() !== null;

    useEffect(() => {
        PaymentsAPIServiceV2.getInstance()
            .getUserCompanies()
            .then((response) => {
                console.log(response);
            });
    }, []);

    return (
        <ContentWrapper>
            <>
                {/* <Button color="primary" variant="contained">
                    hi
                </Button> */}
                <Typography variant="h5">{company.name}</Typography>
                Welcome to InstaPaid backoffice, {user.firstName}{' '}
                {user.lastName}!
                <br />
                {isImpersonating ? (
                    <>You are impersonating {user.email} account.</>
                ) : (
                    <>You are logged in with {user.email} account.</>
                )}
            </>
        </ContentWrapper>
    );
}
