import { JobDetailsResponse } from '../../../models/payment';
import { Alert, Box, Container, Typography } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { formatDateTime } from '../../../utils/formatting';

export type ImportJobDetailsProps = {
    jobDetails: JobDetailsResponse;
};

export const ImportJobDetails = (props: ImportJobDetailsProps) => {
    return (
        <Box>
            <p>
                Import status: <b>{props.jobDetails.jobStatus}</b>
            </p>
            <p>
                Import progress: <b>{props.jobDetails.progress}%</b>
            </p>

            <Typography marginTop="1rem" variant="h5">
                Import results:
            </Typography>
            <TableContainer sx={{ marginBottom: 2 }}>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Skipped users
                            </TableCell>
                            <TableCell align="right">
                                {props.jobDetails.skippedUsersCount}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                New users
                            </TableCell>
                            <TableCell align="right">
                                {props.jobDetails.newUsersCount}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Updated users
                            </TableCell>
                            <TableCell align="right">
                                {props.jobDetails.updatedUsersCount}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Deleted users
                            </TableCell>
                            <TableCell align="right">
                                {props.jobDetails.deletedUsersCount}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Total users processed
                            </TableCell>
                            <TableCell align="right">
                                {props.jobDetails.totalItems}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row">
                                Created at
                            </TableCell>
                            <TableCell align="right">
                                {formatDateTime(props.jobDetails.createdAt)}
                            </TableCell>
                        </TableRow>
                        {(props.jobDetails.jobStatus === 'Failed' ||
                            props.jobDetails.jobStatus === 'Succeded' ||
                            props.jobDetails.jobStatus === 'Cancelled') && (
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Finished at
                                </TableCell>
                                <TableCell align="right">
                                    {formatDateTime(
                                        props.jobDetails.finishedAt
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {props.jobDetails.errors.length > 0 && (
                <>
                    <Typography marginTop="1rem" variant="h5">
                        Errors
                    </Typography>
                    We've encountered the following <strong>errors</strong>{' '}
                    during import. The users with errors were skipped. You can
                    fix them and try to import these users again.
                    <Container
                        style={{
                            maxHeight: 400,
                            overflow: 'auto',
                            margin: '1rem 0'
                        }}
                    >
                        {props.jobDetails.errors.map(
                            (error: string, index: number) => (
                                <Alert
                                    sx={{ marginTop: '0.5rem' }}
                                    key={`error${index}`}
                                    severity="error"
                                >
                                    {error}
                                </Alert>
                            )
                        )}
                    </Container>
                </>
            )}

            {props.jobDetails.warnings.length > 0 && (
                <>
                    <Typography marginTop="1rem" variant="h5">
                        Validation warnings
                    </Typography>
                    We've encountered the following{' '}
                    <strong>validation warnings</strong> during import. The
                    users with warnings were imported. You can fix the warnings
                    and import users again to pull the fixes.
                    <Container
                        style={{
                            maxHeight: 400,
                            overflow: 'auto',
                            margin: '1rem 0'
                        }}
                    >
                        {props.jobDetails.warnings.map(
                            (warning: string, index: number) => (
                                <Alert
                                    sx={{ marginTop: '0.5rem' }}
                                    key={`warning${index}`}
                                    severity="warning"
                                >
                                    {warning}
                                </Alert>
                            )
                        )}
                    </Container>
                </>
            )}
        </Box>
    );
};
