import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const toDecimal = (number?: number, digits: number = 2) => {
    return number !== undefined ? formatNumber(number, digits) : number;
};

export const formatNumber = (number: number, digits: number = 2) => {
    return (Math.round(number * 100) / 100).toFixed(digits);
};

export const formatDateTime = (dateTime: Date | undefined) => {
    return dateTime
        ? dateTime.toDateString() +
              ' ' +
              dateTime.toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit'
              })
        : '';
};

export const formatDateTimeToTime = (dateTime: Date | undefined | null) => {
    return dateTime
        ? new Date(dateTime).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit'
          })
        : '';
};

export const formatDate = (dateTime: Date | undefined | null) => {
    return dateTime ? dateTime.toDateString() : '';
};

export const convertStringInputToNumber = (value: string | null) => {
    let decimalValue: number | null = 0;
    if (value === '' || value === null) {
        decimalValue = null;
    } else {
        if (Number.isNaN(Number(value))) {
            return;
        }
        decimalValue = Number(value);
    }
    return decimalValue;
};

export const getCurrencySymbol = (currency: string) => {
    switch (currency) {
        case 'DKK':
            return 'kr.';
        default:
            return 'kr.';
    }
};

export const hoursOrMinutes = (hours: number) => {
    if (hours >= 1) {
        return toDecimal(hours, 1) + ' hours';
    } else {
        return toDecimal(60 * hours, 0) + ' minutes';
    }
};

export enum DateFormats {
    DATE_WITH_SHORT_MONTH = 'MMMM DD, YYYY'
}

export const useFormatting = () => {
    const { i18n } = useTranslation();

    const formatNumber = (number: number, digits: number = 2) => {
        const locale = i18n.resolvedLanguage;
        const settings = {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits
        };
        return number.toLocaleString(locale, settings);
    };

    const toDecimal = (number?: number, digits: number = 0) => {
        if (number !== undefined && number != null) {
            return formatNumber(number, digits);
        } else {
            return number;
        }
    };

    const formatDate = useCallback(
        (
            date: Date | undefined,
            year: 'numeric' | '2-digit' | null = 'numeric',
            showWeekday: boolean = true
        ) => {
            const locale = i18n.resolvedLanguage;
            let options: Intl.DateTimeFormatOptions = {
                weekday: showWeekday ? 'short' : undefined,
                month: 'short',
                day: 'numeric'
            };
            if (year !== null) {
                options.year = year;
            }
            return date?.toLocaleDateString(locale, options);
        },
        [i18n.resolvedLanguage]
    );

    const formatShortDate = useCallback(
        (
            date: Date | undefined,
            year: 'numeric' | '2-digit' | null = 'numeric'
        ) => {
            const locale = i18n.resolvedLanguage;
            let options: Intl.DateTimeFormatOptions = {
                month: 'short',
                day: 'numeric'
            };
            if (year !== null) {
                options.year = year;
            }
            return date?.toLocaleDateString(locale, options);
        },
        [i18n.resolvedLanguage]
    );

    const getCurrencyLabel = (currency: string) => {
        switch (currency) {
            case 'DKK':
                return 'kr.';
            default:
                return currency;
        }
    };

    interface TwithDate {
        createdAt: string;
    }

    const getWeekLabel = (date: Date) => {
        const firstDayNumber = date.getUTCDate() - date.getDay();
        const lastDayNumber = firstDayNumber + 6;

        let firstWeekDay = new Date(date.getTime());
        firstWeekDay.setUTCDate(firstDayNumber);

        let lastWeekDay = new Date(date.getTime());
        lastWeekDay.setUTCDate(lastDayNumber);

        return `${formatDate(firstWeekDay)} - ${formatDate(lastWeekDay)}`;
    };

    interface GroupedArray<T> {
        label: string;
        data: T[];
    }

    const groupByWeek = <T extends TwithDate>(
        transactions: T[]
    ): GroupedArray<T>[] => {
        type Grouped = { [label: string]: Array<T> };
        let byweek: Grouped = {};
        transactions.forEach((transaction) => {
            const label = getWeekLabel(new Date(transaction.createdAt));
            byweek[label] = byweek[label] || [];
            byweek[label].push(transaction);
        });

        let byWeekArr: GroupedArray<T>[] = [];
        Object.keys(byweek).forEach((item) => {
            byWeekArr.push({ label: item, data: byweek[item] });
        });
        return byWeekArr;
    };

    const getBalanceLabel = (
        value: number | undefined = 0,
        currency?: string,
        negative = false,
        allowNegative = false
    ) => {
        const balanceDisplayAmount =
            value && value > 0 ? value : allowNegative ? value : 0;

        const negativeSign = negative && value > 0 ? '-' : '';

        let formattedBalance = new Intl.NumberFormat('da-DK', {
            style: 'currency',
            currency,
            minimumFractionDigits: 2
        }).format(balanceDisplayAmount);

        formattedBalance = formattedBalance.replace('kr.', 'DKK');

        return `${negativeSign}${formattedBalance}`;
    };

    return {
        toDecimal,
        getCurrencyLabel,
        formatDate,
        groupByWeek,
        formatShortDate,
        getBalanceLabel
    };
};
