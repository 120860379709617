import styled from '@emotion/styled';
import {
    IconButton,
    MenuItem,
    Typography,
    useTheme,
    InputAdornment
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface DropDownChoices {
    label: string;
    value: any;
}

export enum DropdownType {
    Single,
    Multi,
    Action,
    SingleShowTitle
}

interface DropdownProps {
    label: string;
    choices?: DropDownChoices[] | { value: number; label: string }[];
    value: any; //TODO:change later to <T>
    onChange: (value: any | any[]) => void;
    error?: boolean;
    type?: DropdownType;
    direction?: 'up' | 'down';
    selectedOnInit?: any | undefined;
    style?: React.CSSProperties;
    listContainerStyle?: React.CSSProperties;
    startAdornment?: any;
    isDisabled?: boolean;
}

const DropdownWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding: 10px 14px 10px 14px;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    width: 100%;
    height: 36px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px #1018280d;
    justify-content: space-between;
    position: relative;
    margin: 3px 0;

    &:hover {
        cursor: pointer;
        background: #f8f8f8;
    }
`;

const DropdownLabelContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
`;

const LabelSection = styled.div`
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    vertical-align: middle;
`;

const DropdownListContainer = styled.div`
    width: 100%;
    display: flex;
    position: absolute;
    flex-direction: column;
    left: 0px;
    top: 36px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 1px 1px 2px 0px #1018280d;
    z-index: 1000;
    max-height: 200px;
    overflow: auto;
`;

const DropUpListContainer = styled.div`
    width: 100%;
    display: flex;
    position: absolute;
    flex-direction: column;
    left: 0px;
    bottom: 36px;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 1px 1px 2px 0px #1018280d;
    z-index: 1000;
    max-height: 200px;
    overflow: auto;
`;

const ExpandButton = styled(IconButton)`
    padding: 8px 0px;
    position: absolute;
    right: 10px;
`;

const StyledMenuItem = styled(MenuItem)`
    &:hover {
        background: ${(props) => props.theme.palette.lightSuccessColor}};
    }
`;

function useOutsideAlerter(ref: any, onClickOutside: () => void) {
    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                console.log('clicked out');
                onClickOutside();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

export const Dropdown = ({
    label,
    choices,
    value,
    error,
    type = DropdownType.Single,
    onChange,
    direction = 'down',
    selectedOnInit,
    style,
    listContainerStyle,
    startAdornment,
    isDisabled = false
}: DropdownProps) => {
    const theme = useTheme();

    const wrapperRef = useRef(null);

    const [showOptions, setShowOptions] = useState(false);

    const onClickOutside = () => {
        setShowOptions(false);
    };
    useOutsideAlerter(wrapperRef, onClickOutside);

    const [selected, setSelected] = useState<DropDownChoices[] | null>(null);

    useEffect(() => {
        if (value?.length == 0) {
            setSelected(null);
            return;
        }

        if (type === DropdownType.SingleShowTitle) {
            const found = choices?.find((x) => value == x.value);
            if (found) {
                setSelected([found]);
            }
        }

        const found = choices?.filter((x) => value?.includes?.(x.value));

        if (found && found?.length > 0) {
            setSelected(found);
        }
    }, [value, choices]);

    useEffect(() => {
        if (selectedOnInit && !selected) {
            setSelected([selectedOnInit]);
        }
    }, [selectedOnInit]);

    const select = useCallback(
        (event: any, choice: DropDownChoices) => {
            setShowOptions(false);
            if (
                type === DropdownType.Single ||
                type === DropdownType.SingleShowTitle
            ) {
                if (selected?.[0]?.value == choice.value) {
                    setSelected(null);
                    onChange(null);
                    return;
                } else {
                    setSelected([choice]);
                    onChange(choice.value);
                }
            }

            if (type === DropdownType.Multi) {
                setSelected((prev) => {
                    if (prev) {
                        if (prev.find((x) => x.value == choice.value)) {
                            let onlySelected = prev.filter(
                                (x) => x.value != choice.value
                            );
                            onChange(onlySelected.map((x) => x.value));
                            return onlySelected;
                        }
                        onChange([...prev, choice].map((x) => x.value));
                        return [...prev, choice];
                    } else {
                        onChange([choice.value]);
                        return [choice];
                    }
                });
            }

            if (type === DropdownType.Action) {
                onChange(choice.value);
            }
        },
        [selected, onChange]
    );

    const renderLabel = useMemo(() => {
        if (type === DropdownType.Single) {
            return selected?.[0].label ?? label;
        }

        if (type === DropdownType.Multi) {
            let _label = selected?.map((x) => x.label).join(', ') ?? label;
            return _label == '' ? label : _label;
        }

        if (type === DropdownType.SingleShowTitle) {
            return label + ' ' + selected?.[0].label;
        }

        return label;
    }, [label, type, selected]);

    const list = choices?.map((choice) => {
        return (
            <StyledMenuItem
                style={{
                    backgroundColor: selected?.find(
                        (x) => x.value == choice.value
                    )
                        ? theme.palette.lightSuccessColor
                        : 'none'
                }}
                onClick={(e) => {
                    select(e, choice);
                }}
                key={choice.value}
                value={choice.value}
            >
                {choice.label}
            </StyledMenuItem>
        );
    });

    const renderDropDown = () => {
        return (
            <DropdownListContainer ref={wrapperRef} style={listContainerStyle}>
                {list}
            </DropdownListContainer>
        );
    };

    const renderDropUp = () => {
        return (
            <DropUpListContainer ref={wrapperRef} style={listContainerStyle}>
                {list}
            </DropUpListContainer>
        );
    };

    return (
        <DropdownWrapper
            style={style}
            onClick={() => {
                if (!isDisabled) {
                    setShowOptions(!showOptions);
                }
            }}
        >
            {showOptions && direction == 'up' && renderDropUp()}
            <DropdownLabelContainer>
                <LabelSection>
                    {startAdornment && (
                        <InputAdornment position="start">
                            {startAdornment}
                        </InputAdornment>
                    )}
                    <Typography
                        fontSize={'14px'}
                        lineHeight={'20px'}
                        fontWeight={600}
                        noWrap
                        color={
                            error
                                ? theme.palette.error.main
                                : theme.palette.text.primary
                        }
                        style={listContainerStyle}
                    >
                        {renderLabel}
                    </Typography>
                </LabelSection>
                {direction == 'down' && (
                    <ExpandButton disableRipple={true}>
                        <ExpandMoreIcon />
                    </ExpandButton>
                )}
            </DropdownLabelContainer>

            {showOptions && direction == 'down' && renderDropDown()}
        </DropdownWrapper>
    );
};
