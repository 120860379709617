import { SerializedError } from '@reduxjs/toolkit';

export enum FETCH_STATE {
    IDLE = 'idle',
    LOADING = 'loading',
    SUCCEEDED = 'succeeded',
    FAILED = 'failed'
}

export interface BaseAPIState {
    /** @deprecated isLoading is deprecated. use status */
    isLoading?: boolean;
    status: FETCH_STATE;
    error?: any;
}

export interface InstaPaidError extends SerializedError {}
