import {
    Button,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Stack,
    Table,
    TableBody,
    TableContainer
} from '@mui/material';
import { ContentWrapper } from '../../components/ContentWrapper';
import CardHeader from '../../components/shared/CardHeader';
import Chip, { chipColors } from '../../components/shared/Chip';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { SMBold } from '../../typography/Typography';
import { DATE_FORMAT } from '../../lib/constants';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
    TableCell,
    TableCellFirstCell,
    TableCellNoPadding,
    TableFooter,
    TableFooterCell,
    TableHeader,
    TableHeaderCell,
    TableHeaderCellFirstCell,
    TableRow
} from '../../components/shared/styles/Table';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useAdminCompanies, useAppDispatch } from '../../lib/hooks';
import { AddCompanyForm } from './AddCompanyForm';
import { TableCard } from '../../components/shared/Card';
import { useNavigate } from 'react-router-dom';
import {
    setCompanyIdInLocalStorage,
    setCompanyIdInLocalStorageWithoutReload
} from '../../services/companyService';
import { useFormatting } from '../../utils/formatting';
import { useUser } from '../../contexts/user/UserContext';
import { useCallback, useMemo, useState } from 'react';
import { UserRoles } from '../../components/sidebar/MenuTree';
import { CompanyWithAdmins } from '../../models/company';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';
import { Validator, useErrorHandling } from '../../utils/errorHandling';
import { DeleteCompanyRequest } from '../../models/payment';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { DeleteCompanyDialog } from './DeleteCompanyDialog';
import { setLoader } from '../../lib/slices/globalLoaderSlice';
import {
    AdminCompany,
    getAdminCompanies
} from '../../lib/slices/paymentsSlice';
import { TableSortLabel } from '../../components/shared/styles/Table';

type SortingType = 'asc' | 'desc' | undefined;

export const TableCellNoPaddingWithFontWeight = ({
    fontWeight,
    children
}: {
    fontWeight: any;
    children: any;
}) => {
    return (
        <TableCellNoPadding style={{ fontWeight }} align="right">
            {children}
        </TableCellNoPadding>
    );
};

export const CompaniesPageV2 = () => {
    const { t } = useTranslation();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteDialogCompany, setDeleteDialogCompany] =
        useState<AdminCompany>();

    const { companies } = useAdminCompanies();
    const { user } = useUser();

    const navigate = useNavigate();
    const { getBalanceLabel, toDecimal } = useFormatting();

    const handleCompanySelect = (companyId: string) => {
        navigate(`/?changeCompanyId=${companyId}`);
    };
    const { setAlert } = useNotificationsSnackbar();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const { backendErrorHandler } = useErrorHandling();
    const dispatch = useAppDispatch();

    const [nameSortDirection, setNameSortDirection] =
        useState<SortingType>(undefined);

    const [rowCompany, setRowCompany] = useState<AdminCompany>();

    const onDeleteIconClick = (company: AdminCompany) => {
        handleMenuClose();
        dispatch(setLoader(true));
        PaymentsAPIServiceV2.getInstance()
            .sendDeleteCompanyVerificationEmail({
                companyId: company.companyId
            })
            .then(() => {
                setDeleteDialogOpen(true);
                setDeleteDialogCompany(company);
            })
            .catch(
                backendErrorHandler(
                    'Could not send deletion verification code.'
                )
            )
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const [validator, setValidator] = useState(new Validator());

    const sortedCompanies = [...companies].sort((a, b) =>
        a.name.localeCompare(b.name)
    );

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const onDeleteDialogSubmit = async (
        request: DeleteCompanyRequest
    ): Promise<void> => {
        dispatch(setLoader(true));
        handleMenuClose();
        PaymentsAPIServiceV2.getInstance()
            .deleteCompany(request)
            .then(() => {
                setAlert('Company deleted successfully', 'success');
                setDeleteDialogOpen(false);
                dispatch(getAdminCompanies());
            })
            .catch(
                backendErrorHandler(
                    'Error deleting company: ',
                    (e, errorDetails) => {
                        setValidator(new Validator(errorDetails?.errors));
                    }
                )
            )
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

    const onDeleteDialogCancel = () => {
        setDeleteDialogOpen(false);
        setDeleteDialogCompany(undefined);
        setValidator(new Validator());
    };

    const totals = useMemo(() => {
        let employees = 0;
        let withdrawed = 0;
        let pendingTransactions = 0;
        let finalizedTransactions = 0;
        let balance = 0;

        sortedCompanies.forEach((company) => {
            employees += company.usersCount;
            withdrawed += company.withdrawed;
            pendingTransactions += company.pendingTransactionsCount;
            finalizedTransactions += company.finalizedTransactionsCount;
            balance += company.balance;
        });

        return {
            employees,
            withdrawed,
            pendingTransactions,
            finalizedTransactions,
            balance
        };
    }, [sortedCompanies]);

    const handleMenuClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        company: AdminCompany
    ) => {
        setAnchorEl(event.currentTarget);
        setRowCompany(company);
    };

    const sortedUserList = useMemo(() => {
        let list = [...sortedCompanies];

        const sortArr = [
            {
                key: 'name',
                direction: nameSortDirection
            }
        ];

        sortArr.forEach((sortDirection) => {
            if (sortDirection.direction) {
                list.sort((a: any, b: any) => {
                    if (a[sortDirection.key] && b[sortDirection.key]) {
                        return (
                            a[sortDirection.key]!.toString().localeCompare(
                                b[sortDirection.key!]!.toString()
                            ) * (sortDirection.direction === 'asc' ? 1 : -1)
                        );
                    }
                    return 0;
                });
            }
        });

        return list;
    }, [sortedCompanies, nameSortDirection]);

    const handleSort = useCallback(
        (key: SortingType, func: Function) => {
            setNameSortDirection(undefined);

            key === 'asc' ? func('desc') : func('asc');
        },
        [sortedCompanies, nameSortDirection]
    );

    const onLoadCompanies = () => {
        dispatch(getAdminCompanies());
    };

    return (
        <ContentWrapper showImpersonationBanner={false}>
            <>
                <TableCard>
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        mr={2}
                    >
                        <CardHeader
                            label={t('company_status')}
                            chip={
                                <Chip
                                    icon={<></>}
                                    label={
                                        <SMBold color={chipColors.success.text}>
                                            {dayjs().format(
                                                DATE_FORMAT.MONTH_NAME
                                            )}
                                        </SMBold>
                                    }
                                    color={'success'}
                                />
                            }
                        />
                        <AddCompanyForm onAddSuccess={onLoadCompanies} />
                    </Stack>

                    <TableContainer component={Paper}>
                        <Table aria-label="shifts table">
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCellFirstCell>
                                        <TableSortLabel
                                            direction={nameSortDirection}
                                            onClick={() =>
                                                handleSort(
                                                    nameSortDirection,
                                                    setNameSortDirection
                                                )
                                            }
                                        >
                                            {t('alias')}
                                        </TableSortLabel>
                                    </TableHeaderCellFirstCell>
                                    <TableHeaderCell align="right">
                                        {t('employees')}
                                    </TableHeaderCell>
                                    <TableHeaderCell align="right">
                                        {t('withdrawed')}
                                    </TableHeaderCell>
                                    <TableHeaderCell align="right">
                                        {t('transactions')}
                                    </TableHeaderCell>
                                    <TableHeaderCell align="right">
                                        {t('balance')}
                                    </TableHeaderCell>
                                    <TableHeaderCell
                                        width={'40px'}
                                    ></TableHeaderCell>
                                    {user?.role === UserRoles.SuperAdmin && (
                                        <TableHeaderCell
                                            width={'40px'}
                                        ></TableHeaderCell>
                                    )}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {sortedUserList?.map((row) => (
                                    <TableRow key={row.companyId}>
                                        <TableCellFirstCell>
                                            {row.name}
                                        </TableCellFirstCell>
                                        <TableCell align="right">
                                            {row.usersCount}
                                        </TableCell>
                                        <TableCell align="right">
                                            {getBalanceLabel(
                                                row.withdrawed,
                                                row.currency
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            <SMBold>
                                                <span
                                                    style={{
                                                        color:
                                                            row.pendingTransactionsCount >
                                                            row.finalizedTransactionsCount
                                                                ? 'danger'
                                                                : 'inherit'
                                                    }}
                                                >
                                                    {
                                                        row.pendingTransactionsCount
                                                    }
                                                </span>
                                                /
                                                {row.finalizedTransactionsCount}
                                            </SMBold>
                                        </TableCell>
                                        <TableCellNoPaddingWithFontWeight
                                            fontWeight={
                                                row.balance > 0
                                                    ? 'bold'
                                                    : 'normal'
                                            }
                                        >
                                            {getBalanceLabel(
                                                row.balance,
                                                row.currency,
                                                undefined,
                                                true
                                            )}
                                        </TableCellNoPaddingWithFontWeight>
                                        <TableCellNoPadding>
                                            <IconButton
                                                style={{
                                                    paddingLeft: '14px',
                                                    paddingRight: '14px'
                                                }}
                                                color="primary"
                                                onClick={() => {
                                                    handleCompanySelect(
                                                        row.companyId
                                                    );
                                                }}
                                            >
                                                <OpenInNewIcon />
                                            </IconButton>
                                        </TableCellNoPadding>

                                        {user?.role ===
                                            UserRoles.SuperAdmin && (
                                            <TableCellNoPadding>
                                                <Button
                                                    color="secondary"
                                                    aria-controls={
                                                        open
                                                            ? 'basic-menu'
                                                            : undefined
                                                    }
                                                    aria-haspopup="true"
                                                    aria-expanded={
                                                        open
                                                            ? 'true'
                                                            : undefined
                                                    }
                                                    onClick={(event) =>
                                                        handleMenuClick(
                                                            event,
                                                            row
                                                        )
                                                    }
                                                >
                                                    <MoreVertIcon />
                                                </Button>
                                            </TableCellNoPadding>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableFooterCell>
                                        <SMBold>Total</SMBold>
                                    </TableFooterCell>
                                    <TableFooterCell align="right">
                                        <SMBold>
                                            {' '}
                                            {toDecimal(totals.employees)}
                                        </SMBold>
                                    </TableFooterCell>
                                    <TableFooterCell align="right">
                                        <SMBold>
                                            {' '}
                                            {toDecimal(totals.withdrawed)}
                                        </SMBold>
                                    </TableFooterCell>
                                    <TableFooterCell align="right">
                                        <SMBold>
                                            {toDecimal(
                                                totals.pendingTransactions
                                            )}
                                            /
                                            {toDecimal(
                                                totals.finalizedTransactions
                                            )}
                                        </SMBold>
                                    </TableFooterCell>
                                    <TableFooterCell
                                        align="right"
                                        style={{ paddingRight: 0 }}
                                    >
                                        <SMBold>
                                            {getBalanceLabel(
                                                totals.balance,
                                                'DKK'
                                            )}
                                        </SMBold>
                                    </TableFooterCell>
                                    <TableFooterCell></TableFooterCell>
                                    {user?.role === UserRoles.SuperAdmin && (
                                        <TableHeaderCell
                                            width={'40px'}
                                        ></TableHeaderCell>
                                    )}
                                </TableRow>
                            </TableFooter>
                        </Table>

                        <Menu
                            id="company-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button'
                            }}
                            sx={{ boxShadow: 'none' }}
                        >
                            <MenuItem
                                onClick={() => onDeleteIconClick(rowCompany!)}
                            >
                                <>Delete Company</>
                            </MenuItem>
                        </Menu>
                    </TableContainer>
                </TableCard>
                {deleteDialogCompany && (
                    <DeleteCompanyDialog
                        onClose={onDeleteDialogCancel}
                        onSubmit={onDeleteDialogSubmit}
                        validator={validator}
                        setValidator={setValidator}
                        open={deleteDialogOpen}
                        companyId={deleteDialogCompany.companyId}
                        companyName={deleteDialogCompany.name}
                    />
                )}
            </>
        </ContentWrapper>
    );
};
