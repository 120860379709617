import { SvgIcon, SvgIconProps } from '@mui/material';

export const TransactionsIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 16">
            <rect width="16" height="16" rx="3" />
            <path
                d="M6.33333 5.5H11.75M6.33333 8H11.75M6.33333 10.5H11.75M4.25 5.5H4.25417M4.25 8H4.25417M4.25 10.5H4.25417"
                stroke="#F5FAFF"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </SvgIcon>
    );
};
