import { SvgIconProps } from '@mui/material';

export const RefreshIcon = (props: SvgIconProps) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_234_6998)">
                <path
                    d="M0.666504 2.66655V6.66655M0.666504 6.66655H4.6665M0.666504 6.66655L3.75984 3.75989C4.47634 3.04303 5.36275 2.51936 6.33638 2.23774C7.31 1.95612 8.3391 1.92572 9.32765 2.14939C10.3162 2.37306 11.232 2.84351 11.9895 3.51683C12.7471 4.19016 13.3217 5.04441 13.6598 5.99989M15.3332 13.3332V9.33322M15.3332 9.33322H11.3332M15.3332 9.33322L12.2398 12.2399C11.5233 12.9567 10.6369 13.4804 9.6633 13.762C8.68967 14.0437 7.66058 14.0741 6.67203 13.8504C5.68348 13.6267 4.7677 13.1563 4.01015 12.4829C3.25259 11.8096 2.67795 10.9554 2.33984 9.99989"
                    stroke="#FDB021"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_234_6998">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
