export const paymentSources = {
    instaPaidAccount: 'InstaPaidAccount',
    companyAccount: 'CompanyAccount'
};

export const paymentProviders = {
    aiia: 'Aiia',
    danskeBank: 'DanskeBank'
};

export const approvalTypes = {
    manual: 'Manual',
    automatic: 'Automatic'
};

export const AUTO_APPROVAL_TIME_PROVIDERS = ['Planday', 'Staff'];
export const ALLOW_CONNECTION_TIME_REGISTRATION_EDIT = ['Planday', 'Zenegy'];
