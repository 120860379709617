import {
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Switch,
    FormControlLabel,
    TextFieldProps,
    Alert,
    FormGroup
} from '@mui/material';
import { useState } from 'react';
import { CreateUserRequest } from '../../../models/company';
import { Validator } from '../../../utils/errorHandling';
import { MuiTelInput } from 'mui-tel-input';

type AddUserDialogProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (
        request: CreateUserRequest,
        onSuccess: () => void
    ) => Promise<void>;
    validator: Validator;
    setValidator: React.Dispatch<React.SetStateAction<Validator>>;
};

export const AddUserDialog = (props: AddUserDialogProps) => {
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [isActive, setIsActive] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const onSubmit = () => {
        props.onSubmit(
            {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phoneNumber: phoneNumber,
                isActive: isActive,
                isAdmin: isAdmin
            },
            clearState
        );
    };

    const clearState = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhoneNumber('');
        setIsActive(false);
        setIsAdmin(false);
    };
    const commonProps: TextFieldProps = {
        margin: 'dense',
        fullWidth: true,
        variant: 'standard'
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Add new user</DialogTitle>
            <DialogContent>
                <Alert severity="info">
                    Note: for the new user to be able to use the Instapaid app,
                    you will need to run the user import from the "Payroll
                    connect" page (after you've added the user) in order to load
                    the new user's salary provider data.
                </Alert>
                <TextField
                    {...commonProps}
                    error={props.validator.hasError('firstName')}
                    helperText={props.validator.getError('firstName')}
                    name="first_name"
                    label="First name"
                    value={firstName}
                    onChange={(e) => {
                        setFirstName(e.currentTarget.value);
                        props.setValidator(
                            props.validator.clearError('firstName')
                        );
                    }}
                />

                <TextField
                    {...commonProps}
                    error={props.validator.hasError('lastName')}
                    helperText={props.validator.getError('lastName')}
                    name="last_name"
                    label="Last name"
                    value={lastName}
                    onChange={(e) => {
                        setLastName(e.currentTarget.value);
                        props.setValidator(
                            props.validator.clearError('lastName')
                        );
                    }}
                />

                <TextField
                    {...commonProps}
                    error={props.validator.hasError('email')}
                    helperText={props.validator.getError('email')}
                    name="email"
                    type="email"
                    label="Email"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.currentTarget.value);
                        props.setValidator(props.validator.clearError('email'));
                    }}
                />

                <MuiTelInput
                    {...commonProps}
                    name="phone_number"
                    label="Phone number"
                    placeholder="+XX XXXXXX"
                    error={props.validator.hasError('phoneNumber')}
                    helperText={props.validator.getError(
                        'phoneNumber',
                        'Phone number including country code'
                    )}
                    value={phoneNumber}
                    onChange={(e) => {
                        setPhoneNumber(e);
                        props.setValidator(
                            props.validator.clearError('phoneNumber')
                        );
                    }}
                />

                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={(event, checked) =>
                                    setIsActive(checked)
                                }
                                checked={isActive}
                            />
                        }
                        label="Active"
                    />
                </FormGroup>

                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={(event, checked) =>
                                    setIsAdmin(checked)
                                }
                                checked={isAdmin}
                            />
                        }
                        label="Admin"
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Cancel</Button>
                <Button variant="contained" onClick={onSubmit}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};
