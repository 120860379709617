import { useState, useEffect, useCallback } from 'react';
import { ContentWrapper } from '../../components/ContentWrapper';
import { ZenegyConnectStatus } from '../zenegy/ZenegyConnectStatus';
import { CompanyPaymentDetailsResponse } from '../../models/payment';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import { Alert } from '@mui/material';
import { IntectConnectStatus } from '../intect/IntectConnectStatus';
import { PayrollProviderSelect } from './PayrollProviderSelect';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export const PayrollPage = () => {
    const [company, setCompany] = useState<CompanyPaymentDetailsResponse>();
    const [errorMessage, setErrorMessage] = useState<string>();

    const { setLoading } = useLoader();
    const { backendErrorHandler } = useErrorHandling();

    const loadCompany = useCallback(() => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getCurrentCompany()
            .then((response: any) => {
                setCompany(response.data);
            })
            .catch(
                backendErrorHandler(
                    'Could not get company details: ',
                    (e: Error) => {
                        setErrorMessage(
                            'Could not get company details: ' + e.message
                        );
                    }
                )
            )
            .finally(() => {
                setLoading(false);
            });
    }, [backendErrorHandler, setLoading]);

    useEffect(() => {
        loadCompany();
    }, [loadCompany]);

    return (
        <ContentWrapper>
            <>
                {company?.usersCount === 1 && (
                    <PayrollProviderSelect
                        selectedPayrollProvider={company.salaryProvider}
                        onSaveSuccess={loadCompany}
                    />
                )}
                {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                {company && company.salaryProvider === 'Zenegy' && (
                    <ZenegyConnectStatus
                        company={company}
                        onSaveSuccess={loadCompany}
                        showImport={true}
                    />
                )}
                {company && company.salaryProvider === 'Intect' && (
                    <IntectConnectStatus
                        company={company}
                        onSaveSuccess={loadCompany}
                    />
                )}
            </>
        </ContentWrapper>
    );
};
