import { useNotificationsSnackbar } from '../../../components/snackbar/NotificationsSnackbarContext';
import { useLoader } from '../../../contexts/loader/LoaderContext';
import { UpdateBankAccountDialog } from './UpdateBankAccountDialog';
import { UpdateUserBankAccountDetailsRequest } from '../../../models/payment';
import { Validator, useErrorHandling } from '../../../utils/errorHandling';
import { useState } from 'react';
import PaymentsAPIServiceV2 from '../../../services/PaymentsServiceV2';

type UpdateBankAccountFormProps = {
    userId?: string;
    onUserListChange: () => void;
    open: boolean;
    setOpen: (open: boolean) => void;
};

export const UpdateBankAccountForm = (props: UpdateBankAccountFormProps) => {
    const { setAlert } = useNotificationsSnackbar();

    const { setLoading } = useLoader();
    const [validator, setValidator] = useState(new Validator());
    const { backendErrorHandler } = useErrorHandling();

    const onClose = () => {
        props.setOpen(false);
    };

    const onSubmit = async (
        request: UpdateUserBankAccountDetailsRequest
    ): Promise<void> => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .updateUserBankAccountDetails(request)
            .then(() => {
                setAlert('Saved successfully.', 'success');
                setValidator(new Validator());
                props.setOpen(false);
                props.onUserListChange();
            })
            .catch(
                backendErrorHandler(undefined, (e, errorDetails) => {
                    setValidator(new Validator(errorDetails?.errors));
                })
            )
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <UpdateBankAccountDialog
            validator={validator}
            setValidator={setValidator}
            userId={props.userId}
            open={props.open}
            onClose={onClose}
            onSubmit={onSubmit}
        />
    );
};
