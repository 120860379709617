import {
    Grid,
    FormControlLabel,
    Checkbox,
    FormGroup,
    Button,
    Container
} from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState, useCallback } from 'react';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useLoader } from '../../contexts/loader/LoaderContext';
import {
    EmployeeTypesResponse,
    SalaryModeResponse,
    DepartmentsResponse,
    ZenegyEmployeeTypeEnum,
    SalaryTypeResponse,
    ZenegySalaryTypeEnum
} from '../../models/payment';
import { useErrorHandling } from '../../utils/errorHandling';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { ImportSettingsProps } from '../users/import/ImportUsers';
import { ImportFiltersHeader } from '../users/import/ImportFiltersHeader';
import { useAppDispatch, usePayments } from '../../lib/hooks';
import { FETCH_STATE } from '../../lib/slices/types';
import { getZenegyDepartments } from '../../lib/slices/paymentsSlice';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export const ZenegyImportSettings = (props: ImportSettingsProps) => {
    const { setLoading } = useLoader();
    const { setAlert } = useNotificationsSnackbar();

    const { backendErrorHandler } = useErrorHandling();
    const [departments, setDepartments] = useState<DepartmentsResponse>();
    const [salaryModes, setSalaryModes] = useState<SalaryModeResponse>();
    const [salaryTypes, setSalaryTypes] = useState<SalaryTypeResponse>();
    const [employeeTypes, setEmployeeTypes] = useState<EmployeeTypesResponse>();
    const [
        confirmSettingsChangeDialogOpen,
        setConfirmSettingsChangeDialogOpen
    ] = useState(false);
    const [settingsDeselected, setSettingsDeselected] = useState(false);

    const dispatch = useAppDispatch();
    const { zenegyDepartments } = usePayments();

    useEffect(() => {
        setLoading(zenegyDepartments.status == FETCH_STATE.LOADING);
        if (zenegyDepartments) {
            setDepartments(zenegyDepartments.zenegyDepartments);
        }
        if (zenegyDepartments.error) {
            backendErrorHandler('Error fetching departments: ');
        }
    }, [zenegyDepartments]);

    const loadSettings = useCallback(() => {
        setLoading(true);
        dispatch(getZenegyDepartments());

        const salaryModesPromise = PaymentsAPIServiceV2.getInstance()
            .getZenegySalaryModes()
            .then((response: any) => {
                setSalaryModes(response.data as SalaryModeResponse);
            });

        const salaryTypesPromise = PaymentsAPIServiceV2.getInstance()
            .getZenegySalaryTypes()
            .then((response: any) => {
                setSalaryTypes(response.data as SalaryTypeResponse);
            });

        const employeeTypesPromise = PaymentsAPIServiceV2.getInstance()
            .getZenegyEmployeeTypes()
            .then((response: any) => {
                setEmployeeTypes(response.data as EmployeeTypesResponse);
            });

        Promise.all([
            salaryModesPromise,
            salaryTypesPromise,
            employeeTypesPromise
        ])
            .catch(backendErrorHandler('Could not load settings: '))
            .finally(() => {
                setLoading(false);
            });
    }, [backendErrorHandler, setLoading, getZenegyDepartments]);

    useEffect(() => {
        loadSettings();
    }, [loadSettings]);

    const handleCheckboxClick = <T extends string>(
        e: React.MouseEvent<HTMLLabelElement, MouseEvent>,
        clickedItem: CheckboxItem<T>,
        items: CheckboxItem<T>[] | undefined,
        setter: (items: CheckboxItem<T>[]) => void
    ) => {
        e.preventDefault();
        if (!items) return;
        props.setSettingsTouched(true);

        const currentIndex = items.indexOf(clickedItem);

        const newItems = items.map((item) => ({ ...item }));

        newItems[currentIndex].isUsersSyncEnabled =
            !items[currentIndex].isUsersSyncEnabled;
        if (newItems[currentIndex].isUsersSyncEnabled === false) {
            // if the item has been deselected, update the state to render the confirmation dialog before user saves the settings
            setSettingsDeselected(true);
        }
        setter(newItems);
    };

    const saveSettings = () => {
        if (!salaryModes || !employeeTypes || !departments || !salaryTypes)
            return;

        setConfirmSettingsChangeDialogOpen(false);
        setLoading(true);
        const salaryModesPromise =
            PaymentsAPIServiceV2.getInstance().updateZenegySalaryModes(
                salaryModes
            );
        const salaryTypesPromise =
            PaymentsAPIServiceV2.getInstance().updateZenegySalaryTypes(
                salaryTypes
            );
        const employeeTypesPromise =
            PaymentsAPIServiceV2.getInstance().updateZenegyEmployeeTypes(
                employeeTypes
            );
        const departmentsPromise =
            PaymentsAPIServiceV2.getInstance().updateZenegyDepartments(
                departments
            );

        Promise.all([
            salaryModesPromise,
            salaryTypesPromise,
            employeeTypesPromise,
            departmentsPromise
        ])
            .then(() => {
                props.setSettingsTouched(false);
                setSettingsDeselected(false);
                setAlert('Settings saved successfully.', 'success');
            })
            .catch(backendErrorHandler('Could not update settings: '))
            .finally(() => {
                setLoading(false);
            });
    };

    const onSaveClick = () => {
        if (settingsDeselected) {
            // if any settings have been deselected, show confirmation dialog
            setConfirmSettingsChangeDialogOpen(true);
        } else {
            saveSettings();
        }
    };

    const onCancelClick = () => {
        loadSettings();
        props.setSettingsTouched(false);
    };

    type CheckboxItem<T> = {
        id: string;
        name: T;
        isUsersSyncEnabled: boolean;
    };

    const renderCheckboxes = <T extends string>(
        title: string,
        items: CheckboxItem<T>[],
        setter: (items: CheckboxItem<T>[]) => void
    ) => {
        return (
            <Grid item xs={12} sm={6} md={4}>
                <b>{title}</b>
                <br />
                {items && (
                    <FormGroup>
                        {items.map((item) => (
                            <FormControlLabel
                                onClick={(e) =>
                                    handleCheckboxClick(e, item, items, setter)
                                }
                                key={item.name}
                                control={
                                    <Checkbox
                                        size="small"
                                        sx={{ padding: '0.3rem 0.5rem' }}
                                        checked={item.isUsersSyncEnabled}
                                    />
                                }
                                label={item.name}
                            />
                        ))}
                    </FormGroup>
                )}
            </Grid>
        );
    };

    return (
        <Container sx={{ marginBottom: 3 }}>
            <ImportFiltersHeader onRefreshSuccess={loadSettings} />
            Choose employees which should be imported from Zenegy. Only
            categories with active users are shown below. Click the refresh icon
            to update the lists. After initial setup of the connection to
            Zenegy, we will run the import daily for you using the same settings
            to make sure the employee data stays up to date.
            <Grid marginTop="0.2rem" marginBottom="1rem" container spacing={1}>
                {employeeTypes &&
                    renderCheckboxes(
                        'Employee type',
                        employeeTypes?.employeeTypes,
                        (items: CheckboxItem<ZenegyEmployeeTypeEnum>[]) => {
                            setEmployeeTypes({ employeeTypes: items });
                        }
                    )}
                {departments &&
                    renderCheckboxes(
                        'Department',
                        departments?.departments,
                        (items: CheckboxItem<string>[]) => {
                            setDepartments({ departments: items });
                        }
                    )}
                {salaryTypes &&
                    renderCheckboxes(
                        'Salary type',
                        salaryTypes?.salaryTypes,
                        (items: CheckboxItem<ZenegySalaryTypeEnum>[]) => {
                            setSalaryTypes({ salaryTypes: items });
                        }
                    )}
                {/* // TODO enable when formulas in place - see #232 */}
                {/* {salaryModes && renderCheckboxes("Salary mode for hourly employees", salaryModes?.salaryModes, (items: CheckboxItem<SalaryModeEnum>[]) => { setSalaryModes({ salaryModes: items }) })} */}
            </Grid>
            <ConfirmDialog
                title="Are you sure?"
                content="Disabling import categories may result in deletion of user profiles."
                dialogOpen={confirmSettingsChangeDialogOpen}
                handleContinue={() => {
                    saveSettings();
                }}
                handleCancel={() => setConfirmSettingsChangeDialogOpen(false)}
            />
            <Stack direction="row" spacing={2}>
                <Button
                    color="error"
                    variant="outlined"
                    onClick={onCancelClick}
                >
                    Cancel
                </Button>
                <Button
                    color="success"
                    variant="outlined"
                    onClick={onSaveClick}
                >
                    Save settings
                </Button>
            </Stack>
        </Container>
    );
};
