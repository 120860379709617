import {
    ZenegyEmployeeTypeEnum,
    ZenegySalaryModeEnum,
    ZenegySalaryTypeEnum
} from '../models/payment';

export interface ZenegyFilter {
    selectedSalaryModes: ZenegySalaryModeEnum[];
    selectedDepartments: string[];
    selectedEmployeeTypes: ZenegyEmployeeTypeEnum[];
    selectedSalaryTypes: ZenegySalaryTypeEnum[];
    selectedPandayEmployeeIds?: string[] | string;
    selectedActiveType: string;
    username: string;
    selectedActive: string | undefined;
    selectedInstaPaidSalaryTypes: string | undefined;
}

export interface IntectFilters {
    selectedDepartments: string[];
    selectedEmploymentTemplates: string[];
    username: string;
    selectedPlanDayEmployeeTypes: string[];
    selectedActiveType: string;
    selectedActive: string | undefined;
    selectedSalaryTypes: string | undefined;
}

const ZENEGY_FILTERS = 'zenegyFilters';
const INTECT_FILTERS = 'intectFilters';
const SUPER_ADMIN_OVERRIDE = 'superAdminOverride';

export const saveZenegyUsersFiltersLocally = (filters: ZenegyFilter) => {
    localStorage.setItem(ZENEGY_FILTERS, JSON.stringify(filters));
};

export const getZenegyUsersFilters = () => {
    const filters = localStorage.getItem(ZENEGY_FILTERS);
    return filters ? JSON.parse(filters) : undefined;
};

export const saveZenegyUserFilters = (newFilter: Partial<ZenegyFilter>) => {
    let filters = getZenegyUsersFilters();
    saveZenegyUsersFiltersLocally({
        ...filters,
        ...newFilter
    });
};

export const saveIntectUsersFiltersLocally = (filters: IntectFilters) => {
    localStorage.setItem(INTECT_FILTERS, JSON.stringify(filters));
};

export const getIntectUsersFilters = () => {
    const filters = localStorage.getItem(INTECT_FILTERS);
    return filters ? JSON.parse(filters) : undefined;
};

export const saveIntectUserFilters = (newFilter: Partial<IntectFilters>) => {
    let filters = getIntectUsersFilters();
    saveIntectUsersFiltersLocally({
        ...filters,
        ...newFilter
    });
};

export const setSuperAdminOverride = (value: boolean) => {
    localStorage.setItem(SUPER_ADMIN_OVERRIDE, JSON.stringify(value));
};

export const getSuperAdminOverride = () => {
    const value = localStorage.getItem(SUPER_ADMIN_OVERRIDE);
    return value ? JSON.parse(value) : false;
};

// export const handleZenegyFilterChange = (
//     key: keyof ZenegyFilter,
//     value: Partial<ZenegyFilter>,
//     func: Function
// ) => {
//     func(value);
//     saveZenegyUserFilters(value);
// };

export const ROWS_PER_PAGE_USER_TABLE = 'ROWS_PER_PAGE_USER_TABLE';

export const saveRowsPerPageForUserTable = (rowsPerPage: number) => {
    if (rowsPerPage > -2) {
        localStorage.setItem(ROWS_PER_PAGE_USER_TABLE, rowsPerPage?.toString());
    }
};

export const getRowsPerPageForUserTable = () => {
    const rowsPerPage = localStorage.getItem(ROWS_PER_PAGE_USER_TABLE);
    return rowsPerPage ? parseInt(rowsPerPage) : 16;
};
