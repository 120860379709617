import { createTheme } from '@mui/material';
import {
    drawerActiveIconColor,
    drawerBackgroundColor,
    drawerIconColor
} from '../utils/colors';
import { grey, red } from '@mui/material/colors';

export const staticColors = {
    white: '#ffffff',
    drawerActiveBackground: grey[300],
    lightError: '#FEF3F2',
    error: red[500],
    rootBackgroundColor: '#F0F5F8',
    successDot: '#17B76A',
    buttonGreyText: '#667085',
    rowBackgroundDeep: '#667085',
    rowBackgroundLight: '#F2F4F7',
    disabledText: '#667085',
    enabledText: '#131313',
    tableRowHeader: '#D0D5DD',
    tableSubHeader: '#F2F4F7'
};

export const PADDING = {
    small: '8px',
    medium: '16px',
    large: '24px',
    // custom
    px12: '12px'
};

export const theme = createTheme({
    typography: {
        fontFamily: ['Inter', 'Figtree', 'Arial'].join(','),
        fontSize: 12
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: drawerBackgroundColor
                }
            }
        }
    },
    palette: {
        drawerIconColor,
        drawerActiveIconColor,
        textColor: '#000000',
        successColor: '#5BC236',
        lightSuccessColor: '#D1FADF',
        successBackground: '#ecfdf3',
        warnColor: '#FECC00',
        headingColor: '#0F70C6',
        selectedRowColor: '#edf7fe',
        infoColor: '#33b5e5',
        danger: '#ff4444',
        white1: '#DADDED',
        greyBorder: '#D0D5DD',
        white2: '#F1F2F7',
        greyText: '#667085',
        greySurface: '#F2F3F8',
        text: {
            secondary: '#667085',
            success: '#089855',
            error: '#D92D20'
        },
        success: {
            main: '#057A48'
        },

        primary: {
            main: '#1570EF'
        },
        secondary: {
            main: '#344054'
        }
    }
});
