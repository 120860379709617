import {
    Box,
    Card,
    Grid,
    Table,
    TableBody,
    TableContainer
} from '@mui/material';

import {
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow
} from '../../components/shared/styles/Table';
import { Heading } from '../../typography/Typography';
import { Dropdown } from '../shared/Dropdown';
import { useEffect, useState } from 'react';
import ReportsInternalService from '../../services/ReportsInternalService';
import { useAppDispatch } from '../../lib/hooks';
import { setLoader } from '../../lib/slices/globalLoaderSlice';
import { useNotificationsSnackbar } from '../snackbar/NotificationsSnackbarContext';
import { useFormatting } from '../../utils/formatting';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

export const SicknessReport = () => {
    const dispatch = useAppDispatch();

    const [departments, setDepartments] = useState<string[]>([]);
    const [selectedDepartment, setSelectedDepartment] = useState<string>();

    const [employeesList, setEmployeesList] = useState<any[]>([]);
    const { setAlert } = useNotificationsSnackbar();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatch(setLoader(isLoading));
    }, [isLoading]);

    const fetchDepartments = async () => {
        try {
            setIsLoading(true);
            let { data } =
                await ReportsInternalService.getInstance().getDepartments();
            setDepartments([
                'All',
                ...data.plandayDepartments.map((d: any) => d.name)
            ]);
            setSelectedDepartment('All');
        } catch (e) {
            setAlert('Failed to fetch departments', 'error');
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDepartments();
    }, []);

    const fetchEmployeeSickness = async () => {
        try {
            setIsLoading(true);
            let departments =
                selectedDepartment === 'All' ? [] : [selectedDepartment!];
            let { data } =
                await ReportsInternalService.getInstance().getEmployeeSickness(
                    departments
                );

            setEmployeesList(data.employees);
        } catch (e) {
            setAlert('Failed to fetch employee sickness', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (selectedDepartment) {
            fetchEmployeeSickness();
        }
    }, [selectedDepartment]);

    const { toDecimal } = useFormatting();

    const { t } = useTranslation();

    return (
        <Card>
            <Box>
                <Grid pt={2} pl={2} pr={2} container alignItems={'center'}>
                    <Grid item xs={10}>
                        <Heading>Sickness Report</Heading>
                    </Grid>
                    <Grid item xs={2}>
                        <Dropdown
                            label={''}
                            choices={departments.map((d, index) => {
                                return { label: d, value: d };
                            })}
                            value={selectedDepartment}
                            onChange={function (value: any | any[]): void {
                                setSelectedDepartment(value);
                            }}
                        ></Dropdown>
                    </Grid>
                </Grid>

                <Box mt={2}>
                    <TableContainer
                        style={{
                            overflowY: 'scroll',
                            height: 'calc(100vh - 220px)'
                        }}
                    >
                        <Table>
                            <TableHeader
                                style={{
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1
                                }}
                            >
                                <TableHeaderCell>
                                    {t('employee_name')}
                                </TableHeaderCell>
                                <TableHeaderCell>
                                    {t('department')}
                                </TableHeaderCell>

                                {employeesList?.[0]?.sickDays?.map(
                                    (sickDay: any) => {
                                        return (
                                            <TableHeaderCell
                                                align="right"
                                                key={sickDay?.monthName}
                                                style={{ minWidth: '75px' }}
                                            >
                                                {sickDay?.monthName}{' '}
                                                {dayjs(
                                                    sickDay?.year?.toString()
                                                ).format('YY')}
                                            </TableHeaderCell>
                                        );
                                    }
                                )}

                                <TableHeaderCell
                                    align="right"
                                    style={{
                                        minWidth: '70px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {t('sum')}
                                </TableHeaderCell>
                            </TableHeader>
                            <TableBody>
                                {employeesList.map((employee) => {
                                    return (
                                        <TableRow key={employee.id}>
                                            <TableCell
                                            // style={{ textWrap: 'nowrap' }}
                                            >
                                                {employee?.employeeName}
                                            </TableCell>

                                            <TableCell>
                                                {employee?.departmentName}
                                            </TableCell>

                                            {employee?.sickDays?.map(
                                                (
                                                    sickDay: any,
                                                    index: number
                                                ) => {
                                                    return (
                                                        <TableCell
                                                            align="right"
                                                            key={index}
                                                        >
                                                            {sickDay?.hours
                                                                ? toDecimal(
                                                                      parseFloat(
                                                                          sickDay?.hours
                                                                      ),
                                                                      2
                                                                  )
                                                                : '-'}
                                                        </TableCell>
                                                    );
                                                }
                                            )}
                                            <TableCell
                                                align="right"
                                                style={{ fontWeight: 'bold' }}
                                            >
                                                {employee?.sum}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Card>
    );
};
