export const impersonateUser = (userId: string) => {
    window.localStorage.setItem('impersonated_user_id', userId);
};

export const getImpersonatedUserId = () => {
    return window.localStorage.getItem('impersonated_user_id');
};

export const exitImpersonation = () => {
    window.localStorage.removeItem('impersonated_user_id');
};
