import { useCallback, useEffect, useState } from 'react';
import {
    Box,
    TextField,
    TextFieldProps,
    Button,
    FormControl,
    Typography
} from '@mui/material';
import {
    CompanyDanskeBankSettings,
    CompanyPaymentDetailsResponse
} from '../../models/payment';
import { TransactionApprovalDetails } from './TransactionApprovalDetails';
import { Validator, useErrorHandling } from '../../utils/errorHandling';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useLoader } from '../../contexts/loader/LoaderContext';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export type DanskeBankConnectStatusProps = {
    companyPaymentDetails: CompanyPaymentDetailsResponse;
};

export const DanskeBankConnectStatus = (
    props: DanskeBankConnectStatusProps
) => {
    const [cvr, setCvr] = useState<string>('');
    const [iban, setIban] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [validator, setValidator] = useState(new Validator());
    const { backendErrorHandler } = useErrorHandling();

    const { setAlert } = useNotificationsSnackbar();
    const { setLoading } = useLoader();
    const commonProps: TextFieldProps = {
        margin: 'dense',
        fullWidth: true,
        variant: 'standard'
    };

    const fetchCompany = useCallback(() => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getCompanyDanskeBankSettings()
            .then((response: any) => {
                const settings = response.data as CompanyDanskeBankSettings;
                setCvr(settings.cvr);
                setIban(settings.iban);
                setName(settings.name);
            })
            .catch(backendErrorHandler())
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, backendErrorHandler]);

    useEffect(() => {
        fetchCompany();
    }, [fetchCompany]);

    const onSave = () => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .updateCompanyDanskeBankSettings({ cvr, iban, name })
            .then(() => {
                setAlert(
                    'Danske bank settings updated successfully',
                    'success'
                );
            })
            .catch(
                backendErrorHandler(
                    'Could not save settings: ',
                    (e, errorDetails) => {
                        setValidator(new Validator(errorDetails?.errors));
                    }
                )
            )
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Typography variant="h5">Payment provider settings</Typography>
            {props.companyPaymentDetails?.paymentSource ===
                'CompanyAccount' && (
                <Box>
                    Your company uses its <b>DanskeBank account</b> to approve
                    payments.
                </Box>
            )}

            <TransactionApprovalDetails
                approvalType={props.companyPaymentDetails?.approvalType}
            />

            <FormControl>
                <TextField
                    {...commonProps}
                    error={validator.hasError('name')}
                    helperText={validator.getError('name')}
                    label="Name"
                    value={name}
                    onChange={(e) => {
                        setName(e.currentTarget.value);
                        setValidator(validator.clearError('name'));
                    }}
                />

                <TextField
                    {...commonProps}
                    error={validator.hasError('iban')}
                    helperText={validator.getError('iban')}
                    label="IBAN"
                    value={iban}
                    onChange={(e) => {
                        setIban(e.currentTarget.value);
                        setValidator(validator.clearError('iban'));
                    }}
                />

                <TextField
                    {...commonProps}
                    error={validator.hasError('cvr')}
                    helperText={validator.getError('cvr')}
                    label="CVR"
                    value={cvr}
                    onChange={(e) => {
                        setCvr(e.currentTarget.value);
                        setValidator(validator.clearError('cvr'));
                    }}
                />

                <Button
                    variant="contained"
                    sx={{ marginTop: '1rem' }}
                    onClick={onSave}
                >
                    Save
                </Button>
            </FormControl>
        </>
    );
};
