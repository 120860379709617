import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState
} from 'react';
import { Alert } from '@mui/material';
import {
    AuthenticationResult,
    Configuration,
    EventMessage,
    EventType,
    LogLevel,
    PopupRequest,
    PublicClientApplication
} from '@azure/msal-browser';
import { MSALHolder } from '../../lib/msal';

export type BackofficeLoginType = 'AD' | 'B2C';

export const persistLoginType = (loginType: BackofficeLoginType) => {
    window.localStorage.setItem('loginType', loginType);
};
export const getPersistedLoginType = () => {
    return window.localStorage.getItem('loginType') as BackofficeLoginType;
};

type ConfigurationProviderProps = {
    children: JSX.Element;
};

type B2CConfiguration = {
    authorityB2C: string;
    authorityAD: string;
    authorityADChangeEmailFlow: string;
    authorityDomain: string;
    clientId: string;
    scopes: string;
};

type SPAConfiguration = {
    backendUserServiceUrl: string;
    backendPaymentsServiceUrl: string;
    backendNotificationsServiceUrl: string;
    appInsightsConnectionString: string;
    b2c: B2CConfiguration;
    underMaintenanceFileUrl: string;
};

type State = {
    spaConfiguration: SPAConfiguration;
    msalConfiguration: Configuration;
    msalInstance?: PublicClientApplication;
    loginRequest?: PopupRequest;
    loginRequestAD?: PopupRequest;
    loginB2C: () => void;
    loginAD: (loginHint: string | null) => void;
};
const initialState: State = {
    spaConfiguration: {
        backendUserServiceUrl: '',
        backendPaymentsServiceUrl: '',
        backendNotificationsServiceUrl: '',
        appInsightsConnectionString: '',
        b2c: {
            authorityB2C: '',
            authorityAD: '',
            authorityADChangeEmailFlow: '',
            authorityDomain: '',
            clientId: '',
            scopes: ''
        },
        underMaintenanceFileUrl: ''
    },
    msalConfiguration: { auth: { clientId: '' } },
    loginB2C: () => {},
    loginAD: () => {}
};

const ConfigurationContext = createContext({
    ...initialState
});

export const ConfigurationProvider = (props: ConfigurationProviderProps) => {
    const [spaConfiguration, setSPAConfiguration] = useState<SPAConfiguration>(
        initialState.spaConfiguration
    );
    const [msalConfiguration, setMsalConfiguration] = useState<Configuration>(
        initialState.msalConfiguration
    );
    const [msalInstance, setMsalInstance] = useState<PublicClientApplication>();
    const [loginRequest, setLoginRequest] = useState<PopupRequest>();
    const [loginRequestAD, setLoginRequestAD] = useState<PopupRequest>();
    const [error, setError] = useState('');
    const [loaded, setLoaded] = useState(false);
    const msalInitialized = useRef(false);

    const createLoginRequest = useCallback(
        (configuration: SPAConfiguration): PopupRequest => {
            return {
                scopes: [configuration.b2c.scopes]
            };
        },
        []
    );

    const createLoginRequestAD = useCallback(
        (configuration: SPAConfiguration): PopupRequest => {
            return {
                scopes: [configuration.b2c.scopes],
                authority: configuration.b2c.authorityAD
            };
        },
        []
    );

    const b2cPolicies = useCallback((spaConfiguration: SPAConfiguration) => {
        return {
            names: {
                signIn: 'B2C_1_signin'
            },
            authorities: {
                signIn: {
                    authority: spaConfiguration.b2c.authorityB2C
                }
            },
            authorityDomain: spaConfiguration.b2c.authorityDomain
        };
    }, []);

    const getMsalConfiguration = useCallback(
        (spaConfiguration: SPAConfiguration) => {
            const ua = window.navigator.userAgent;
            const msie = ua.indexOf('MSIE ');
            const msie11 = ua.indexOf('Trident/');
            const msedge = ua.indexOf('Edge/');
            const firefox = ua.indexOf('Firefox');
            const isIE = msie > 0 || msie11 > 0;
            const isEdge = msedge > 0;
            const isFirefox = firefox > 0;

            const b2cPolicyValues = b2cPolicies(spaConfiguration);

            const msalConfig: Configuration = {
                auth: {
                    clientId: spaConfiguration.b2c.clientId,
                    authority: b2cPolicyValues.authorities.signIn.authority,
                    knownAuthorities: [b2cPolicyValues.authorityDomain],
                    redirectUri: '/',
                    postLogoutRedirectUri: '/'
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: isIE || isEdge || isFirefox
                },
                system: {
                    loggerOptions: {
                        loggerCallback: (
                            level: LogLevel,
                            message: string,
                            containsPii: boolean
                        ) => {
                            if (containsPii) {
                                return;
                            }
                            switch (level) {
                                case LogLevel.Error:
                                    console.error(message);
                                    return;
                                case LogLevel.Info:
                                    console.info(message);
                                    return;
                                case LogLevel.Verbose:
                                    console.debug(message);
                                    return;
                                case LogLevel.Warning:
                                    console.warn(message);
                                    return;
                                default:
                                    return;
                            }
                        },
                        logLevel: LogLevel.Verbose
                    }
                }
            };
            return msalConfig;
        },
        [b2cPolicies]
    );

    const createMsalInstance = useCallback(
        async (msalConfiguration: Configuration) => {
            const instance = new PublicClientApplication(msalConfiguration);

            // Account selection logic is app dependent. Adjust as needed for different use cases.
            const accounts = instance.getAllAccounts();
            if (accounts.length > 0) {
                instance.setActiveAccount(accounts[0]);
            }

            instance.addEventCallback((event: EventMessage) => {
                if (
                    (event.eventType === EventType.LOGIN_SUCCESS ||
                        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                    event.payload
                ) {
                    const payload = event.payload as AuthenticationResult;
                    const account = payload.account;
                    instance.setActiveAccount(account);
                }
            });

            // handle auth redired/do all initial setup for msal (see https://stackoverflow.com/questions/66405214/browserautherror-interaction-in-progress-interaction-is-currently-in-progress)
            await instance.handleRedirectPromise();

            return instance;
        },
        []
    );

    const loginB2C = () => {
        persistLoginType('B2C');
        msalInstance?.loginRedirect(loginRequest);
    };

    const loginAD = (loginHint: string | null) => {
        persistLoginType('AD');
        const request: PopupRequest = {
            scopes: [spaConfiguration.b2c.scopes],
            // if login hint is present in the url, redirect the user to confirm phone number using hint and change email
            authority: loginHint
                ? spaConfiguration.b2c.authorityADChangeEmailFlow
                : spaConfiguration.b2c.authorityAD,
            loginHint: loginHint ?? undefined
        };
        msalInstance?.loginRedirect(request);
    };

    const configMSAL = async () => {
        try {
            let configHolder = await MSALHolder.getInstance();
            let spaConfiguration = configHolder.getSpaConfiguration();
            setSPAConfiguration(spaConfiguration!);

            const msalConfiguration = getMsalConfiguration(spaConfiguration!);

            const msalInstance = await createMsalInstance(msalConfiguration);

            MSALHolder.msalInstance = msalInstance;
            setMsalInstance(msalInstance);
            setLoginRequest(createLoginRequest(spaConfiguration!));
            setLoginRequestAD(createLoginRequestAD(spaConfiguration!));
        } catch (error) {
            console.error(error);
            setError('Could not load app configuration');
        } finally {
            setLoaded(true);
        }
    };

    useEffect(() => {
        if (msalInitialized.current) return;
        msalInitialized.current = true;
        configMSAL();
        // fetch(`/config.json`)
        //     .then((response) => response.json())
        //     .then(async (json) => {
        //         const spaConfiguration = json as SPAConfiguration;
        //         setSPAConfiguration(spaConfiguration);

        //         const msalConfiguration =
        //             getMsalConfiguration(spaConfiguration);

        //         setMsalConfiguration(msalConfiguration);
        //         const msalInstance = await createMsalInstance(
        //             msalConfiguration
        //         );
        //         MSALHolder.msalInstance = msalInstance;
        //         setMsalInstance(msalInstance);
        //         setLoginRequest(createLoginRequest(spaConfiguration));
        //         setLoginRequestAD(createLoginRequestAD(spaConfiguration));
        //     })
        //     .catch((error) => {
        //         console.error(error);
        //         setError('Could not load app configuration');
        //     })
        //     .finally(() => setLoaded(true));
    }, [
        getMsalConfiguration,
        createMsalInstance,
        msalInstance,
        createLoginRequest,
        createLoginRequestAD
    ]);

    return (
        <>
            {loaded && (
                <>
                    {error ? (
                        <Alert severity="error">{error}</Alert>
                    ) : (
                        <ConfigurationContext.Provider
                            value={{
                                spaConfiguration: spaConfiguration,
                                msalConfiguration: msalConfiguration,
                                msalInstance: msalInstance,
                                loginRequest: loginRequest,
                                loginRequestAD: loginRequestAD,
                                loginB2C: loginB2C,
                                loginAD: loginAD
                            }}
                        >
                            {props.children}
                        </ConfigurationContext.Provider>
                    )}
                </>
            )}
        </>
    );
};

export const useConfiguration = () => useContext(ConfigurationContext);
