import { useCallback, useEffect, useState } from 'react';
import { Alert, Box, Button } from '@mui/material';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { TransactionsDetailedResponse } from '../../models/transaction';
import {
    CompanyPaymentDetailsResponse,
    GetAiiaAuthorizeUrlResponse
} from '../../models/payment';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { TransactionsApprovalTable } from './TransactionsApprovalTable';
import { useErrorHandling } from '../../utils/errorHandling';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';
import { getCurrentUserData } from '../../lib/slices/usersSlice';
import { useAppDispatch, useUsers } from '../../lib/hooks';
import { useTranslation } from 'react-i18next';

export const TransactionsList = () => {
    const { t } = useTranslation();
    const [transactions, setTransactions] =
        useState<TransactionsDetailedResponse>();
    const [companyPaymentDetails, setCompanyPaymentDetails] =
        useState<CompanyPaymentDetailsResponse>();
    const [checked, setChecked] = useState<
        { transactionId: string; transactionStatus: string }[]
    >([]);
    const [errorMessage, setErrorMessage] = useState<string>();
    const { setAlert } = useNotificationsSnackbar();
    const { setLoading } = useLoader();
    const dispatch = useAppDispatch();
    const { backendErrorHandler } = useErrorHandling();
    const { currentUser } = useUsers();

    useEffect(() => {
        dispatch(getCurrentUserData());
    }, []);

    const loadData = useCallback(() => {
        setLoading(true);
        const companyPromise = PaymentsAPIServiceV2.getInstance()
            .getCurrentCompany()
            .then((response: any) => {
                setCompanyPaymentDetails(
                    response.data as CompanyPaymentDetailsResponse
                );
            })
            .catch(
                backendErrorHandler(undefined, (e: Error) => {
                    setErrorMessage(
                        'Could not load current company payment details: ' +
                            e.message
                    );
                })
            );
        const transactionsPromise = PaymentsAPIServiceV2.getInstance()
            .getPendingTransactions()
            .then((response: any) => {
                setTransactions(response.data);
                setChecked([]);
            })
            .catch(
                backendErrorHandler(undefined, (e: Error) => {
                    setErrorMessage(
                        'Could not get transactions list: ' + e.message
                    );
                })
            );

        Promise.all([companyPromise, transactionsPromise]).finally(() => {
            setLoading(false);
        });
    }, [setLoading, backendErrorHandler]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const handleToggle =
        (transactionId: string, transactionStatus: string) => () => {
            const currentIndex = checked.findIndex(
                (item) => item.transactionId === transactionId
            );
            const newChecked = [...checked];

            if (currentIndex === -1) {
                newChecked.push({ transactionId, transactionStatus });
            } else {
                newChecked.splice(currentIndex, 1);
            }

            setChecked(newChecked);
        };

    const handleToggleAll = () => () => {
        const allChecked = checked.length === transactions?.transactions.length;
        let newChecked: { transactionId: string; transactionStatus: any }[] =
            [];

        if (allChecked) {
            newChecked = [];
        } else {
            newChecked =
                transactions?.transactions.map((transaction) => ({
                    transactionId: transaction.transactionId,
                    transactionStatus: transaction.status
                })) ?? [];
        }

        setChecked(newChecked);
    };

    const pendingApprovalTransactions = checked.filter(
        (item) => item.transactionStatus === 'PendingApproval'
    );

    const succeedFailTransactions = checked.filter(
        (item) => item.transactionStatus === 'AwaitingBank'
    );

    const handleAiiaWithdrawal = async () => {
        if (
            companyPaymentDetails?.aiiaPaymentsAllowedPerAuthorization &&
            pendingApprovalTransactions.length >
                companyPaymentDetails?.aiiaPaymentsAllowedPerAuthorization
        ) {
            setAlert(
                `You cannot authorize more than ${companyPaymentDetails?.aiiaPaymentsAllowedPerAuthorization} payments in one batch`,
                'error'
            );
            return;
        }

        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getAiiaAuthorizeUrl({
                transactionIds: pendingApprovalTransactions.map(
                    (item) => item.transactionId
                )
            })
            .then((response: any) => {
                let responseBody: GetAiiaAuthorizeUrlResponse = response.data;
                window.location.href = responseBody.authorizeUrl;
            })
            .catch(
                backendErrorHandler(
                    'Could not authroize transactions list: ',
                    () => {
                        loadData();
                    }
                )
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const handleDanskeBankWithdrawal = async () => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .approveTransactions({
                transactionIds: pendingApprovalTransactions.map(
                    (item) => item.transactionId
                )
            })
            .then(() => {
                setAlert('Transactions approved successfully', 'success');
                loadData();
            })
            .catch(
                backendErrorHandler(
                    'Could not approve transactions list: ',
                    () => {
                        loadData();
                    }
                )
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const handleTransactionFail = async (
        e: React.SyntheticEvent
    ): Promise<void> => {
        e.preventDefault();

        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .failTransactionInSalarySystem({
                transactionIds: succeedFailTransactions.map(
                    (item) => item.transactionId
                )
            })
            .then(() => {
                setAlert('Transactions failed successfully', 'success');
                loadData();
            })
            .catch(
                backendErrorHandler(
                    'Could not fail transactions list: ',
                    () => {
                        loadData();
                    }
                )
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const handleTransactionSucceed = async (
        e: React.SyntheticEvent
    ): Promise<void> => {
        e.preventDefault();

        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .succeedTransactionInSalarySystem({
                transactionIds: succeedFailTransactions.map(
                    (item) => item.transactionId
                )
            })
            .then(() => {
                setAlert('Transactions succeeded', 'success');
                loadData();
            })
            .catch(
                backendErrorHandler(
                    'Could not succeed transactions list: ',
                    () => {
                        loadData();
                    }
                )
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const onApproveWithdrawals = async (
        e: React.SyntheticEvent
    ): Promise<void> => {
        e.preventDefault();

        if (companyPaymentDetails?.paymentProvider === 'Aiia') {
            handleAiiaWithdrawal();
        } else if (companyPaymentDetails?.paymentProvider === 'DanskeBank') {
            handleDanskeBankWithdrawal();
        } else if (
            companyPaymentDetails?.paymentSource === 'InstaPaidAccount'
        ) {
            handleDanskeBankWithdrawal();
        } else {
            setAlert(
                'Unknown payment provider - please contact support',
                'warning'
            );
        }
    };

    const onRejectWithdrawals = async (
        e: React.SyntheticEvent
    ): Promise<void> => {
        e.preventDefault();
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .rejectTransactions({
                transactionIds: pendingApprovalTransactions.map(
                    (item) => item.transactionId
                )
            })
            .then(() => {
                setAlert(
                    'Withdrawal request(-s) rejected successfully',
                    'success'
                );
            })
            .catch(backendErrorHandler('Could not reject transactions: '))
            .finally(() => {
                setLoading(false);
                loadData();
            });
    };

    const shouldShowApproveReject = checked.some(
        (item) => item?.transactionStatus === 'PendingApproval'
    );
    const shouldShowSucceedFail = checked.some(
        (item) => item.transactionStatus === 'AwaitingBank'
    );

    return (
        <Box>
            {transactions && (
                <>
                    {transactions.transactions.length === 0 && (
                        <Alert severity="info">
                            {t('pending_transactions_placeholder')}
                        </Alert>
                    )}
                    {transactions.transactions.length > 0 && (
                        <>
                            <Box>{t('pending_transactions_note')}:</Box>
                            <TransactionsApprovalTable
                                checked={checked}
                                transactions={transactions.transactions}
                                handleToggle={handleToggle}
                                handleAllToggle={handleToggleAll}
                            />

                            {companyPaymentDetails?.paymentProvider ===
                                'Aiia' && (
                                <Alert severity="info">
                                    Your bank supports approval of a total of{' '}
                                    {
                                        companyPaymentDetails?.aiiaPaymentsAllowedPerAuthorization
                                    }{' '}
                                    payment(-s) in a batch
                                </Alert>
                            )}

                            {shouldShowApproveReject && (
                                <>
                                    <Button
                                        sx={{ margin: '1rem 1rem 0 0' }}
                                        variant="contained"
                                        onClick={onApproveWithdrawals}
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        sx={{ margin: '1rem 1rem 0 0' }}
                                        variant="contained"
                                        color="error"
                                        onClick={onRejectWithdrawals}
                                    >
                                        Reject
                                    </Button>
                                </>
                            )}
                            {shouldShowSucceedFail &&
                                currentUser?.data?.role === 'SuperAdmin' && (
                                    <>
                                        <Button
                                            sx={{ margin: '1rem 1rem 0 0' }}
                                            variant="contained"
                                            color="success"
                                            onClick={handleTransactionSucceed}
                                        >
                                            Succeed
                                        </Button>
                                        <Button
                                            sx={{ margin: '1rem 1rem 0 0' }}
                                            variant="contained"
                                            color="warning"
                                            onClick={handleTransactionFail}
                                        >
                                            Failed
                                        </Button>
                                    </>
                                )}
                        </>
                    )}
                </>
            )}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        </Box>
    );
};
