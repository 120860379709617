import { Paper, Grid } from '@mui/material';
import { ExitImpersonationAlert } from './ExitImpersonationAlert';

type ContentWrapperProps = {
    children: JSX.Element;
    showImpersonationBanner?: boolean;
};

export const ContentWrapper = (props: ContentWrapperProps) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {props.showImpersonationBanner !== false && (
                    <ExitImpersonationAlert />
                )}
                {props.children}
            </Grid>
        </Grid>
    );
};
