import { Box, Stack } from '@mui/material';
import { ReactNode } from 'react';
import Heading from '../../typography/Heading';
import Description from '../../typography/Description';

interface CardHeaderProps {
    label: ReactNode;
    description?: string;
    chip?: ReactNode;
    actions?: ReactNode;
}

const CardHeader = ({ label, description, chip, actions }: CardHeaderProps) => {
    return (
        <Box m={1} p={1} pl={2}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'column'}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <Heading fontSize={'18px'} label={label} />
                        {chip}
                    </Stack>

                    {description && <Description label={description} />}
                </Stack>
                <Stack direction={'row'} spacing={1}>
                    {actions}
                </Stack>
            </Stack>

            {/* <Divider style={{ marginTop: "8px" }} /> */}
        </Box>
    );
};

export default CardHeader;
