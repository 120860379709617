import { Alert, Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { ContentWrapper } from '../../components/ContentWrapper';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useErrorHandling } from '../../utils/errorHandling';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export const AiiaConnectCallbackPage = () => {
    const [error, setError] = useState<string>();
    const { setAlert } = useNotificationsSnackbar();

    const { backendErrorHandler } = useErrorHandling();

    let navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const code = searchParams.get('code') ?? '';
        const consentId = searchParams.get('consentId') ?? '';
        if (!code || !consentId) {
            setError(
                'Something went wrong - could not determine the code and consent.'
            );
        } else {
            PaymentsAPIServiceV2.getInstance()
                .aiiaExchangeToken({
                    code: decodeURIComponent(code),
                    consentId: decodeURIComponent(consentId)
                })
                .then(() => {
                    setAlert('AiiA account connected successfully', 'success');
                    return navigate('/settings');
                })
                .catch(
                    backendErrorHandler('Error fetching users: ', (e) => {
                        setError('Could not exchange AiiA code: ' + e.message);
                    })
                );
        }
    }, [searchParams, navigate, setAlert, backendErrorHandler]);

    return (
        <>
            {error && (
                <ContentWrapper>
                    <Box textAlign="center">
                        <Alert severity="error">{error}</Alert>
                        <Button
                            sx={{ marginTop: '1rem' }}
                            variant="contained"
                            component={Link}
                            to="/"
                        >
                            Return home
                        </Button>
                    </Box>
                </ContentWrapper>
            )}
        </>
    );
};
