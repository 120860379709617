import styled from '@emotion/styled';
import { Dialog, DialogProps } from '@mui/material';
import { JSX } from 'react/jsx-runtime';

export const StyledDialog = styled(
    (props: JSX.IntrinsicAttributes & DialogProps) => (
        <Dialog
            {...props}
            PaperProps={{
                style: {
                    borderRadius: '12px'
                }
            }}
        />
    )
)`
    box-shadow: 0px 8px 8px -4px #10182808;
    box-shadow: 0px 20px 24px -4px #10182814;
    min-height: 100px;
    border-radius: 12px;
`;

export const StyledDialogContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
`;

export const StyledDynamicContentBox = styled.div`
    overflow-y: auto;
    max-height: 300px;
    padding: 8px 8px 0px 8px;
    border: 1px solid #d0d5dd;
`;

export const StyledDialogTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
`;
