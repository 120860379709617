import { ContentWrapper } from '../../components/ContentWrapper';
import { Typography } from '@mui/material';
import { CompanyAccountsOverview } from './CompanyAccountsOverview';

export const CompanyAccountsPage = () => {
    return (
        <ContentWrapper>
            <>
                <Typography variant="h4">Company accounts</Typography>
                <CompanyAccountsOverview />
            </>
        </ContentWrapper>
    );
};
