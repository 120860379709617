import { SvgIcon, SvgIconProps } from '@mui/material';

export const CompanyAccountsIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 16">
            <rect width="16" height="16" rx="3" />
            <g clip-path="url(#clip0_2690_279)">
                <path
                    d="M6.926 4.73218C8.12458 3.53305 10.0685 3.53351 11.2675 4.73218C12.4666 5.93085 12.4666 7.87493 11.2675 9.07406M9.97407 9.09582C9.97407 10.7916 8.59945 12.1663 6.90378 12.1663C5.20811 12.1663 3.8335 10.7916 3.8335 9.09582C3.8335 7.40002 5.20811 6.0253 6.90378 6.0253C8.59945 6.0253 9.97407 7.40002 9.97407 9.09582Z"
                    stroke="#F5FAFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2690_279">
                    <rect
                        x="3"
                        y="3"
                        width="10"
                        height="10"
                        rx="2"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
