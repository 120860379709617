import { useState } from 'react';
import { useNotificationsSnackbar } from '../../../components/snackbar/NotificationsSnackbarContext';
import { UpdateUserRequest } from '../../../models/company';
import { useLoader } from '../../../contexts/loader/LoaderContext';
import { UpdateUserDialog } from './UpdateUserDialog';
import { Validator, useErrorHandling } from '../../../utils/errorHandling';
import UsersAPIServiceV2 from '../../../services/UserServiceV2';

type UpdateUserFormProps = {
    userId?: string;
    onUserListChange: () => void;
    open: boolean;
    setOpen: (open: boolean) => void;
};

export const UpdateUserForm = (props: UpdateUserFormProps) => {
    const { setAlert } = useNotificationsSnackbar();

    const { setLoading } = useLoader();
    const { backendErrorHandler } = useErrorHandling();
    const [validator, setValidator] = useState(new Validator());

    const onClose = () => {
        props.setOpen(false);
    };

    const onSubmit = async (request: UpdateUserRequest): Promise<void> => {
        setLoading(true);
        UsersAPIServiceV2.getInstance()
            .updateUser(request)
            .then(() => {
                setAlert('Saved successfully.', 'success');
                setValidator(validator.clearErrors());
                props.setOpen(false);
                props.onUserListChange();
            })
            .catch(
                backendErrorHandler(undefined, (e, errorDetails) => {
                    setValidator(new Validator(errorDetails?.errors));
                })
            )
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <UpdateUserDialog
            validator={validator}
            setValidator={setValidator}
            userId={props.userId}
            open={props.open}
            onClose={onClose}
            onSubmit={onSubmit}
        />
    );
};
