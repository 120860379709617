import { Grid, Typography } from '@mui/material';
import { AddCompanyAccountTransactionDialogMode } from './AddCompanyAccountTransactionDialog';
import { toDecimal } from '../../utils/formatting';

export type AccountUpdateResultProps = {
    mode: AddCompanyAccountTransactionDialogMode;
    amount: number;
    balance: number;
    currency: string;
};

export const AccountUpdateResult = (props: AccountUpdateResultProps) => {
    const getNewBalance = () => {
        if (props.mode === 'Deposit') {
            return props.balance + props.amount;
        }
        return props.balance - props.amount;
    };

    return (
        <>
            <Grid marginTop="1rem" container justifyContent="space-between">
                <Typography display="inline" align="left" fontWeight="bold">
                    Current balance:
                </Typography>
                <Typography display="inline" align="right">
                    {toDecimal(props.balance)} {props.currency}
                </Typography>
            </Grid>
            <Grid container justifyContent="space-between">
                <Typography display="inline" align="left" fontWeight="bold">
                    {props.mode}:
                </Typography>
                <Typography display="inline" align="right">
                    {toDecimal(props.amount)} {props.currency}
                </Typography>
            </Grid>

            <hr />

            <Grid container justifyContent="space-between">
                <Typography display="inline" align="left" fontWeight="bold">
                    New balance:
                </Typography>
                <Typography display="inline" align="right">
                    {toDecimal(getNewBalance())} {props.currency}
                </Typography>
            </Grid>
        </>
    );
};
