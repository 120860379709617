import {
    SxProps,
    Theme,
    Typography,
    TypographyProps,
    TypographyPropsVariantOverrides
} from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { OverridableStringUnion } from '@mui/types';
import { Property } from 'csstype';
import { ReactNode } from 'react';

interface HeadingProps extends TypographyProps {
    label: string | ReactNode;
    sx?: SxProps<Theme>;
    variant?: OverridableStringUnion<
        Variant | 'inherit',
        TypographyPropsVariantOverrides
    >;
    fontSize?: Property.FontSize | undefined | number;
}

const Heading = ({ label, sx, variant, fontSize, ...props }: HeadingProps) => {
    return (
        <Typography
            sx={sx}
            style={{
                fontSize: fontSize ? fontSize : '14px',
                lineHeight: '38px',
                fontWeight: 600,
                // letterSpacing: '0.5px',
                textAlign: 'left',
                fontFamily: 'Inter'
            }}
            {...props}
        >
            {label}
        </Typography>
    );
};

export default Heading;
