import { PopupRequest, PublicClientApplication } from '@azure/msal-browser';
import i18n from '../translations/i18n';

type B2CConfiguration = {
    authorityB2C: string;
    authorityAD: string;
    authorityADChangeEmailFlow: string;
    authorityDomain: string;
    clientId: string;
    scopes: string;
};

type SPAConfiguration = {
    backendUserServiceUrl: string;
    userServiceAppUrl: string;
    paymentsServiceAppUrl: string;
    backendReportsInternalServiceURL: string;
    notificationsServiceAppUrl: string;
    pushServerPublicKey: string;
    appInsightsConnectionString: string;
    backendPaymentsServiceUrl: string;
    underMaintenanceFileUrl: string;
    backendNotificationsServiceUrl: string;
    b2c: B2CConfiguration;
};

//Patched Holder to replace Hooks to Redux store, this is initail setup to be optimized later
export class MSALHolder {
    private static instance: MSALHolder | null = null;
    private static configuration: SPAConfiguration | null = null;

    private static configCache: any;

    public static getInstance = async () => {
        if (MSALHolder.instance != null) {
            return MSALHolder.instance;
        }

        let config = null;

        if (MSALHolder.configCache) {
            config = MSALHolder.configCache;
        } else {
            let response = await fetch(`/config.json`);
            config = await response.json();
            MSALHolder.configCache = config;
        }
        const spaConfiguration = config as SPAConfiguration;
        MSALHolder.configuration = spaConfiguration;
        MSALHolder.instance = new MSALHolder();

        return MSALHolder.instance;
    };

    getLoginRequest = (): PopupRequest => {
        return {
            scopes: [MSALHolder.configuration?.b2c.scopes!]
        };
    };

    getLoginRequestAD = (): PopupRequest => {
        return {
            scopes: [MSALHolder.configuration?.b2c.scopes!],
            authority: MSALHolder.configuration!.b2c.authorityAD
        };
    };

    getSpaConfiguration = () => {
        const config = MSALHolder.configuration;
        return config;
    };

    public static msalInstance: PublicClientApplication | null = null;
}
