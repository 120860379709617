import { useCallback, useEffect, useState } from 'react';
import {
    CompanyPaymentDetailsResponse,
    IntegrationStatusResponse
} from '../../models/payment';
import {
    Typography,
    Box,
    Container,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@mui/material';
import { ImportUsers } from '../users/import/ImportUsers';
import { IntectConnectForm } from './IntectConnect';
import { IntectImportSettings } from './IntectImportSettings';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PayrollConnectionStatusHeader } from '../payroll/PayrollConnectionStatusHeader';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export type IntectConnectStatusProps = {
    company: CompanyPaymentDetailsResponse;
    onSaveSuccess: () => void;
};

export const IntectConnectStatus = (props: IntectConnectStatusProps) => {
    const { setLoading } = useLoader();

    const { backendErrorHandler } = useErrorHandling();
    const [integrationStatus, setIntegrationStatus] =
        useState<IntegrationStatusResponse>();
    const [open, setOpen] = useState(props.company.intectCompanyId === null);

    const fetchIntegrationStatus = useCallback(() => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getIntegrationStatus('intect')
            .then((response: any) => {
                setIntegrationStatus(response.data);
            })
            .catch(backendErrorHandler())
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, backendErrorHandler]);

    useEffect(() => {
        fetchIntegrationStatus();
    }, [fetchIntegrationStatus]);

    return (
        <>
            <Typography variant="h5" marginBottom={1}>
                Intect settings
            </Typography>

            <Accordion
                expanded={open}
                onChange={(event: React.SyntheticEvent, newExpanded: boolean) =>
                    setOpen(newExpanded)
                }
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <PayrollConnectionStatusHeader
                        externalCompanyId={props.company.intectCompanyId}
                        providerName="Intect"
                        status={integrationStatus?.status}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    <Container>
                        {props.company?.intectCompanyId && (
                            <>
                                {integrationStatus?.status === 'Connected' && (
                                    <>
                                        <Box>
                                            Your company is connected to Intect
                                            company{' '}
                                            <strong>
                                                {props.company.intectCompanyId}
                                            </strong>
                                            .
                                        </Box>
                                        <Box>
                                            Fill in the fields below to connect
                                            again.
                                        </Box>

                                        <IntectConnectForm
                                            onSaveSuccess={() => {
                                                props.onSaveSuccess();
                                                fetchIntegrationStatus();
                                            }}
                                        />
                                    </>
                                )}
                                {integrationStatus?.status ===
                                    'ConnectionError' && (
                                    <>
                                        <Box>
                                            Connection to Intect company{' '}
                                            <strong>
                                                {props.company.intectCompanyId}
                                            </strong>{' '}
                                            could not be established.
                                        </Box>
                                        <Box>
                                            Fill in the fields below to connect
                                            again.
                                        </Box>

                                        <IntectConnectForm
                                            onSaveSuccess={() => {
                                                props.onSaveSuccess();
                                                fetchIntegrationStatus();
                                            }}
                                        />
                                    </>
                                )}
                                {integrationStatus?.status ===
                                    'NotConnected' && (
                                    <>
                                        <Box>
                                            You have not connected a Intect
                                            account yet.
                                        </Box>
                                        <Box>
                                            Fill in the fields below to connect.
                                        </Box>

                                        <IntectConnectForm
                                            onSaveSuccess={() => {
                                                props.onSaveSuccess();
                                                fetchIntegrationStatus();
                                            }}
                                        />
                                    </>
                                )}
                            </>
                        )}
                        {!props.company?.intectCompanyId && (
                            <>
                                <Box>
                                    You have not connected a Intect account yet.
                                </Box>
                                <Box>Fill in the fields below to connect.</Box>

                                <IntectConnectForm
                                    onSaveSuccess={() => {
                                        props.onSaveSuccess();
                                        fetchIntegrationStatus();
                                    }}
                                />
                            </>
                        )}
                    </Container>
                </AccordionDetails>
            </Accordion>

            {props.company?.intectCompanyId && (
                <ImportUsers
                    onImportSuccess={props.onSaveSuccess}
                    importSettings={IntectImportSettings}
                />
            )}
        </>
    );
};
