import { Box } from '@mui/material';
import { NotificationsSnackbar } from './snackbar/NotificationsSnackbar';
import { Sidebar } from './sidebar/Sidebar';
import { useUser } from '../contexts/user/UserContext';
import { Consents } from './consent/Consents';
import { UserRoles } from './sidebar/MenuTree';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import {
    setCompanyIdInLocalStorage,
    setCompanyIdInLocalStorageWithoutReload
} from '../services/companyService';
import { useAppDispatch } from '../lib/hooks';
import { getAdminCompanies } from '../lib/slices/paymentsSlice';

type PageLayoutProps = {
    children: JSX.Element;
};

export const PageLayout = (props: PageLayoutProps) => {
    const { company, user, reloadUserData } = useUser();

    const location = useLocation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const changeCompanyId = params.get('changeCompanyId');
        if (changeCompanyId) {
            setCompanyIdInLocalStorageWithoutReload(changeCompanyId);
            params.delete('changeCompanyId');
            reloadUserData();
            dispatch(getAdminCompanies());
            window.history.replaceState(
                {},
                '',
                `${location.pathname}?${params.toString()}`
            );
        }
    }, [location]);

    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar />
            <Box
                component="main"
                sx={{ flexGrow: 1, margin: '20px', marginTop: '40px' }}
            >
                {company.requiredConsentsAccepted ||
                user.role == UserRoles.SuperAdmin ? (
                    <>{props.children}</>
                ) : (
                    <Consents />
                )}
            </Box>
            <NotificationsSnackbar />
        </Box>
    );
};
