import { ContentWrapper } from '../../components/ContentWrapper';
import { ConsentsTable } from './ConsentsTable';
import { SubscriptionDetails } from './SubscriptionDetails';

export const SubscriptionPage = () => {
    return (
        <ContentWrapper>
            <>
                <SubscriptionDetails />
                <ConsentsTable />
            </>
        </ContentWrapper>
    );
};
