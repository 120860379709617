import { SvgIcon, SvgIconProps } from '@mui/material';

export const CompanyIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 16 16">
            <rect width="16" height="16" rx="3" />
            <g clip-path="url(#clip0_2690_273)">
                <path
                    d="M9.66683 11.75V5.08333C9.66683 4.86232 9.57903 4.65036 9.42275 4.49408C9.26647 4.3378 9.05451 4.25 8.8335 4.25H7.16683C6.94582 4.25 6.73385 4.3378 6.57757 4.49408C6.42129 4.65036 6.3335 4.86232 6.3335 5.08333V11.75M4.66683 5.91667H11.3335C11.7937 5.91667 12.1668 6.28976 12.1668 6.75V10.9167C12.1668 11.3769 11.7937 11.75 11.3335 11.75H4.66683C4.20659 11.75 3.8335 11.3769 3.8335 10.9167V6.75C3.8335 6.28976 4.20659 5.91667 4.66683 5.91667Z"
                    stroke="#F5FAFF"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_2690_273">
                    <rect
                        x="3"
                        y="3"
                        width="10"
                        height="10"
                        rx="2"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};
