import { useCallback, useEffect, useState } from 'react';
import { ContentWrapper } from '../../components/ContentWrapper';
import {
    Alert,
    Button,
    FormControl,
    FormControlProps,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    TextFieldProps,
    Typography
} from '@mui/material';
import { LanguagesResponse } from '../../models/company';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import UsersAPIServiceV2 from '../../services/UserServiceV2';
import NotificationAPIServiceV2 from '../../services/NotificationServiceV2';

export const EmailsPage = () => {
    const [languages, setLanguages] = useState<LanguagesResponse>();
    const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
    const [error, setError] = useState<string>();
    const [email, setEmail] = useState<string>('');

    const { setLoading } = useLoader();
    const { backendErrorHandler } = useErrorHandling();
    const { setAlert } = useNotificationsSnackbar();
    const commonProps: TextFieldProps = {
        margin: 'dense',
        fullWidth: true,
        variant: 'standard'
    };

    const fetchLanguages = useCallback(() => {
        setLoading(true);
        UsersAPIServiceV2.getInstance()
            .getSupportedLanguages()
            .then((response: any) => {
                setLanguages(response.data);
            })
            .catch(
                backendErrorHandler(undefined, () => {
                    setError('Could not load language options');
                })
            )
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, backendErrorHandler]);

    const onClick = () => {
        if (!selectedLanguage || !email) {
            setAlert('Please provide an email and selected language', 'error');
            return;
        }

        setLoading(true);
        NotificationAPIServiceV2.getInstance()
            .sendAllEmails({ email: email, language: selectedLanguage })
            .then(() => {
                setAlert('Emails sent successfully', 'success');
            })
            .catch(
                backendErrorHandler(undefined, () => {
                    setError('Could not send emails');
                })
            )
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchLanguages();
    }, [fetchLanguages]);

    return (
        <ContentWrapper>
            <>
                <Typography variant="h5">Email settings</Typography>
                <p>
                    Use the form below to send test emails to the provided
                    recipient.
                </p>

                {error && <Alert severity="error">{error}</Alert>}

                {languages?.languages && (
                    <>
                        <TextField
                            {...commonProps}
                            label="Recipient email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                        />

                        <FormControl {...(commonProps as FormControlProps)}>
                            <InputLabel></InputLabel>
                            <Select
                                variant="standard"
                                value={selectedLanguage}
                                label="Select language"
                                onChange={(e) =>
                                    setSelectedLanguage(e.target.value)
                                }
                            >
                                {languages?.languages.map((language) => (
                                    <MenuItem
                                        key={language.code}
                                        value={language.code}
                                    >
                                        {language.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Button
                            sx={{ marginTop: '1rem' }}
                            variant="contained"
                            onClick={onClick}
                        >
                            Send
                        </Button>
                    </>
                )}
            </>
        </ContentWrapper>
    );
};
