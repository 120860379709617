import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import translationEnglish from './en.json';

export const defaultNS = 'translation';
export const resources = {
    en: {
        translation: translationEnglish
    }
} as const;

i18next
    .use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        resources,
        debug: true,
        detection: {
            order: ['localStorage', 'navigator']
        },
        fallbackLng: 'en',
        returnNull: false,
        nonExplicitSupportedLngs: true,
        load: 'languageOnly',
        supportedLngs: ['da', 'en'],
        interpolation: {
            escapeValue: false // no need for React - it escapes by default
        }
    });

export default i18next;
