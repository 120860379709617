import { Box } from '@mui/material';
import { ContentWrapper } from './ContentWrapper';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useEffect, useState } from 'react';
import { useConfiguration } from '../contexts/configuration/ConfigurationContext';
import { setLoader } from '../lib/slices/globalLoaderSlice';
import { useAppDispatch } from '../lib/hooks';
import {
    getSuperAdminOverride,
    setSuperAdminOverride
} from '../lib/localStorage';

type UnderMaintenanceCheckProps = {
    children: JSX.Element;
};

export const UnderMaintenanceCheck = (props: UnderMaintenanceCheckProps) => {
    const { spaConfiguration } = useConfiguration();
    const [isUnderMaintenance, setIsUnderMaintenance] = useState<boolean>();
    const underMaintenanceMarker = 'IS_UNDER_MAINTENANCE=1';

    useEffect(() => {
        var noCacheHeaders = new Headers();
        noCacheHeaders.append('pragma', 'no-cache');
        noCacheHeaders.append('cache-control', 'no-cache');

        fetch(spaConfiguration.underMaintenanceFileUrl, {
            method: 'GET',
            cache: 'no-store',
            headers: noCacheHeaders
        })
            .then((response) => response.text())
            .then((response) => {
                setIsUnderMaintenance(
                    response.indexOf(underMaintenanceMarker) !== -1
                );
            })
            .catch(() => {
                // if we could not determine if the flag exists - assume that we're not under maintanence
                setIsUnderMaintenance(false);
            });
    }, []);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isUnderMaintenance) {
            setTimeout(() => {
                dispatch(setLoader(false));
            }, 5000);
        }
    }, [isUnderMaintenance]);

    const [pressedKey, setPressedKey] = useState<string | undefined>();

    useEffect(() => {
        const onKeyPress = (e: KeyboardEvent) => {
            setPressedKey((prev) => prev + e.key);
        };
        window.addEventListener('keypress', onKeyPress);

        return () => {
            window.removeEventListener('keypress', onKeyPress);
        };
    }, []);

    useEffect(() => {
        if (pressedKey && pressedKey.indexOf('superunlock') != -1) {
            setSuperAdminOverride(true);
            location.reload();
        } else if (pressedKey && pressedKey?.indexOf('superlock') != -1) {
            setSuperAdminOverride(false);
            location.reload();
        }
    }, [pressedKey]);

    return isUnderMaintenance !== undefined &&
        isUnderMaintenance &&
        !getSuperAdminOverride() ? (
        <Box
            sx={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                margin: '20px'
            }}
        >
            <ContentWrapper showImpersonationBanner={false}>
                <>
                    <Box textAlign="center">
                        <img
                            style={{ width: '200px', marginBottom: '100px' }}
                            src={'/instapaid_long.png'}
                            alt={'instapaid logo'}
                        />
                    </Box>
                    <Box textAlign="center">
                        <EngineeringIcon fontSize="large" />
                        <br />
                        Sorry, the site is under maintenance. Please come back
                        later.
                    </Box>
                </>
            </ContentWrapper>
        </Box>
    ) : (
        <>{props.children}</>
    );
};
