import { useState, useEffect, useCallback } from 'react';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import {
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { ConsentResponse, GetConsentsResponse } from '../../models/company';
import { formatDateTime } from '../../utils/formatting';
import UsersAPIServiceV2 from '../../services/UserServiceV2';

export const ConsentsTable = () => {
    const [consents, setConsents] = useState<GetConsentsResponse>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const { setLoading } = useLoader();
    const { backendErrorHandler } = useErrorHandling();

    const loadConsents = useCallback(() => {
        setLoading(true);
        UsersAPIServiceV2.getInstance()
            .getConsents()
            .then((response: any) => {
                setConsents(response.data);
            })
            .catch(
                backendErrorHandler('Could not get consents: ', (e: Error) => {
                    setErrorMessage('Could not get consents: ' + e.message);
                })
            )
            .finally(() => {
                setLoading(false);
            });
    }, [backendErrorHandler, setLoading]);

    const getTermsDocumentName = (consent: ConsentResponse) => {
        if (consent.termsVersionName) {
            return consent.termsVersionName;
        }
        switch (consent.consentType) {
            case 'Commercial':
                return 'License agreement';
            case 'Data':
                return 'Data processing agreement';
            case 'NewsletterAndUpdates':
                return 'Marketing updates';
            default:
                return consent.consentType;
        }
    };

    useEffect(() => {
        loadConsents();
    }, [loadConsents]);

    return (
        <>
            <Typography variant="h5">Accepted documents</Typography>
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            {consents && consents.consents.length === 0 && (
                <Alert severity="info">
                    You have no accepted documents yet.
                </Alert>
            )}
            {consents && consents.consents.length > 0 && (
                <TableContainer>
                    <Table
                        sx={{ minWidth: 650, marginBottom: '1rem' }}
                        aria-label="simple table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    Document
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    Action
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    Date / time
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>
                                    Performed by
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {consents.consents.map((consent) => {
                                return (
                                    <TableRow key={consent.consentId}>
                                        <TableCell>
                                            {consent.termsVersionUrl ? (
                                                <a
                                                    href={
                                                        consent.termsVersionUrl
                                                    }
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {getTermsDocumentName(
                                                        consent
                                                    )}
                                                </a>
                                            ) : (
                                                <>
                                                    {getTermsDocumentName(
                                                        consent
                                                    )}
                                                </>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {consent.actionType}
                                        </TableCell>
                                        <TableCell>
                                            {formatDateTime(
                                                consent.performedAt
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {consent.performedByEmail}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};
