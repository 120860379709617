import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Table,
    TableBody,
    TableContainer,
    useTheme
} from '@mui/material';
import {
    Caption,
    FONT_WEIGHT,
    Heading,
    LINE_HEIGHT,
    TEXT_SIZE
} from '../../../typography/Typography';
import { Balance } from '../useBalance';
import { useContext, useMemo } from 'react';
import { OutlinedPrimaryButton } from '../../../components/shared/Button';
import {
    BalanceSummaryItemType,
    BalanceSummaryListItem
} from './BalanceSummaryItem';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '../../../utils/formatting';
import { UserContext } from '../../../contexts/user/UserContext';

export interface BalanceSummaryDetailsDialogProps {
    open: boolean;
    balance?: Balance;
    payrollDateString?: string;
    onClose?: () => void;
}

export const BalanceSummaryDetailsDialog = ({
    open,
    balance,
    onClose
}: BalanceSummaryDetailsDialogProps) => {
    const { t } = useTranslation();
    const { formatDate, getBalanceLabel, toDecimal } = useFormatting();
    const { company } = useContext(UserContext);
    const theme = useTheme();

    const payrollDateString = useMemo(() => {
        return t('payroll_period_text', {
            startDate: formatDate(
                new Date(balance?.details?.payrollPeriodStart!)
            ),
            endDate: formatDate(new Date(balance?.details?.payrollPeriodEnd!))
        });
    }, [balance]);

    const balanceItems = useMemo(() => {
        return [
            {
                primaryText: 'Gross Earnings',
                balance: balance?.details?.earned,
                type: BalanceSummaryItemType.SUB_TOTAL
            },
            {
                primaryText: 'Excluded Shifts',
                balance: balance?.details?.unavailableShiftsTotalAmount
            },
            {
                primaryText: 'Adjustments before taxes',
                balance:
                    balance?.details?.balanceAdjustments
                        .totalAdjustmentsBeforeTax
            },
            {
                primaryText: 'Earnings before taxes',
                balance:
                    (balance?.details?.balanceAdjustments
                        .totalAdjustmentsBeforeTax ?? 0) +
                        (balance?.details?.unavailableShiftsTotalAmount ?? 0) ==
                    0
                        ? 0
                        : balance?.details?.calculationsDetails
                              ?.earningsBeforeTax,

                type: BalanceSummaryItemType.SUB_TOTAL
            },
            {
                primaryText: `ATP: Monthly payroll period (${balance?.details?.calculationsDetails?.atpContributionBracket} hours/mo.)`,
                balance:
                    -1 * balance?.details?.calculationsDetails.atpContribution!,
                subLabel: ``
            },
            {
                primaryText: 'AM Contribution',
                balance:
                    -1 * balance?.details?.calculationsDetails.amContribution!,
                col1: getBalanceLabel(
                    balance?.details?.calculationsDetails
                        ?.amContributionAfterATP,
                    company.currency
                ),
                col2: `8%` //hardcoded for Denmark, for Sweden we will change summary screen,
            },
            {
                primaryText: t('tax_rate_from'),
                balance: <></>,
                subLabel: `${getBalanceLabel(
                    balance?.details?.calculationsDetails.taxableIncome,
                    company.currency
                )} (${balance?.details?.workdays}/${
                    balance?.details?.totalBusinessDaysInMonth
                } workdays)`,
                col1: getBalanceLabel(
                    balance?.details?.calculationsDetails.aIncome!,
                    company.currency
                ),
                col2: getBalanceLabel(
                    balance?.details?.calculationsDetails.appliedTaxDeduction!,
                    company.currency,
                    true,
                    true
                )
            },

            {
                primaryText: 'Income tax',
                col1: getBalanceLabel(
                    balance?.details?.calculationsDetails?.taxableIncome,
                    company.currency
                ),
                col2:
                    toDecimal(
                        -1 *
                            balance?.details?.calculationsDetails.taxRate! *
                            100
                    ) + '%',
                balance: -1 * balance?.details?.calculationsDetails.incomeTax!
            },

            {
                primaryText: 'Earnings after taxes',
                balance:
                    balance?.details?.calculationsDetails?.earningsAfterTax,
                type: BalanceSummaryItemType.SUB_TOTAL
            },
            {
                primaryText: t('allowance_percentage', {
                    percentage: ''
                }),
                col1: getBalanceLabel(
                    balance?.details?.calculationsDetails?.earningsAfterTax,
                    company.currency
                ),
                col2: ` ${toDecimal(
                    -1 * balance?.details?.allowancePercentage!
                )}%`,
                balance: -1 * balance?.details?.allowancePercentageDeduction!
            },
            {
                primaryText: 'Adjustments after taxes',
                balance:
                    balance?.details?.balanceAdjustments
                        ?.totalAdjustmentsAfterTax!
            },
            {
                primaryText: 'Withdrawed',
                balance: -1 * balance?.details?.withdrawed!
            },
            {
                primaryText: 'Pending approval',
                balance: -1 * balance?.details?.pendingWithdrawals!
            },
            {
                primaryText: 'Mileage',
                balance: balance?.details?.mileageTotal
            }
        ];
    }, [balance]);

    return (
        <Dialog
            sx={{
                '& .MuiPaper-root': {
                    maxWidth: '695px',
                    borderRadius: '12px'
                }
            }}
            open={open}
        >
            <DialogContent
                style={{
                    minWidth: '695px',
                    padding: '24px 8px 0px 8px',
                    borderRadius: '12px',
                    maxHeight: '792px'
                }}
            >
                <Grid container>
                    <Grid container justifyContent={'center'}>
                        <Heading
                            fontSize={TEXT_SIZE.MEDIUM_SMALL}
                            fontWeight={FONT_WEIGHT.BOLD}
                            lineHeight={LINE_HEIGHT.LARGE}
                        >
                            {t('balance_summary')}
                        </Heading>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <Caption>{payrollDateString}</Caption>
                    </Grid>
                </Grid>

                <Grid container alignItems={'space-around'} p={2}>
                    <Grid container>
                        <TableContainer>
                            <Table aria-label="balance table">
                                <TableBody>
                                    {balanceItems.map((item, index) => {
                                        if (
                                            item.balance == 0 ||
                                            !item.balance
                                        ) {
                                            return null;
                                        }
                                        return (
                                            <BalanceSummaryListItem
                                                key={index}
                                                type={item.type}
                                                col1={item.col1}
                                                col2={item.col2}
                                                subLabel={item.subLabel}
                                                label={item.primaryText}
                                                balance={item.balance}
                                            />
                                        );
                                    })}

                                    <BalanceSummaryListItem
                                        type={BalanceSummaryItemType.TOTAL}
                                        label={'Available balance'}
                                        balance={balance?.balance!}
                                    />
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ margin: '8px 16px 16px' }}>
                <OutlinedPrimaryButton
                    fullWidth
                    variant="contained"
                    onClick={onClose}
                >
                    Close
                </OutlinedPrimaryButton>
            </DialogActions>
        </Dialog>
    );
};
