import { useEffect, useState, useCallback } from 'react';
import { Validator, useErrorHandling } from '../../utils/errorHandling';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { CompanyAccountsResponse } from '../../models/company';
import { CompanyAccountsTable } from './CompanyAccountsTable';
import { Button, Box } from '@mui/material';
import {
    AddCompanyAccountTransactionDialog,
    AddCompanyAccountTransactionDialogMode
} from './AddCompanyAccountTransactionDialog';
import { CreateCompanyAccountTransactionRequest } from '../../models/payment';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';
import { useAppDispatch } from '../../lib/hooks';
import { createCompanyAccountsTransaction } from '../../lib/slices/paymentsSlice';

export const CompanyAccountsOverview = () => {
    const [companyAccounts, setCompanyAccounts] =
        useState<CompanyAccountsResponse>();
    const { setLoading } = useLoader();

    const { backendErrorHandler } = useErrorHandling();
    const { setAlert } = useNotificationsSnackbar();
    const [mode, setMode] =
        useState<AddCompanyAccountTransactionDialogMode>('Deposit');
    const [open, setOpen] = useState(false);
    const [validator, setValidator] = useState(new Validator());
    const dispatch = useAppDispatch();

    const loadCompanyAccounts = useCallback(() => {
        setLoading(true);
        PaymentsAPIServiceV2.getInstance()
            .getCompanyAccounts()
            .then((response: any) => {
                setCompanyAccounts(response.data as CompanyAccountsResponse);
            })
            .catch(backendErrorHandler('Error fetching company accounts: '))
            .finally(() => {
                setLoading(false);
            });
    }, [setLoading, backendErrorHandler]);

    const onDepositClick = () => {
        setMode('Deposit');
        setOpen(true);
    };

    const onWithdrawClick = () => {
        setMode('Withdraw');
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onSubmit = async (
        request: CreateCompanyAccountTransactionRequest,
        onSuccess: () => void
    ): Promise<void> => {
        setLoading(true);

        dispatch(createCompanyAccountsTransaction(request))
            .then(() => {
                setAlert('New transaction added successfully.', 'success');
                setOpen(false);
                setValidator(validator.clearErrors());
                onSuccess();
                loadCompanyAccounts();
            })
            .catch(
                backendErrorHandler(undefined, (e, errorDetails) => {
                    setValidator(new Validator(errorDetails?.errors));
                })
            )
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        loadCompanyAccounts();
    }, [loadCompanyAccounts]);

    return (
        <>
            <Box sx={{ marginTop: '1rem' }}>
                <Button
                    sx={{ marginRight: '1rem' }}
                    color="primary"
                    variant="contained"
                    onClick={onDepositClick}
                >
                    Deposit
                </Button>
                <Button
                    color="error"
                    variant="contained"
                    onClick={onWithdrawClick}
                >
                    Withdraw
                </Button>
            </Box>

            {companyAccounts && (
                <>
                    <AddCompanyAccountTransactionDialog
                        companyAccounts={companyAccounts}
                        mode={mode}
                        validator={validator}
                        setValidator={setValidator}
                        open={open}
                        onClose={onClose}
                        onSubmit={onSubmit}
                    />

                    <CompanyAccountsTable companyAccounts={companyAccounts} />
                </>
            )}
        </>
    );
};
