import { Typography, TypographyPropsVariantOverrides } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { OverridableStringUnion } from '@mui/types';

interface DescriptionProps {
    label: string;
    variant?: OverridableStringUnion<
        Variant | 'inherit',
        TypographyPropsVariantOverrides
    >;
}

const Description = ({ label, variant }: DescriptionProps) => {
    return (
        <Typography
            variant={variant ? variant : 'body1'}
            style={{
                fontSize: '12px',
                lineHeight: '18px',
                fontWeight: '500',
                color: 'grey',
                textAlign: 'left',
                fontFamily: 'Inter'
            }}
        >
            {label}
        </Typography>
    );
};

export default Description;
