import { Alert, Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ContentWrapper } from '../../components/ContentWrapper';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';
import { useLoader } from '../../contexts/loader/LoaderContext';
import { useErrorHandling } from '../../utils/errorHandling';
import PaymentsAPIServiceV2 from '../../services/PaymentsServiceV2';

export const ZenegyCallbackPage = () => {
    const [error, setError] = useState<string>();
    const { setAlert } = useNotificationsSnackbar();
    const { setLoading } = useLoader();
    const { backendErrorHandler } = useErrorHandling();

    const useQuery = () => {
        const { search } = useLocation();

        return React.useMemo(() => new URLSearchParams(search), [search]);
    };

    let query = useQuery();
    let navigate = useNavigate();

    useEffect(() => {
        const code = query.get('code') ?? '';
        if (!code) {
            setError('Something went wrong - could not determine the code.');
        } else {
            setLoading(true);
            PaymentsAPIServiceV2.getInstance()
                .zenegyExchangeToken({ code: decodeURIComponent(code) })
                .then(() => {
                    setAlert(
                        'Zenegy account connected successfully',
                        'success'
                    );
                    return navigate('/settings');
                })
                .catch(
                    backendErrorHandler(
                        'Could not exchange Zenegy code: ',
                        (e: Error) => {
                            setError(
                                'Could not exchange Zenegy code: ' + e.message
                            );
                        }
                    )
                )
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [query, navigate, setAlert, setLoading, backendErrorHandler]);

    return (
        <>
            {error && (
                <ContentWrapper>
                    <Box textAlign="center">
                        <Alert severity="error">{error}</Alert>
                        <Button
                            sx={{ marginTop: '1rem' }}
                            variant="contained"
                            component={Link}
                            to="/"
                        >
                            Return home
                        </Button>
                    </Box>
                </ContentWrapper>
            )}
        </>
    );
};
