import { Alert } from '@mui/material';
import { useNotificationsSnackbar } from './NotificationsSnackbarContext';
import { Snackbar } from '@mui/material';

export const NotificationsSnackbar = () => {
    const { text, type } = useNotificationsSnackbar();

    if (text && type) {
        return (
            <Snackbar
                open={true}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity={type} sx={{ width: '100%' }}>
                    {text}
                </Alert>
            </Snackbar>
        );
    } else {
        return <></>;
    }
};
