import { Alert, Button } from '@mui/material';
import { useUser } from '../contexts/user/UserContext';
import {
    exitImpersonation,
    getImpersonatedUserId
} from '../services/impersonationService';

export const ExitImpersonationAlert = () => {
    const { user, company } = useUser();
    const impersonatedUserId = getImpersonatedUserId();

    const onExitImpersonationClick = () => {
        exitImpersonation();
        window.location.reload();
    };

    return (
        <>
            {impersonatedUserId && (
                <Alert
                    onClick={onExitImpersonationClick}
                    action={
                        <Button
                            variant="contained"
                            title="Exit impersonation mode"
                            color="error"
                            size="small"
                        >
                            EXIT
                        </Button>
                    }
                    severity="info"
                    sx={{
                        width: '100%',
                        marginBottom: '1rem',
                        cursor: 'pointer'
                    }}
                >
                    {user.email && (
                        <>
                            You are impersonating{' '}
                            <b>
                                {user.firstName} {user.lastName}
                            </b>{' '}
                            ({user.email}) from <b>{company.name}</b> company.
                            Click here to exit.
                        </>
                    )}
                    {!user.email && (
                        <>
                            You are in impersonation mode, but we could not load
                            the details of the user you're impersonating.
                        </>
                    )}
                </Alert>
            )}
        </>
    );
};
